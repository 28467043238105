import { APIRequest } from '../helpers/requests';
import { API } from '../../config';

export const technologyService = {
    getTechnologies,
    createTechnology,
    updateTechnology,
    deleteTechnology,
    getTechnologyByID,
}
async function getTechnologies() {
    return await APIRequest({
        url: API.TECHNOLOGIES,
        method: 'GET',
        data: {},
        private: true
    });
}
async function getTechnologyByID(id) {
    return await APIRequest({
        url: API.TECHNOLOGIES + id,
        method: 'GET',
        data: {},
        private: true
    });
}
async function createTechnology(data) {
    return await APIRequest({
        url: API.TECHNOLOGIES,
        method: 'POST',
        data: data,
        private: true
    });
}
async function updateTechnology(id, data) {
    return await APIRequest({
        url: API.TECHNOLOGIES + id,
        method: 'PUT',
        data: { name: data },
        private: true
    });
}
async function deleteTechnology(id) {
    return await APIRequest({
        url: API.TECHNOLOGIES,
        method: 'DELETE',
        data: {},
        private: true
    });
}