import React from 'react'
import { Grid } from 'semantic-ui-react';

export default function InfoRow({ name, value }) {
    return (
        <Grid.Row columns={2} style={{ padding: 0 }}>
            <Grid.Column style={{ padding: 0, textAlign: "right", paddingRight: "0.5rem" }}>{name}:</Grid.Column>
            <Grid.Column style={{ fontWeight: "bold", padding: 0, paddingLeft: "0.5rem" }} > {value}</Grid.Column>
        </Grid.Row>
    )
}
