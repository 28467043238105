import React from 'react';
import { Grid } from 'semantic-ui-react';

// components
import GraphSegment from './GraphSegment';
import GraphTotalSection from './GraphTotalsSection';

function GraphStatistics2() {

    return (
        <GraphSegment
            header="Statistics 2"
            content={
                <Grid columns={4}>
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <GraphTotalSection
                                headerColor="blue"
                                header="EMPLOYEES"
                                detail="122"
                                icon="male"
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <GraphTotalSection
                                headerColor="green"
                                header="ACCOUNTS"
                                detail="a lot of"
                                icon="address book"
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <GraphTotalSection
                                headerColor="yellow"
                                header="OPPORTUNITIES"
                                detail="soo many"
                                icon="money"
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <GraphTotalSection
                                headerColor="pink"
                                header="LEADS"
                                detail="22"
                                icon="chart line"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        />
    );
}

export default GraphStatistics2