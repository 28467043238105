import React, { useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'

const BundleSelect = ({ data, loading, handler, input }) => {
    const [options, setOptions] = useState([])
    const [value, setValue] = useState(input ? input : '')

    useEffect(() => {
        let array = []
        for (let i in data) {
            array = array.concat({ key: data[i].id, value: data[i].id, text: `${data[i].name} ${data[i].discount}%` })
        }
        setOptions(array)
        if (input) setValue(input)
    }, [data, input])

    function handleChange(e, { value }) {
        setValue(value)
        handler(value)
    }

    return (
        <Form.Select
            disabled={loading}
            clearable
            style={{ borderRadius: 0, width: '100%' }}
            placeholder='Select your bundle'
            options={options}
            value={value}
            onChange={handleChange} />
    )
}

export default BundleSelect
