import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Form, Grid } from 'semantic-ui-react'
import Expand from 'react-expand-animated';
import CategoryInput from '../../../components/inputs/CategoryInput'
import RangeInput from './inputs/RangeInput'

const Filter = ({ visible, max_datas, filter, reset }) => {
    const [maximum, setMaximum] = useState({
        minimum_contract_length: max_datas.minimum_contract_length_max,
        minimum_order: max_datas.minimum_order_max,
        total_price: max_datas.total_price_max,
        time_before_order: max_datas.time_before_order_max,
        total_persons: max_datas.total_persons_max,
    })
    const [data, setData] = useState({
        minimum_contract_length: {
            min: 0,
            max: 100
        },
        minimum_order: {
            min: 0,
            max: 100
        },
        total_price: {
            min: 0,
            max: 100
        },
        time_before_order: {
            min: 0,
            max: 100
        },
        total_persons: {
            min: 0,
            max: 100
        },
        project_manager: false,
        is_outsourcing: false,
        name: '',
        category: 0
    })
    useEffect(() => {
        if (!max_datas) return
        setMaximum(prevState => ({
            ...prevState,
            minimum_contract_length: max_datas.minimum_contract_length_max,
            minimum_order: max_datas.minimum_order_max,
            total_price: max_datas.total_price_max,
            time_before_order: max_datas.time_before_order_max,
            total_persons: max_datas.total_persons_max,
        }))

        setData(prevState => ({
            ...prevState,
            minimum_contract_length: { min: 0, max: max_datas.minimum_contract_length_max },
            minimum_order: { min: 0, max: max_datas.minimum_order_max },
            total_price: { min: 0, max: max_datas.total_price_max },
            time_before_order: { min: 0, max: max_datas.time_before_order_max },
            total_persons: { min: 0, max: max_datas.total_persons_max },
        }))


    }, [max_datas])

    function handleContract(e) { setData((prevState) => ({ ...prevState, minimum_contract_length: e })) }
    function handleOrder(e) { setData((prevState) => ({ ...prevState, minimum_order: e })) }
    function handlePrice(e) { setData((prevState) => ({ ...prevState, total_price: e })) }
    function handleTBO(e) { setData((prevState) => ({ ...prevState, time_before_order: e })) }
    function handlePerson(e) { setData((prevState) => ({ ...prevState, total_persons: e })) }
    function handleProjectManager(e) { setData((prevState) => ({ ...prevState, project_manager: !data.project_manager })) }
    function handleOutsourcing(e) { setData((prevState) => ({ ...prevState, is_outsourcing: !data.is_outsourcing })) }
    function handleName(e) { setData((prevState) => ({ ...prevState, name: e })) }
    function handleCategory(e) { setData((prevState) => ({ ...prevState, category: e })) }

    const [MCL, setMCL] = useState({
        boxes: [
            {
                name: 'day',
                type: 1,
            },
            {
                name: 'week',
                type: 2,
            },
            {
                name: 'month',
                type: 3,
            },
        ],
        check: 1
    })
    const [TBO, setTBO] = useState({
        boxes: [
            {
                name: 'day',
                type: 1,
            },
            {
                name: 'week',
                type: 2,
            },
            {
                name: 'month',
                type: 3,
            },
        ],
        check: 1
    })
    function handleRangeTBO(e) { setTBO((prevState) => ({ ...prevState, check: e })) }
    function handleRangeMCL(e) { setMCL((prevState) => ({ ...prevState, check: e })) }
    function resetingAll() {
        reset()
    }
    return (
        <Expand open={visible}>
            <Grid >
                <Grid.Column mobile={16} tablet={4} computer={4}>
                    <span>Minimum contract length</span>
                    <RangeInput handler={(e) => handleContract(e)} input_value={data.minimum_contract_length} min={0} max={maximum.minimum_contract_length} boxes={MCL} handleCheckbox={(e) => handleRangeMCL(e)} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={4} computer={4}>
                    <span>Time before order</span>
                    <RangeInput handler={(e) => handleTBO(e)} input_value={data.time_before_order} min={0} max={maximum.time_before_order} boxes={TBO} handleCheckbox={(e) => handleRangeTBO(e)} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={4} computer={4}>
                    <span>Minimum order</span>
                    <RangeInput handler={(e) => handleOrder(e)} input_value={data.minimum_order} min={0} max={maximum.minimum_order} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={4} computer={4}>
                    <span>Total people</span>
                    <RangeInput handler={(e) => handlePerson(e)} input_value={data.total_persons} min={0} max={maximum.total_persons} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={4} computer={4}>
                    <span>Price</span>
                    <RangeInput handler={(e) => handlePrice(e)} input_value={data.total_price} min={0} max={maximum.total_price} />
                </Grid.Column>
                <Grid.Column style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} mobile={8} tablet={4} computer={2}>
                    <span style={{ marginBottom: '1rem' }}>Outsourcing</span>
                    <Checkbox slider checked={data.is_outsourcing} onChange={() => handleOutsourcing()} />
                </Grid.Column>
                <Grid.Column style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} mobile={8} tablet={4} computer={2}>
                    <span style={{ marginBottom: '1rem' }}>Manager</span>
                    <Checkbox slider checked={data.project_manager} onChange={() => handleProjectManager()} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={4} computer={4}>
                    <span>Name</span>
                    <Form.Input
                        fluid
                        placeholder="Enter name"
                        value={data.name}
                        onChange={(e) => handleName(e.target.value)} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={4} computer={4}>
                    <CategoryInput handler={(e) => handleCategory(e)} data={data.category} />
                </Grid.Column>
                <Grid.Row centered>
                    <Button onClick={() => resetingAll()} content="Reset" basic icon="redo" />
                    <Button onClick={() => filter(data)} content="Filter" icon="search" />
                </Grid.Row>
            </Grid>
        </Expand>
    )
}

export default Filter
