import React from 'react'
import { Grid } from 'semantic-ui-react'
import Technology from '../../../../components/general/Technology'

const ProjectTechnologies = ({ techs }) => {
    //techs = array of technologies
    function mapTechnologies() {
        return techs.map((item, key) => (
            <Technology data={item.name} key={key} />
        ))
    }
    return (
        <Grid.Row style={{ padding: 0, margin: '0.5rem 1rem' }}>
            {mapTechnologies()}
        </Grid.Row>
    )
}

export default ProjectTechnologies
