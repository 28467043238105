import React from 'react';
import { Checkbox } from 'semantic-ui-react';

const RadioButton = ({ name, checked, type, handler, ...rest }) => {
    return (
        <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center" }}>
            <Checkbox {...rest} radio checked={checked === type} onChange={() => handler()} />
            <span>{name}</span>
        </div>
    );
};

export default RadioButton;