import React, { useState, useEffect } from 'react'
import { DateInput } from 'semantic-ui-calendar-react'
import moment from 'moment';
import { dateService } from '../../store/services/custom/DateServices';
import { isDateError } from '../../store/services/helpers/validation';

const DateInputCustom = ({ input, handler, min, label, errorHandle }) => {
    const [data, setData] = useState(input ? input : dateService.rotateDate((moment().format("DD-MM-YYYY"))))
    const [error, setError] = useState(false)
    function handleChange(e, { value }) {
        // handler(dateService.rotateDate(value))
        handler(value)
        setData(value)
    }
    useEffect(() => {
        if (isDateError(data)) setError(true)
        if (!isDateError(data)) setError(false)
        errorHandle(error)
    }, [data, error, errorHandle])

    return (
        <>
            <DateInput
                label={label}
                style={{ width: "100%" }}
                placeholder="Date"
                value={data}
                iconPosition="left"
                onChange={handleChange}
                // minDate={min ? min : moment().format("DD-MM-YYYY")}
                clearable
                closable
            />
            {error &&
                <span style={{ color: 'var(--danger)' }}>Not valid date</span>
            }
        </>
    )
}

export default DateInputCustom
