import React from 'react'

const Recommendations = () => {
    return (
        <div>
            hello
        </div>
    )
}

export default Recommendations
