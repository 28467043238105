// react
import React from "react";
// components
import { Button, Header, Modal, Segment } from "semantic-ui-react";

// component
function ResponseModal({
    open,
    onClose,
    icon,
    header,
    message,
    onClick,
    content,
    loading,
    actionButton,
    cancel,
    dimmer }) {
    // template
    return (
        <Modal
            open={open}
            onClose={onClose}
            closeOnDimmerClick={false}
            size="mini"
            dimmer={dimmer}
        >
            <Header icon={icon} content={header} />
            <Modal.Content>
                {message && <h3>{message}</h3>}
                {content}
                {loading && <Segment loading />}
            </Modal.Content>
            {!content &&
                <Modal.Actions>
                    {cancel &&
                        <Button onClick={onClose} basic disabled={loading}>
                            Cancel
                        </Button>
                    }
                    <Button onClick={onClick} loading={loading}>
                        {actionButton ? actionButton : 'Got it'}
                    </Button>
                </Modal.Actions>
            }
        </Modal>
    );
}

export default ResponseModal;
