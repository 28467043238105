// react
import React, { useEffect, useState } from "react";
import { Switch, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLoadScript } from "@react-google-maps/api";

// styles
import "./styles/default.css";
import "semantic-ui-css/semantic.min.css";

// Route Components:
import PrivateRoute from './components/routes/PrivateRoute';
import PublicRoute from './components/routes/PublicRoute';

// Public Pages
import Home from "./pages/public/homepage/Home";
import Error from "./pages/public/Error";
import Dashboard from "./pages/dashboard/Dashboard";

// Auth Pages
import Login from "./pages/public/auth/Login";
import Registration from "./pages/public/auth/register/Registration";
import TipperRegister from "./pages/public/auth/register/TipperRegister";
import Activation from "./pages/public/auth/Activation";
import ResetPassword from "./pages/public/auth/ResetPassword";
import ConfirmResetPassword from "./pages/public/auth/ConfirmResetPassword";

// others
import "./store/i18n";
import { googleMapsApiKey, libraries } from "./store/config";
import { routes } from './store/routes/index'





function App() {
    // eslint-disable-next-line
    const { } = useLoadScript({
        googleMapsApiKey: googleMapsApiKey,
        libraries,
    })


    const { i18n } = useTranslation();
    const language = useSelector((state) => state.language);
    const [initLang] = useState(language);

    useEffect(() => {
        i18n.changeLanguage(initLang);
    }, [i18n, initLang]);


    return (
        <Switch>
            <PublicRoute restricted={false} exact path={routes.HOME} component={Home} />
            <PublicRoute exact restricted={true} path={routes.LOGIN} component={Login} />
            <PublicRoute exact restricted={true} path={routes.REGISTRATION} component={Registration} />
            <PublicRoute exact restricted={true} path={routes.REGISTRATION + ":link"} component={Registration} />
            <PublicRoute exact restricted={true} path={routes.TIPPER_REGISTER} component={TipperRegister} />
            <PublicRoute exact restricted={true} path={routes.ACTIVATION} component={Activation} />
            <PrivateRoute path={routes.DASHBOARD} component={Dashboard} />
            <PublicRoute
                restricted={false}
                exact
                path="/activate/:uid/:token"
                component={Activation}
            />
            <PublicRoute restricted={false} exact path="/password-reset" component={ResetPassword} />
            <PublicRoute restricted={false}
                exact
                path="/password/reset/confirm/:uid/:token"
                component={ConfirmResetPassword}
            />
            <PublicRoute restricted={false} exact path="*">
                <Error code={"404"} message={"Page not found, Sorry!"} />
            </PublicRoute>
        </Switch>
    );
}

export default withRouter(App);
