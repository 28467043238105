import React, { useEffect, useState } from 'react'
import { Button, Grid, Header, Modal, Segment } from 'semantic-ui-react'
import Technology from '../../../../../components/general/Technology'
import { technologyService } from '../../../../../store/services/common/TechnologyService'

const ShowModal = ({ open, onClose, data }) => {
    const [technology, setTechnology] = useState({ id: 0, name: 'none' })
    const [loadingTech, setLoadingTech] = useState(true)
    useEffect(() => {
        async function fetchTech() {
            setLoadingTech(true)
            let result = await technologyService.getTechnologyByID(data.technology)
            if (result.status === 200) setTechnology(result.response)
            setLoadingTech(false)
        }
        if (data.technology) fetchTech()
    }, [data])
    const InfoCol = ({ name, value }) => {
        return (
            <Grid.Column width={8}>
                <Header style={{ display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: 'column' }}>
                    {value}
                    <Header.Subheader>{name}</Header.Subheader>
                </Header>
            </Grid.Column>
        )
    }
    function getTimeType(input) {
        if (input === 1) return "min"
        if (input === 2) return "H"
        if (input === 3) return "D"
        if (input === 4) return "W"
        if (input === 5) return "M"
    }
    return (
        <Modal
            open={open}
            onClose={onClose}
            size='mini'
        >
            <Modal.Header style={{ textAlign: "center" }}>{data.name}</Modal.Header>
            <Modal.Content as={Grid} centered>
                <InfoCol name="Price" value={`${data.price_display} ${data.currency}`} />
                <InfoCol name="Discount" value={`${data.discount}%`} />
                <InfoCol name="Duration" value={`${data.duration_display} ${getTimeType(data.duration_type)}`} />
                <InfoCol name="Manday limit" value={`${data.man_day_limit}`} />
                <InfoCol name="Technology" value={<Segment loading={loadingTech}><Technology data={technology.name} /></Segment>} />
            </Modal.Content>
            <Modal.Actions>
                <Button content="Cancel" onClick={onClose} />
            </Modal.Actions>
        </Modal>
    )
}

export default ShowModal
