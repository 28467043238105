import React, { useState, useEffect } from 'react'
import { Grid, Header, Button } from 'semantic-ui-react'

import ServiceList from './list/ServiceList'
import CreateService from './services/create/CreateService'
import { serviceService } from '../../../store/services/services/ServicesService'
import { useSelector } from "react-redux";
import Loading from '../../../components/general/Loading'
import ActivationService from './services/ActivationService'
import DeactivationService from './services/DeactivationService'

const Services = () => {
    const [open, setOpen] = useState(false)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [activatingLoading, setActivatingLoading] = useState(false)
    const [activation, setActivation] = useState(false)
    const [deactivation, setDeactivation] = useState(false)
    const [serviceToChange, setServiceToChange] = useState(null)
    const profile = useSelector(state => state.profile)

    useEffect(() => {
        async function fetchData() {
            let services = await serviceService.getServices()
            if (services.status === 200) setData(services.response)
            setLoading(false)
        }
        fetchData()
    }, [])

    function addNewService(input) {
        let array = data
        setData(array.concat(input))
    }

    async function activate() {
        setActivatingLoading(true)
        let result = await serviceService.updateService(serviceToChange, { is_active: true })
        let array = data.filter(item => item.id !== result.response.id)
        array = array.concat(result.response)
        setData(array)
        setActivation(false)
        setActivatingLoading(false)
    }
    async function deactivate() {
        setActivatingLoading(true)
        let result = await serviceService.updateService(serviceToChange, { is_active: false })
        let array = data.filter(item => item.id !== result.response.id)
        array = array.concat(result.response)
        setData(array)
        setDeactivation(false)
        setActivatingLoading(false)
    }
    function openActivation(input) {
        setServiceToChange(input)
        setActivation(true)
    }
    function openDectivation(input) {
        setServiceToChange(input)
        setDeactivation(true)
    }
    return (
        <Grid>
            <ActivationService open={activation} cancel={() => setActivation(false)} onActivation={() => activate()} loading={activatingLoading} />
            <DeactivationService open={deactivation} cancel={() => setDeactivation(false)} onDeactivation={() => deactivate()} loading={activatingLoading} />
            <CreateService open={open} onClose={() => setOpen(false)} id={profile.user.id} addService={(e) => addNewService(e)} />
            <Grid.Row style={{ paddingBottom: 0, display: 'flex', justifyContent: 'space-between' }}>
                <Header as='h1' style={{ margin: 0, padding: '0 1rem', color: 'var(--primary)' }}>
                    My services
                    <Header.Subheader>Manage your services</Header.Subheader>
                </Header>
                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", padding: '0 1rem' }}>
                    <Button content="Create service" icon="plus" onClick={() => setOpen(true)} />
                </div>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: 0, width: '100%' }}>
                {loading && <Loading />}
                {!loading && <ServiceList
                    data={data}
                    activate={(e) => openActivation(e)}
                    deactivate={(e) => openDectivation(e)} />}
            </Grid.Row>
        </Grid>
    )
}
export default Services