import { APIRequest } from '../helpers/requests';
import { API } from '../../config';


export const serviceOfferService = {
    getAllServiceOffers,
    createServiceOffer,
    getServiceOfferById,
    updateServiceOffer,
    deleteServiceOffer,
    activateServiceOffer,
    deactivateServiceOffer
}

async function getAllServiceOffers() {
    return await APIRequest({
        url: API.SERVICES_OFFERS,
        method: 'GET',
        data: {},
        private: true
    });
}
async function createServiceOffer(data) {
    return await APIRequest({
        url: API.SERVICES_OFFERS,
        method: 'POST',
        data: data,
        private: true
    });
}
async function getServiceOfferById(id) {
    return await APIRequest({
        url: API.SERVICES_OFFERS + id,
        method: 'GET',
        data: {},
        private: true
    });
}
async function updateServiceOffer(id, data) {
    return await APIRequest({
        url: API.SERVICES_OFFERS + id,
        method: 'PATCH',
        data: data,
        private: true
    });
}
async function deleteServiceOffer(id) {
    return await APIRequest({
        url: API.SERVICES_OFFERS + id,
        method: 'DELETE',
        data: {},
        private: true
    });
}
async function deactivateServiceOffer(id) {
    return await APIRequest({
        url: API.SERVICES_OFFERS + id,
        method: 'PATCH',
        data: { is_active: false },
        private: true
    });
}
async function activateServiceOffer(id) {
    return await APIRequest({
        url: API.SERVICES_OFFERS + id,
        method: 'PATCH',
        data: { is_active: true },
        private: true
    });
}