import React, { Fragment, useState } from 'react';
// components
import DataTable from '../../../../components/tables/DataTable'
import ActionButton from '../../../../components/buttons/ActionButton'
import InfoModal from '../../../../components/modals/InfoModal'
import SuperModal from '../../../../components/modals/SuperModal'
import { Button } from "semantic-ui-react";

//Services
import { dateService } from '../../../../store/services/custom/DateServices'
import { registerService } from '../../../../store/services/users/RegistrationService'

const TableRequests = ({ data }) => {
    const [state, setState] = useState(data)
    const [item, setItem] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const [openSuperModal, setOpenSuperModal] = useState(false)
    const [positive, setPositive] = useState(false)
    const [loading, setLoading] = useState(false)
    const [header, setHeader] = useState("")
    const [content, setContent] = useState("")

    const orderedHeaders = [
        'id',
        'company_name',
        'get_role',
        'email',
        'create_date',
        'tipper',
        'tableActions',
    ];
    const TableAction = ({ row }) => {
        return (
            <Button.Group>
                <ActionButton icon="eye" action={() => action(row, 'open')} />
                <ActionButton icon="checkmark" action={() => action(row, 'accept')} />
                <ActionButton icon="close" action={() => action(row, 'deny')} />
            </Button.Group>
        );
    }
    const headers = {
        id: {
            text: 'Id',
            invisible: true,
            filterable: false,
            transform: (value) => `#${value}`,
        },
        company_name: {
            text: 'Name',
            sortable: true,
            filterable: true,
        },
        get_role: {
            text: 'Role',
            sortable: true,
            filterable: true,
        },
        email: {
            text: 'Email',
            sortable: true,
            filterable: true,
        },
        tipper: {
            text: 'Tipper',
            sortable: true,
            filterable: true,
            transform: (value) => <>{value ? value : 'none'}</>
        },
        create_date: {
            text: 'Request send on',
            sortable: true,
            filterable: true,
            transform: (value, index, row) => {
                return dateService.convertDateWithDots(row.date_joined)
            },
        },
        tableActions: {
            text: 'Akcie',
            invisible: false,
            sortable: false,
            filterable: false,
            transform: (value, index, row) => {
                return <TableAction row={row} index={index} />
            },
        },
        dit_number: { invisible: true },
        firstname: { invisible: true },
        get_language: { invisible: true },
        get_status: { invisible: true },
        identification_number: { invisible: true },
        language: { invisible: true },
        lastname: { invisible: true },
        message: { invisible: true },
        phone: { invisible: true },
        role: { invisible: true },
        status: { invisible: true },
    }
    async function acceptRequest() {
        setLoading(true)
        let result = state.filter((x) => (x.id !== item.id))
        await registerService.registrationApprove(item.id)
        setState(result)
        setOpenModal(false)
        setOpenSuperModal(false)
        setLoading(false)
    }
    async function denyRequest() {
        setLoading(true)
        let result = state.filter((x) => (x.id !== item.id))
        await registerService.registrationReject(item.id)
        setState(result)
        setOpenModal(false)
        setOpenSuperModal(false)
        setLoading(false)
    }

    const action = (data, action) => {
        setItem(data)
        if (action === 'open') {
            setOpenModal(true)
        } else if (action === 'accept') {
            setHeader("Approve")
            setContent("Do you really want to accept this request?")
            setPositive(true)
            setOpenSuperModal(true)
        } else if (action === 'deny') {
            setHeader("Refuse")
            setContent("Do you really want to refuse this request?")
            setPositive(false)
            setOpenSuperModal(true)
        }
    }
    return (
        <Fragment>
            <InfoModal
                data={item}
                open={openModal}
                onClose={() => setOpenModal(false)}
                onAccept={() => acceptRequest()}
                onDeny={() => denyRequest()}
                loading={loading}
            />
            <DataTable
                data={state}
                orderedHeaders={orderedHeaders}
                headers={headers}
            />
            <SuperModal
                onClose={() => setOpenSuperModal(false)}
                onOpen={() => setOpenSuperModal(true)}
                open={openSuperModal}
                header={header}
                content={content}
                onAccept={() => acceptRequest()}
                onDeny={() => denyRequest()}
                positive={positive}
                size="mini"
                loading={loading}
            />
        </Fragment>
    )
}
export default TableRequests
