import React from 'react'
import { Grid, Icon } from 'semantic-ui-react'

const SectionOne = () => {
    return (
        <Grid className="home" style={{ padding: "5% 0" }}>
            <Grid.Column mobile={16} tablet={16} computer={8}>
                <div className="header home-header" style={styles.text_char}>{"<"}</div>
                <ul style={styles.list_text}>
                    <li className="header home-header" style={styles.text}>{"TALENTS & TEAMS"}</li>
                    <li className="header home-header" style={styles.text}>OUTSOURCING</li>
                    <li className="header home-header" style={styles.text}>PLATFORM</li>
                </ul>
                <div className="header home-header" style={styles.text_char}>{"/>"}</div>
                <ul style={styles.list_text}>
                    <li>We are great networkers.</li>
                    <li>We connect IT experts with best employers.</li>
                    <li>We encourage to improve - both companies and employees.</li>
                </ul>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
                <Grid style={styles.box_container}>
                    <Grid.Column style={styles.box}><Icon name="user outline" size="huge" />Talent Search</Grid.Column>
                    <Grid.Column style={styles.box_pink}><Icon name="user outline" size="huge" />Dedicated Teams</Grid.Column>
                    <Grid.Column style={styles.box_empty} className="home-empty-box"></Grid.Column>
                    <Grid.Column style={styles.box_empty} className="home-empty-box"></Grid.Column>
                    <Grid.Column style={styles.box}><Icon name="user outline" size="huge" />Project Management</Grid.Column>
                    <Grid.Column style={styles.box}><Icon name="user outline" size="huge" />Outsourcing</Grid.Column>
                </Grid>
            </Grid.Column>
        </Grid >
    )
}
const styles = {
    text_char: {
        color: "var(--primary)",
    },
    box: {
        textAlign: "center",
        fontWeight: "bold",
        backgroundColor: "var(--light)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        margin: "0.5rem",
        width: 150,
        height: 150,

    },
    box_container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    box_empty: {
        width: 150,
        height: 150,
        margin: "0.5rem",
    },
    box_pink: {
        backgroundColor: "var(--primary)",
        color: "var(--white)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        margin: "0.5rem",
        width: 150,
        height: 150,
        textAlign: "center",
        fontWeight: "bold",
    },
    list_text: {
        listStyleType: "none",
        padding: 0,
        fontSize: 20,
        lineHeight: 1.2,
        margin: 0
    }
}

export default SectionOne
