import request from "umi-request";
import Cookies from "js-cookie";
import { addProfile, addUser, logoutUser, deleteProfile, addPreferences, deletePreferences } from "../actions/index";
import { APIRequest } from './helpers/requests';
import { API } from "../config";
import { preferencesService } from "./common/PreferencesService";


/*
 * Service functions
 */
export const authService = {
    login,
    logout,
    refreshLogin,
    register,
    resendActivation,
    getAccessToken,
    validateToken,
};

function setAuthTokens(response) {
    let tmpExpiration = response.expiresIn;
    let expiresIn = new Date(new Date().getTime() + tmpExpiration * 1000);

    if (document.location.protocol === "https:") {
        Cookies.set("accessToken", response.access, {
            expires: expiresIn,
            secure: true,
        });
        Cookies.set("refreshToken", response.refresh, { secure: true });
    } else {
        Cookies.set("accessToken", response.access, { expires: expiresIn });
        Cookies.set("refreshToken", response.refresh);
    }
}

/* Auth Services */
async function login(username, password, dispatch, i18n) {

    let result = await APIRequest({
        url: API.LOGIN,
        method: "POST",
        data: {
            username: username,
            password: password,
        },
        private: true
    })

    if (result.status === 200) { // OK
        setAuthTokens(result.response);
        // store user data to Redux Store using dispatch and set him to authorized
        dispatch(addUser(result.response.user))
        let preferences = await preferencesService.getChoices()
        if (preferences.status === 200) dispatch(addPreferences(preferences.response))
        let profile = await APIRequest({
            url: API.PROFILE,
            method: "GET",
            data: {},
            private: true
        })
        if (profile.status === 200) {
            dispatch(addProfile(profile.response))
        }
        //dispatch(setLanguage(response.user.language));
        //i18n.changeLanguage(response.user.language);
    }
    return result;
}

async function refreshLogin(refreshToken) {
    // Extend user session based on refreshToken if exist else redirect user to login
    let result = await request
        .post(API.REFRESH, {
            data: {
                refresh: refreshToken,
            },
        })
        .then(function (response) {
            // store OLD Access & Refresh Token from Cookies,
            Cookies.remove("accessToken");
            Cookies.remove("refreshToken");

            setAuthTokens(response);

            return true;
        })
        .catch(function (error) {
            return false;
        });

    return result;
}

function logout(dispatch) {
    // Perform user logout and remove user from redux store and cookies
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    dispatch(logoutUser())
    dispatch(deleteProfile())
    dispatch(deletePreferences())
}

async function validateToken() {
    const token = Cookies.get("accessToken");
    let response = {};

    if (token) {
        response = await APIRequest({
            url: API.VERIFY,
            method: 'POST',
            data: {
                token: token
            }
        });
    }

    if (response && response.status === 200) {
        return token;
    } else {
        const refresh = Cookies.get("refreshToken");
        // attempt to refreshLogin with refresh token, if true then return new access token
        if (refresh) {
            const result = await refreshLogin(refresh);
            if (result) {
                return Cookies.get("accessToken");
            }
        }
        return false;
    }
}

async function getAccessToken() {
    return await validateToken();
}

/* Register */
async function register(data) {
    let created;
    created = await request(API.REGISTER, {
        method: "post",
        data: data,
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return created;
}

async function resendActivation(data) {
    let sended;
    sended = await request(API.RESEND_ACTIVATION, {
        method: "post",
        data: data,
    })
        .then(function (response) {
            return true;
        })
        .catch(function (error) {
            return false;
        });

    return sended;
}

export async function checkUser() {
    const result = await authService.getAccessToken();
    return !result ? false : true;
}


