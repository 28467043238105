import React from 'react'
import { Grid, Button, Header } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom';

import ActionButton from '../../../../components/buttons/ActionButton'
import { routes } from '../../../../store/routes/index'

const ServiceRow = ({ list, search, type, deactivate, activate }) => {
    let result
    const history = useHistory()

    if (type === 'name') result = list.filter(item => item.name.includes(search))
    if (type === 'id') result = list.filter(item => item.id.includes(search))
    if (type === 'uuid') result = list.filter(item => item.uuid.includes(search))
    const ActionButtonGroup = ({ id, active }) => {
        return (
            <Button.Group>
                <ActionButton action={() => action(id, 'show')} icon="eye" />
                {active && <ActionButton action={() => action(id, 'deactivate')} icon="caret square down" />}
                {!active && <ActionButton action={() => action(id, 'activate')} icon="caret square up" />}
            </Button.Group>)
    }
    function action(id, type) {
        if (type === 'show') history.push(routes.SERVICE_DETAIL + id)
        if (type === 'deactivate') deactivate(id)
        if (type === 'activate') activate(id)
    }


    const renderList = () => {
        if (result.length === 0) return <Header as='h2' style={{ textAlign: "center", color: 'var(--variant3)', width: '100%' }}>no services found</Header>


        return result.map((item, key) => (
            <Grid.Row key={key} style={styles.row} className='service-offer-row'>
                {/* <Grid.Column width={1} style={styles.col}>#{item.id}</Grid.Column> */}
                <Grid.Column width={2} style={styles.col}>{item.uuid}</Grid.Column>
                <Grid.Column width={5} style={styles.col}>{item.name}</Grid.Column>
                <Grid.Column width={3} style={styles.col}>
                    {item.is_outsourcing
                        ?
                        <span style={{ color: 'var(--info)' }}>outsourcing</span>
                        :
                        <span style={{ color: 'var(--danger)' }}>not outsourcing</span>
                    }
                </Grid.Column>
                <Grid.Column width={2} style={styles.col}>offers: {item.service_offers.length}</Grid.Column>
                <Grid.Column width={3} style={styles.btns}>
                    <ActionButtonGroup id={item.id} active={item.is_active} />
                </Grid.Column>
            </Grid.Row>
        ))
    }
    return (
        <Grid style={{ width: "100%" }}>
            {renderList()}
        </Grid>
    )

}
const styles = {
    row: {
        padding: 0,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: 'var(--border)',
        overflow: 'auto'
    },
    col: {
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%'
    },
    btns: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 0
    }

}
export default ServiceRow