import React from 'react'
import { Modal, Button } from 'semantic-ui-react'

const DeactivationService = ({ open, cancel, onDeactivation, loading }) => {
    return (
        <Modal
            open={open}
            size="tiny"
        >
            <Modal.Header style={{ textAlign: "center", color: 'var(--white)' }}>Deactivation of service</Modal.Header>
            <Modal.Content>
                Do you really want to deactivate this service?
            </Modal.Content>
            <Modal.Actions>
                <Button basic onClick={cancel} disabled={loading}>Cancel</Button>
                <Button onClick={onDeactivation} loading={loading}>Deactivate</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default DeactivationService