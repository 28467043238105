import React from 'react';

import { Grid, Card, Item } from 'semantic-ui-react';

const QuickAccessLink = (props) => {
    return (
        <Grid.Column>
            <Card 
                fluid
                style={{ borderBottom: "2px solid var(--primary)", textAlign: "center",cursor: "pointer", borderRadius: 0, padding: "1rem" }} 
                onClick={props.to}
            >
                <Item>
                    <Item.Image src={props.image} style={{ filter: "invert(1)" }} size="mini"/>
                    <Item.Content verticalAlign='middle'>
                        <Item.Header>{props.text}</Item.Header>
                    </Item.Content>
                </Item>
            </Card>
        </Grid.Column>
    );
};

export default QuickAccessLink;