import React, { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import DividerHead from '../../../../components/general/DividerHead'
import InfoRow from '../../../../components/general/InfoRow'
import { businessService } from '../../../../store/services/common/BusinessService'
const BusinessDetails = ({ data }) => {
    const [business, setBusiness] = useState(data ? data : {
        name: '',
        identification_number: '',
        dit_number: '',
        vat_number: '',
        nace_code: '',
        nace_name: '',
        business_register_folder: ''
    })
    useEffect(() => {
        async function fetchData() {
            if (data.nace_code) {
                let result = await businessService.getNaceByID(data.nace_code)
                setBusiness((prevState) => ({
                    ...prevState,
                    nace_code: result.response.code,
                    nace_name: result.response.name,
                }))
            }
        }
        fetchData()
    }, [data.nace_code])


    return (

        <Grid style={styles.container}>
            <DividerHead title="Business" />
            <Grid.Row style={{ padding: 0 }}>
                <Grid>
                    <InfoRow name={"NAME"} value={business.name} />
                    <InfoRow name={"ID"} value={business.identification_number} />
                    <InfoRow name={"DIT"} value={business.dit_number} />
                    <InfoRow name={"VAT"} value={business.vat_number} />
                    <InfoRow name={"NACE"} value={`${business.nace_code} - ${business.nace_name}`} />
                    <InfoRow name={"BUSINESS REGISTER FOLDER"} value={business.business_register_folder} />
                </Grid>
            </Grid.Row>
        </Grid>
    )
}
const styles = {
    container: {
        padding: "1rem",
        borderColor: "var(--border)",
        borderStyle: "solid",
        borderWidth: 1,
        boxShadow: "1px 1px 2px #686868c7",
        backgroundColor: 'var(--white)',
    },
    headding: {
        display: "flex",
        justifyContent: 'space-between',
    },
    content: {
        display: 'flex', flexDirection: "column",
    }
}
export default BusinessDetails