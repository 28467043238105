import React, { useEffect, useState } from 'react';
import Expand from 'react-expand-animated';
import { Button, Form, Grid } from 'semantic-ui-react';
import { projectService } from '../../../store/services/projects/ProjectService';


const ProjectFilter = ({ visible, filter, reset, loading }) => {
    const [data, setData] = useState([])
    const [value, setValue] = useState(0)

    useEffect(() => {
        async function fetch() {
            let result = await projectService.getProjects()
            if (result.status === 200) {
                setData(getOptions(result.response))
            }
        }
        fetch()
    }, [])
    function getOptions(input) {
        let array = []
        let result = input.map((item, id) => (
            array[id] = { key: id, value: item.id, text: item.name }))
        return result
    }
    const handleChange = (e, { value }) => {
        setValue(value)
    }
    return (
        <Expand open={visible}>
            <Grid >
                <Grid.Row centered>
                    <Form.Select
                        disabled={loading}
                        style={{ borderRadius: 0, width: '100%' }}
                        placeholder='Select your project'
                        options={data}
                        value={value}
                        onChange={handleChange}
                    />
                </Grid.Row>
                <Grid.Row centered>
                    <Button content="Reset" basic onClick={reset} icon="redo" />
                    <Button content="Filter" onClick={() => filter(value)} icon="search" />
                </Grid.Row>
            </Grid>
        </Expand>
    );
};

export default ProjectFilter;