import React, { useState } from 'react'
import { Header, Button, Grid } from 'semantic-ui-react'
import LanguageInput from '../../../components/inputs/LanguageInput'
// import CurrencyInput from '../../../components/inputs/CurrencyInput'
import { userService } from '../../../store/services/users/ServiceUser'
import { useSelector } from "react-redux";


const Preferences = ({ container, modal, update }) => {
    // const [currency, setCurrency] = useState("")
    const profile = useSelector(state => state.profile)
    const [language, setLanguage] = useState(profile.language)
    const [loading, setLoading] = useState(false)

    const handleAll = async () => {
        setLoading(true)
        let result = await userService.updateProfile(profile.id, { language: language })
        if (result.status === 200) {
            modal({
                open: true,
                message: 'Your change was successful.',
                icon: 'checkmark',
                header: 'Change'
            })
            update(result, null)
        }
        setLoading(false)
    }

    return (
        <Grid style={container}>
            <Grid.Column width={16}><Header>Preferences</Header></Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <LanguageInput handler={(e) => setLanguage(e)} />
            </Grid.Column>
            {/* <Grid.Column mobile={16} tablet={8} computer={8}>
                <CurrencyInput handler={(e) => setCurrency(e)} />
            </Grid.Column> */}
            <Grid.Column width={16}>
                <Button content="Save changes" onClick={() => handleAll()} disabled={profile.language === language} loading={loading} />
            </Grid.Column>
        </Grid>
    )
}
export default Preferences
