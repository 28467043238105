import React, { useEffect, useState, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { orderService } from '../../../../store/services/orders/OrderServices';
import Loading from '../../../../components/general/Loading'
import DetailHeader from './DetailHeader';
import { Container } from 'semantic-ui-react';
import DetailData from './DetailData';
import BackButton from '../../../../components/buttons/BackButton';
import { ratingService } from '../../../../store/services/common/RatingService';
import OrderRatings from './OrderRatings';

const OrderDetail = () => {
    const params = useParams()
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [ratings, setRatings] = useState([])

    useEffect(() => {
        async function fetchData() {
            let result = await orderService.getOrderByID(parseInt(params.id))
            if (result.status === 200) {
                setData(result.response);
            }
            let result_ = await ratingService.getRatingOrderByID(result.response.id)
            if (result_.status === 200) {
                setRatings(result_.response)
            }
            setIsLoading(false);
        }
        fetchData()
    }, [params.id]);

    function handleRating(e) { setRatings(e) }

    return (
        <Fragment>
            {isLoading
                ?
                <Loading />
                :
                <Container >
                    <BackButton content="Back to list" />
                    <DetailHeader uuid={data.reservation.uuid} when={data.ordered_when} />
                    <DetailData
                        data={data.reservation}
                        providers={data.providers ? data.providers : null}
                        id={data.id}
                        handleRating={(e) => handleRating(e)}
                        ratings={ratings} />
                    <OrderRatings ratings={ratings} />
                </Container>

            }
        </Fragment >
    )
}

export default OrderDetail