// import React, { Fragment } from 'react'
import React, { Fragment, useState, useEffect } from 'react'
import { Tab, Header, Menu, Label } from 'semantic-ui-react'
import Providers from './Providers'
import Clients from './Clients'
import Talents from './Talents'
import Tippers from './Tippers'

import { userService } from '../../../../store/services/users/ServiceUser'
import { useSelector } from "react-redux";
import { Redirect } from 'react-router'
import { routes } from '../../../../store/routes'

const UsersManagement = () => {
    const [providers, setProviders] = useState([])
    const [clients, setClients] = useState([])
    const [tippers, setTippers] = useState([])
    const [talents, setTalents] = useState([])
    const profile = useSelector(state => state.profile)
    useEffect(() => {
        async function fetchData() {
            let result = await userService.getUsers();
            if (result.status === 200) {
                setProviders(result.response.filter((item) => item.user.role === 2))
                setClients(result.response.filter((item) => item.user.role === 3))
                setTippers(result.response.filter((item) => item.user.role === 4))
                setTalents(result.response.filter((item) => item.user.role === 5))
            }
        };
        if (profile.user.role === 1) {
            fetchData();
        }
    }, [profile]);
    let panes = [
        {
            menuItem: (
                <Menu.Item key='Providers'>
                    Providers
                    <Label circular>{providers.length}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane attached={false}><Providers data={providers} /></Tab.Pane>,
        },
        {
            menuItem: (
                <Menu.Item key='Clients'>
                    Clients
                    <Label circular>{clients.length}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane attached={false}><Clients data={clients} /></Tab.Pane>,
        },
        {
            menuItem: (
                <Menu.Item key='Talents'>
                    Talents
                    <Label circular>{talents.length}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane attached={false}><Talents data={talents} /></Tab.Pane>,
        },
        {
            menuItem: (
                <Menu.Item key='Tippers'>
                    Tippers
                    <Label circular>{tippers.length}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane attached={false}><Tippers data={tippers} /></Tab.Pane>,
        },
    ]

    return (
        <Fragment>
            {profile.user.role === 1 ?
                <>
                    <Header as='h1' style={styles.header}>Users Management</Header>
                    <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
                </>
                : <Redirect to={routes.DASHBOARD} />
            }
        </Fragment>
    )
}
const styles = {
    header: {
        color: 'var(--primary)',
        margin: '1rem'
    },
    number: {
        background: 'var(--variant3)',
        borderRadius: '100%',
        padding: '0.5rem',
    }
}
export default UsersManagement