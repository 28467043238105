import React, { useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { preferencesService } from '../../store/services/common/PreferencesService'
import { connect, useSelector } from "react-redux";
import { addPreferences } from '../../store/actions/index'

const CurrencyInput = (props) => {
    const [currencyOptions, setCurrencyOptions] = useState([])
    const [currency, setCurrency] = useState(props.data ? props.data : '')
    const [loading, setLoading] = useState(true)

    const storedChoices = useSelector(state => state.preferences)
    const handleCurrency = (e, { value }) => {
        setCurrency(value)
        props.handler(value)
    }
    useEffect(() => {
        async function fetchData() {
            if (storedChoices.length === 0) {
                let result = await preferencesService.getChoices()
                if (result.status === 200) {
                    props.dispatch(addPreferences(result.response))
                }
            }
            setCurrencyOptions(toObjectOptions(storedChoices.currency_codes))
            setLoading(false)
        }
        fetchData()
    }, [props, storedChoices])


    function toObjectOptions(input) {
        let array = []
        let result = input.map((item, id) => (
            array[id] = { key: id, value: item[0], text: item[1] }))
        return result
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>Currency</span>
            <Form.Select
                disabled={loading}
                style={{ borderRadius: 0, minWidth: '100%' }}
                placeholder='Select your currency'
                options={currencyOptions}
                value={currency}
                onChange={handleCurrency} />

        </div>
    )
}

export default connect()(CurrencyInput)
