const userReducerPreferences = (preferences = [], action) => {
    switch (action.type) {
        case "ADD_PREFERENCES":
            return action.preferences
        case "DELETE_PREFERENCES":
            return [];
        default:
            return preferences;
    }
};

export default userReducerPreferences;
