import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Button, Grid, Header, Segment } from 'semantic-ui-react'
import InfoRow from '../../../components/general/InfoRow'
import Technology from '../../../components/general/Technology'
import ResponseModal from '../../../components/modals/ResponseModal'
import { bundlesService } from '../../../store/services/bundles/BundlesService'
import { technologyService } from '../../../store/services/common/TechnologyService'
import { addBundlesToProfile } from "../../../store/actions/index";

const BundleCard = (props) => {
    const [technology, setTechnology] = useState({ id: 0, name: '' })
    const [loadingTech, setLoadingTech] = useState(true)
    const [openModal, setOpenModal] = useState(false)
    const [messageModal, setMessageModal] = useState(false)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [bought, setBought] = useState([])

    const profile = useSelector(state => state.profile)

    useEffect(() => {
        async function fetchTech() {
            setLoadingTech(true)
            let result = await technologyService.getTechnologyByID(props.data.technology)
            if (result.status === 200) setTechnology(result.response)
            setLoadingTech(false)
        }
        if (props.data.technology) fetchTech()
        setBought(profile.user.bundles)
    }, [profile.user.bundles, props.data])

    function getDurationType(input) {
        if (input === 1) return 'min'
        if (input === 2) return 'H'
        if (input === 3) return 'D'
        if (input === 4) return 'W'
        if (input === 5) return 'M'
    }
    async function buyBundle() {
        setLoading(true)
        let result = await bundlesService.buyBundle(props.data.id)
        if (result.status === 200) {
            let new_bundle_array = bought.concat(props.data)
            props.dispatch(addBundlesToProfile(new_bundle_array))
            setBought(new_bundle_array)
        }
        setMessage(result.response.message)
        setLoading(false)
        setOpenModal(false)
        setMessageModal(true)
    }
    function checkIfAlreadyBought() {
        let found = false
        for (let i in bought) {
            if ((bought[i].id === props.data.id) || bought[i].bundle === props.data.id) found = true
        }
        return found
    }
    return (
        <Grid style={{ border: '1px solid var(--border)' }} className="shadow-card">
            <Grid.Row
                style={{
                    background: 'var(--primary)',
                    display: 'flex',
                    justifyContent: "center",
                    marginBottom: '1rem'
                }}>
                <Header style={{ color: 'var(--white)' }}>{props.data.name}</Header>
            </Grid.Row>

            <InfoRow name='Price' value={`${props.data.price_display} ${props.data.currency}`} />
            <InfoRow name='Discount' value={`${props.data.discount}%`} />
            <InfoRow name='Manday limit' value={props.data.man_day_limit} />
            <InfoRow name='Duration' value={`${props.data.duration_display} ${getDurationType(props.data.duration_type)}`} />
            <Grid.Row style={{ display: 'flex', alignItems: "center", flexDirection: 'column' }}>
                <Segment loading={loadingTech}><Technology data={technology.name} /></Segment>
                {!checkIfAlreadyBought() ?
                    <Button content="Buy bundle" onClick={() => setOpenModal(true)} />
                    :
                    <Button content="Bought" disabled={true} basic />
                }
            </Grid.Row>
            <ResponseModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                header={`${props.data.name}`}
                message="We appreciate your interest"
                onClick={() => buyBundle()}
                loading={loading}
                actionButton="Buy"
                cancel
            />
            <ResponseModal
                open={messageModal}
                onClose={() => setMessageModal(false)}
                message={message}
                onClick={() => setMessageModal(false)}
                actionButton="OK"
            />
        </Grid>
    )
}

export default connect()(BundleCard)
