import React, { useState, useEffect, Fragment } from 'react'
import { Grid, Button } from 'semantic-ui-react'

import PasswordChange from './PasswordChange'
import UsernameChange from './UsernameChange'
import UpdateBusiness from './UpdateBusiness'
import UpdateContact from './UpdateContact'
import PreferencesSettings from './PreferencesSettings'
import Loading from '../../../components/general/Loading'

import { userService } from '../../../store/services/users/ServiceUser'
import { connect } from "react-redux";
import { addProfile, addUser, updateProfileBusiness } from "../../../store/actions/index";
import ResponseModal from '../../../components/modals/ResponseModal'


const Settings = (props) => {
    const [step, setStep] = useState(1)
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([])
    const [responseModal, setResponseModal] = useState({
        icon: '',
        message: 'error',
        header: '',
        open: false
    })

    useEffect(() => {
        async function fetchData() {

            let result = await userService.getUserProfile()
            if (result.status === 200) setData(result.response)
            else {
                let admin = await userService.getUserUser()
                if (admin.status === 200) setData({ user: admin.response })
            }
            setIsLoading(false);
        }

        fetchData();
    }, []);
    const container = {
        background: 'var(--light)',
        margin: '1rem',
        width: '100%',
        borderStyle: "solid",
        borderColor: "var(--border)",
        borderWidth: 1,
    }
    function reduxUpdate(profile, user) {
        if (profile) props.dispatch(addProfile(profile.response))
        if (user) props.dispatch(addUser(user.response))
    }
    function reduxBusinessUpdate(input) {
        if (input) props.dispatch(updateProfileBusiness(input))
    }
    function modalAction(output) {
        setResponseModal((prevState) => ({
            ...prevState,
            open: output.open,
            message: output.message,
            header: output.header,
            icon: output.icon,
            content: output.content,
        }))
    }

    return (
        <Fragment>
            <ResponseModal
                open={responseModal.open}
                onClose={() => setResponseModal((prevState) => ({ ...prevState, open: false }))}
                icon={responseModal.icon}
                header={responseModal.header}
                message={responseModal.message}
                onClick={() => setResponseModal((prevState) => ({ ...prevState, open: false }))}
                content={responseModal.content}
            />
            {isLoading ? <Loading /> : <>
                <Grid>
                    <Grid.Row style={styles.btn_group}>
                        <Button.Group fluid>
                            <Button content="Change password" icon={step !== 1 ? "down angle" : "up angle"} basic={step !== 1} onClick={() => setStep(1)} style={styles.basic_btn} />
                            <Button content="Change username" icon={step !== 2 ? "down angle" : "up angle"} basic={step !== 2} onClick={() => setStep(2)} style={styles.basic_btn} />
                            {(data.user.role === 2 || data.user.role === 3) &&
                                <Button content="Update business" icon={step !== 3 ? "down angle" : "up angle"} basic={step !== 3} onClick={() => setStep(3)} style={styles.basic_btn} />
                            }
                            <Button content="Update contact" icon={step !== 4 ? "down angle" : "up angle"} basic={step !== 4} onClick={() => setStep(4)} style={styles.basic_btn} />
                            <Button content="Preferences" icon={step !== 5 ? "down angle" : "up angle"} basic={step !== 5} onClick={() => setStep(5)} style={styles.basic_btn} />
                        </Button.Group>
                    </Grid.Row>
                    <Grid.Row style={{ padding: 0 }}>
                        {step === 1 && <PasswordChange container={container} />}
                        {step === 2 && <UsernameChange container={container} update={(profile, user) => reduxUpdate(profile, user)} modal={(output) => modalAction(output)} />}
                        {step === 3 && <UpdateBusiness data={data.business_detail} container={container} update={(e) => reduxBusinessUpdate(e)} modal={(output) => modalAction(output)} />}
                        {step === 4 && <UpdateContact container={container} update={(profile, user) => reduxUpdate(profile, user)} modal={(output) => modalAction(output)} />}
                        {step === 5 && <PreferencesSettings container={container} update={(profile, user) => reduxUpdate(profile, user)} modal={(output) => modalAction(output)} />}
                    </Grid.Row>

                </Grid>
            </>
            }
        </Fragment>
    )
}
const styles = {
    btn_group: {
        padding: 0,
    },
    basic_btn: {
        borderBottomStyle: 'solid',
        borderBottomColor: 'var(--primary)',
        borderBottomWidth: 3
    },
}
export default connect()(Settings)
