import React from 'react';
import { Link } from 'react-router-dom';

const HexagonLink = (props) => {
    return (
        <Link to={props.to}>
            <div style={styles.div}>
                <svg style={styles.svg} version="1.1" xmlns="http://www.w3.org/2000/svg" width="160" height="138" viewBox="0 0 160 138.56406460551017">
                    <path fill={props.fill ? props.fill : "var(--primary)"} d="M0 69.28203230275508L40 0L120 0L160 69.28203230275508L120 138.56406460551017L40 138.56406460551017Z">
                    </path>
                    <image x="50%" y="50%"  width="80" height="80" transform="translate(-40,-40)" href={props.icon} />
                </svg>
                <p style={styles.p}>{props.name}</p>
            </div>
        </Link>
    );
};

const styles = {
    svg: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        transform: "scale(0.9)"
    },
    p: {
        color: "var(--black)",
        textAlign: "center",
        fontSize: "1.2rem",
        width: "100%",
        fontWeight: "bold"
    },
    div: {
        marginTop: "1.5rem",
        marginBottom: "1.5rem"
    }
}

export default HexagonLink;