import { APIRequest } from '../helpers/requests';
import { API } from '../../config';

export const userService = {
    getUserProfile,
    getUsers,
    updateProfile,
    setUserName,
    setPassword,
    getUserByID,
    createPerson,
    setUserProfilePerson,
    getUserUser,
    updateUserUser,
    recommendPerson
};

async function getUserProfile() {
    return await APIRequest({
        url: API.PROFILE, // .../api/users/me/
        method: 'GET',
        data: {},
        private: true
    });
}
async function getUserUser() {
    return await APIRequest({
        url: API.USER,
        method: 'GET',
        data: {},
        private: true
    });
}
async function getUsers() {
    return await APIRequest({
        url: API.PROFILES, // .../api/users/me/
        method: 'GET',
        data: {},
        private: true
    });
}
async function updateProfile(id, data) {
    return await APIRequest({
        url: API.PROFILE + id + "/",
        method: 'PATCH',
        data: data,
        private: true
    });
}
async function setUserName(data) {
    return await APIRequest({
        url: API.SET_USERNAME, // .../api/users/set_username/
        method: 'POST',
        data: data,
        private: true
    });
}
async function setPassword(data) {
    return await APIRequest({
        url: API.SET_PASSWORD, // .../api/users/set_password/
        method: 'POST',
        data: data,
        private: true
    });
}
async function getUserByID(id) {
    return await APIRequest({
        url: API.PROFILE + id + '/',
        method: 'GET',
        data: {},
        private: true
    });
}
async function createPerson(data) {
    return await APIRequest({
        url: API.PERSON,
        method: 'POST',
        data: data,
        private: true
    });
}
async function setUserProfilePerson(id, data) {
    return await APIRequest({
        url: API.PERSON + id + '/',
        method: 'PATCH',
        data: data,
        private: true
    });
}
async function updateUserUser(data) {
    return await APIRequest({
        url: API.USER,
        method: 'PATCH',
        data: data,
        private: true
    });
}
async function recommendPerson(data) {
    return await APIRequest({
        url: API.RECOMMENDATION,
        method: 'POST',
        data: {
            email: data
        },
        private: true
    });
}