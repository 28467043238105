import { APIRequest } from '../helpers/requests';
import { API } from '../../config';


export const reservationService = {
    getReservations,
    getClientReservations,
    getProviderReservations,
    createReservations,
    getReservationsByID,
    updateReservationsByID,
    deleteReservationsByID,
    getSelectedServiceOffers,
    createSelectedServiceOffers,
    getSelectedServiceOffersByID,
    acceptSelectedServiceOffers,
    createOrder,
    getClientReservationsByID,
    getProviderReservationsByID
}

async function getReservations() {
    return await APIRequest({
        url: API.RESERVATIONS,
        method: 'GET',
        data: {},
        private: true
    });
}
async function getClientReservations() {
    return await APIRequest({
        url: API.CLIENT_RESERVATIONS,
        method: 'GET',
        data: {},
        private: true
    });
}
async function getProviderReservations() {
    return await APIRequest({
        url: API.PROVIDER_RESERVATIONS,
        method: 'GET',
        data: {},
        private: true
    });
}
async function getClientReservationsByID(id) {
    return await APIRequest({
        url: API.CLIENT_RESERVATIONS + id,
        method: 'GET',
        data: {},
        private: true
    });
}
async function getProviderReservationsByID(id) {
    return await APIRequest({
        url: API.PROVIDER_RESERVATIONS + id,
        method: 'GET',
        data: {},
        private: true
    });
}
async function createReservations(data) {
    return await APIRequest({
        url: API.RESERVATIONS,
        method: 'POST',
        data: data,
        private: true
    });
}
async function getReservationsByID(id) {
    return await APIRequest({
        url: API.RESERVATIONS + id,
        method: 'GET',
        data: {},
        private: true
    });
}
async function updateReservationsByID(id, data) {
    return await APIRequest({
        url: API.RESERVATIONS + id,
        method: 'PATCH',
        data: data,
        private: true
    });
}
async function deleteReservationsByID(id) {
    return await APIRequest({
        url: API.RESERVATIONS + id,
        method: 'DELETE',
        data: {},
        private: true
    });
}
async function getSelectedServiceOffers() {
    return await APIRequest({
        url: API.SELECTED_SERVICE_OFFERS,
        method: 'GET',
        data: {},
        private: true
    });
}
async function createSelectedServiceOffers(data) {
    return await APIRequest({
        url: API.SELECTED_SERVICE_OFFERS,
        method: 'POST',
        data: data,
        private: true
    });
}
async function getSelectedServiceOffersByID(id) {
    return await APIRequest({
        url: API.SELECTED_SERVICE_OFFERS + id,
        method: 'GET',
        data: {},
        private: true
    });
}
async function acceptSelectedServiceOffers(id, data) {
    return await APIRequest({
        url: API.ACCEPT_SELECTED_SERVICE_OFFERS + id,
        method: 'POST',
        data: data,
        private: true
    });
}
async function createOrder(id) {
    return await APIRequest({
        url: API.CREATE_ORDER + id + "/",
        method: 'POST',
        data: {},
        private: true
    });
}