import React, { useState, useEffect } from 'react'
import { Form } from 'semantic-ui-react'
import { preferencesService } from '../../store/services/common/PreferencesService'
import { connect, useSelector } from "react-redux";
import { addPreferences } from '../../store/actions/index'
const Preferences = (props) => {

    const profile = useSelector(state => state.profile)
    const [languageOptions, setLanguageOptions] = useState([])
    const [language, setLanguage] = useState(profile.language)
    const [loading, setLoading] = useState(true)
    const storedChoices = useSelector(state => state.preferences)

    const handleLanguage = (e, { value }) => {
        props.handler(value)
        setLanguage(value)
    }
    useEffect(() => {
        async function fetchData() {
            if (!storedChoices) {
                let result = await preferencesService.getChoices()
                if (result.status === 200) {
                    props.dispatch(addPreferences(result.response))
                }
            }
            setLanguageOptions(toObjectOptions(storedChoices.language_choices))
            setLoading(false)
        }
        fetchData();
    }, [props, storedChoices]);


    function toObjectOptions(input) {
        let array = []
        let result = input.map((item, id) => (
            array[id] = { key: id, value: item[0], text: item[1] }))
        return result
    }
    return (
        <>
            <span>Language</span>
            <Form.Select
                disabled={loading}
                style={{ borderRadius: 0, width: '100%' }}
                placeholder='Select your language'
                options={languageOptions}
                value={language}
                onChange={handleLanguage} />
        </>


    )
}
export default connect()(Preferences)
