import userReducer from "./user";
import userReducerProfile from "./profile";
import userReducerPreferences from "./preferences";
import { combineReducers } from "redux";


const allReducers = combineReducers({
    user: userReducer,
    profile: userReducerProfile,
    preferences: userReducerPreferences
});

export default allReducers;
