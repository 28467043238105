import React from 'react'
import { Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
const BackButton = ({ content }) => {
    const history = useHistory()
    return (
        <Button content={content ?? "Back"} onClick={() => history.goBack()} icon='left arrow' basic labelPosition="left" style={{ margin: "1rem 0" }} />
    )
}

export default BackButton
