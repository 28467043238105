//react
import React, { useState, Fragment } from 'react'
import { Button, Form, Grid, Header, Checkbox, Icon } from 'semantic-ui-react'
//services
import { isEmpty, isEmailError, isPhoneError, isEmptyErrorList } from '../../../../store/services/helpers/validation'
import { registerService } from '../../../../store/services/users/RegistrationService'
//components
import ResponseModal from "../../../../components/modals/ResponseModal"
import RegisterModal from './RegisterModal'
import TermsAndConditions from './TermsAndConditions'
import { useParams } from 'react-router-dom'

const RegisterForm = (props) => {
    const [profile, setProfile] = useState({ first_name: '', last_name: '', email: '', phone: '', message: '' })
    const [business, setBusiness] = useState({ name: '', id: '', dit: '', })
    const [isProcessing, setIsProcessing] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [checked, setChecked] = useState(false)
    const [complete, setComplete] = useState(false)
    const [registerState, setRegisterState] = useState(0)
    const [termsOpen, setTermsOpen] = useState(false)
    const params = useParams()

    const required = [profile.email, business.name, business.id, business.dit,]
    const requiredClient = [profile.first_name, profile.last_name, profile.email,]

    const handleProfile = (e, { name, value }) => setProfile((prevState) => ({ ...prevState, [name]: value }))
    const handleBusiness = (e, { name, value }) => setBusiness((prevState) => ({ ...prevState, [name]: value }))
    function errorEmail() {
        if (isEmailError(profile.email) || isEmpty(profile.email)) return true
        return false
    }
    function errorPhone() {
        if (isPhoneError(profile.phone) && !isEmpty(profile.phone)) return true
        return false
    }
    const handleRegister = async () => {
        setIsProcessing(true)

        if (props.type === 3) {
            let result
            if (!params.link) result = await registerService.registerClient({ email: profile.email })
            if (params.link) result = await registerService.registerClientRef({ email: profile.email }, params.link)
            if (result.status !== 200) {
                if (result.response.email) {
                    setErrorMessage("Email address is taken")
                } else {
                    setErrorMessage(result.response.message)
                }
                setErrorOpen(true)
                setIsProcessing(false)
                return
            } else {
                setIsProcessing(false)
                setRegisterState(1)
                setComplete(true)
                return
            }
        } else {
            const data = {
                firstname: profile.first_name,
                lastname: profile.last_name,
                phone: profile.phone,
                email: profile.email,
                role: props.type,
                company_name: business.name,
                identification_number: business.id,
                dit_number: business.dit,
                language: 1,
                message: profile.message,
            }
            let result
            if (!params.link) result = await registerService.registerUser(data)
            if (params.link) result = await registerService.registerUserRef(data, params.link)
            if (result.status !== 201) {
                if (result.response.email) {
                    setErrorMessage("Email address is taken")
                } else {
                    setErrorMessage(result.response.message)
                }
                setErrorOpen(true)
                setIsProcessing(false)
            } else {
                setIsProcessing(false)
                setRegisterState(1)
                setComplete(true)
                return
            }
        }
        setIsProcessing(false)
    }

    return (
        <Fragment>
            <RegisterModal open={complete} state={registerState} />
            <ResponseModal
                open={errorOpen}
                onClose={() => setErrorOpen(false)}
                icon="warning circle"
                header="Error"
                message={errorMessage}
                onClick={() => setErrorOpen(false)}
            />
            <Grid centered style={{ width: '100%' }}>
                <Grid.Row style={{ display: 'flex', justifyContent: "center" }}>
                    {props.type === 5 &&
                        <Grid.Column mobile={10} tablet={5} computer={5}>
                            <Header style={styles.header}>Profile</Header>
                            <div>
                                <span>First name</span><span style={styles.required}>*</span>
                                <Form.Input
                                    fluid
                                    placeholder="Enter your first name"
                                    value={profile.first_name}
                                    name="first_name"
                                    onChange={handleProfile} />
                            </div>
                            <div>
                                <span>Last name</span><span style={styles.required}>*</span>
                                <Form.Input
                                    fluid
                                    placeholder="Enter your last name"
                                    value={profile.last_name}
                                    name="last_name"
                                    onChange={handleProfile} />
                            </div>
                        </Grid.Column>
                    }
                    <Grid.Column mobile={10} tablet={5} computer={5}>
                        {props.type !== 3 && <Header style={styles.header}>Contact</Header>}
                        <div style={styles.input_box}>
                            <span>Email</span><span style={styles.required}>*</span>
                            <Form.Input
                                placeholder="Enter your email"
                                fluid
                                value={profile.email}
                                name="email"
                                onChange={handleProfile}
                                error={
                                    !isEmpty(profile.email)
                                        ? isEmailError(profile.email)
                                        : false
                                }
                            />
                            {props.type === 3 &&
                                <p style={{ textAlign: "center", padding: '1rem 0' }}>
                                    If you have project, that needs new people to join it, you can sign up to our platform and find the best choice for you. You will fill your profile after you login with new credentials that will be sent to email you entered. After all needed steps, you will be allowed to hire people from our providers or talents.
                                </p>
                            }
                        </div>
                        {props.type !== 3 &&

                            <div style={styles.input_box}>
                                <span>Phone</span>
                                <Form.Input
                                    placeholder="+421 999 999 999"
                                    name="phone"
                                    fluid
                                    value={profile.phone}
                                    onChange={handleProfile}
                                    error={
                                        !isEmpty(profile.phone)
                                            ? isPhoneError(profile.phone)
                                            : false
                                    } /></div>
                        }

                    </Grid.Column>
                    {(props.type === 2 || props.type === 4) &&
                        <Grid.Column mobile={10} tablet={5} computer={5}>
                            <Header style={styles.header}>Business</Header>
                            <div>
                                <span>Name</span><span style={styles.required}>*</span>
                                <Form.Input
                                    fluid
                                    placeholder="Enter business name"
                                    value={business.name}
                                    name="name"
                                    onChange={handleBusiness} />
                            </div>
                            <div>
                                <span>Identification number</span><span style={styles.required}>*</span>
                                <Form.Input
                                    fluid
                                    placeholder="Enter ID"
                                    value={business.id}
                                    name="id"
                                    onChange={handleBusiness} />
                            </div>
                            <div>
                                <span>DIT</span><span style={styles.required}>*</span>
                                <Form.Input
                                    required
                                    fluid
                                    placeholder="Enter DIT"
                                    value={business.dit}
                                    name="dit"
                                    onChange={handleBusiness} />
                            </div>
                        </Grid.Column>
                    }
                </Grid.Row>

                {props.type !== 3 &&// IF NOT CLIENT SHOW REQUEST
                    <Grid.Row>
                        <Grid.Column width={10}>
                            <Header style={styles.header}>Request</Header>
                            <Form.TextArea
                                style={styles.input_column_text}
                                rows={4}
                                placeholder="Write down your request message."
                                name="message"
                                value={profile.message}
                                onChange={handleProfile} />
                        </Grid.Column>
                    </Grid.Row>
                }
                <Grid.Row style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Form.Field style={{ display: 'flex', alignItems: "center" }}>
                        <Checkbox
                            style={{ marginRight: "0.5rem" }}
                            onChange={() => setChecked(!checked)}
                            checked={checked} />
                        <span>I agree to the <span style={{ fontWeight: "bold", textDecoration: "underline", cursor: 'pointer' }} onClick={() => setTermsOpen(true)}>Terms and Conditions</span></span>
                        <span style={styles.required}>*</span>
                    </Form.Field>
                </Grid.Row>
                <Button
                    onClick={props.handler}
                    basic
                    style={styles.submit_button}><Icon name="left angle" />Go back</Button>
                <Button
                    style={styles.submit_button}
                    onClick={handleRegister}
                    loading={isProcessing}
                    disabled={
                        isProcessing ||
                        errorEmail() ||
                        errorPhone() ||
                        (props.type !== 5 && props.type !== 3 && !isEmptyErrorList(required)) ||
                        (props.type === 5 && !isEmptyErrorList(requiredClient)) ||
                        !checked
                    }
                >Register</Button>
            </Grid>
            <TermsAndConditions open={termsOpen} onClose={() => setTermsOpen(false)} />
        </Fragment>
    )
}
const styles = {
    input_column_check: { margin: "0.87rem" },
    input_column_text: {
        display: "flex",
        width: "100%",
        height: "auto",
        borderColor: "var(--border)",
    },
    header: {
        textDecoration: "underline",
        fontSize: "2rem"
    },
    back_button: {
        position: "absolute",
        left: "1rem",
        margin: "1rem",
        zIndex: 1
    },
    submit_button: {
        margin: "2rem"
    },
    required: {
        color: "var(--danger)",
        fontWeight: "bold"
    }
}
export default RegisterForm