import React, { useState } from "react";
// store
import { resetPasswordService } from "../../../store/services/ServiceResetPassword";
import { isEmpty, isEmailError } from "../../../store/services/helpers/validation";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// routes
import { routes } from "../../../store/routes/index";

// styles
import {
    Container,
    Grid,
    Segment,
    Image,
    Form
} from "semantic-ui-react";
import ResponseModal from "../../../components/modals/ResponseModal";

// component
function ResetPasswordForm(props) {
    // state
    const [email, setEmail] = useState(props.email);
    const [isProcessing, setIsProcessing] = useState(false);
    const [modal, setModal] = useState(false);
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        // validate form fields
        let formError = false;
        if (isEmailError(email)) {
            formError = true;
        }

        if (!formError) {
            // send request for reset password
            if (await resetPasswordService.requestResetPassword(email)) {
                setMessage('Request send successfully.')
                setModal(true)
                setEmail("");
            } else {
                setMessage('Something went wrong.')
                setModal(true)
            }
        }

        setIsProcessing(false);
    };

    // template
    return (
        <Container>
            <ResponseModal
                open={modal}
                onClose={() => setModal(false)}
                onClick={() => setModal(false)}
                message={message}
                actionButton="Ok"
                dimmer={'inverted'}
                header='Request password reset'
            />
            <Grid centered>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Form >
                        <Segment>
                            <Link to={routes.HOME}>
                                <Image centered src="/images/hiringINIT_logo.svg" />
                            </Link>
                            <h1 style={{ textAlign: "center", color: 'var(--black)' }}>Request password reset</h1>
                            <Form.Group>
                                <Form.Input
                                    icon="mail"
                                    iconPosition="left"
                                    fluid
                                    width={16}
                                    required
                                    autoFocus
                                    label="Email"
                                    placeholder="Enter valid email address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={!isEmpty(email) ? isEmailError(email) : false}
                                />
                            </Form.Group>

                            <Form.Button
                                fluid primary
                                size="huge"
                                onClick={handleSubmit}
                                loading={isProcessing}
                                disabled={isProcessing || isEmpty(email) || isEmailError(email)}
                            >
                                Send request
                            </Form.Button>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        </Container>
    );
}

export default connect()(ResetPasswordForm);
