import React from 'react'
import { Grid } from 'semantic-ui-react'

const ProjectContent = ({ data }) => {
    return (
        <Grid.Row style={styles.container}>
            <Grid.Column style={styles.description} computer={16} tablet={16} mobile={16}>{data.description}</Grid.Column>
        </Grid.Row >
    )
}
const styles = {
    container: {
        borderStyle: 'solid',
        borderWidth: 1.5,
        borderColor: 'var(--border)',
        margin: '0 1rem',
        textAlign: "center",
    },
    description: {
        padding: '1rem 4rem',
        textAlign: 'justify'
    }
}
export default ProjectContent
