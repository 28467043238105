export const googleSearchService = {
    getAddress,
    getCity,
    getState,
    getPostalCode,
    getCountry,
    getAddressLine,
}

function getAddress(input) {
    let route = input.filter(item => item.types.indexOf("route") > -1)
    let premise = input.filter(item => item.types.indexOf("premise") > -1)
    let locality = input.filter(item => item.types.indexOf("locality") > -1)
    let number = input.filter(item => item.types.indexOf("street_number") > -1)
    if (number[0]) {
        return route[0].long_name + " " + number[0].long_name
    } else if (premise[0]) {
        return locality[0].long_name + " " + premise[0].long_name
    }
}
function getCity(input) {
    let locality = input.filter(item => item.types.indexOf("locality") > -1)
    let administrative_area_level_2 = input.filter(item => item.types.indexOf("administrative_area_level_2") > -1)
    if (locality[0]) {
        return locality[0].long_name
    } else if (administrative_area_level_2[0]) {
        return administrative_area_level_2[0].long_name
    }
}
function getState(input) {
    let administrative_area_level_1 = input.filter(item => item.types.indexOf("administrative_area_level_1") > -1)
    if (administrative_area_level_1[0]) {
        return administrative_area_level_1[0].long_name
    }
}
function getPostalCode(input) {
    let postal_code = input.filter(item => item.types.indexOf("postal_code") > -1)
    if (postal_code[0]) {
        return postal_code[0].long_name
    }
}
function getCountry(input) {
    let country = input.filter(item => item.types.indexOf("country") > -1)
    if (country[0]) {
        return country[0].short_name
    }
}
function getAddressLine(input) {
    if (input.formatted_address) {
        return input.formatted_address
    }
}