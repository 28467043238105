import { APIRequest } from '../helpers/requests';
import { API } from '../../config';

export const orderService = {
    getClientOrders,
    getProviderOrders,
    getOrderByID
}

async function getClientOrders() {
    return await APIRequest({
        url: API.CLIENT_ORDERS,
        method: 'GET',
        data: {},
        private: true
    });
}

async function getProviderOrders() {
    return await APIRequest({
        url: API.PROVIDER_ORDERS,
        method: 'GET',
        data: {},
        private: true
    });
}
async function getOrderByID(id) {
    return await APIRequest({
        url: API.ORDER + id,
        method: 'GET',
        data: {},
        private: true
    });
}