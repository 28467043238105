import React from 'react'
import { Modal, Button } from 'semantic-ui-react'

const ProjectDeleteModal = ({ open, cancel, onDelete, }) => {
    return (
        <Modal
            open={open}
            size="tiny"
        >
            <Modal.Header style={{ textAlign: "center" }}>Delete project</Modal.Header>
            <Modal.Content>
                Do you really want to delete this project?
            </Modal.Content>
            <Modal.Actions>
                <Button basic onClick={cancel}>Cancel</Button>
                <Button onClick={onDelete}>Delete</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ProjectDeleteModal
