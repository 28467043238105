import React, { useState } from 'react'
import { Button, Form, Header, Modal, Rating } from 'semantic-ui-react'
import { ratingService } from '../../../../store/services/common/RatingService'

const RatingModal = ({ order, provider, open, onClose, handleRating }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({
        error: false,
        message: ''
    })
    const [rate, setRate] = useState({
        rate: 0,
        comment: ''
    })

    async function sendRate() {
        setLoading(true)
        let result = await ratingService.createRatingOrderByID(order,
            {
                rate: rate.rate,
                comment: rate.comment,
                rated_person: provider.id
            })
        if (result.status === 201) {
            setRate({
                rate: 0,
                comment: ''
            })
            let new_data = await ratingService.getRatingOrderByID(order)
            if (new_data.status === 200) {
                handleRating(new_data.response)
                setLoading(false)
                onClose()
            }
        } else {
            setError({ error: true, message: result.response.message })
        }

        setLoading(false)
    }
    function handleComment(e, { value }) { setRate(prev => ({ ...prev, comment: value })) }
    function handleRate(e, { rating }) { setRate(prev => ({ ...prev, rate: rating })) }
    return (
        <Modal
            open={open}
            onClose={onClose}
            size='mini'
        >
            <Modal.Header>Rate{provider ? `: ${provider.name}` : ''}</Modal.Header>
            <Modal.Content >
                {!error.error ?
                    <>
                        <Form.TextArea
                            placeholder='Max. 50 characters.'
                            rows={4}
                            value={rate.comment}
                            style={styles.text_area}
                            onChange={handleComment}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", marginTop: '1rem' }}>
                            <Rating size='huge' icon='star' defaultRating={rate.rate} maxRating={5} onRate={handleRate} />
                        </div>
                    </>
                    :
                    <Header>{error.message}</Header>
                }

            </Modal.Content>
            <Modal.Actions>
                {!error.error &&
                    <>
                        <Button basic content='Cancel' onClick={onClose} disabled={loading} />
                        <Button content='Send rate' onClick={() => sendRate()} loading={loading} />
                    </>
                }
                {error.error &&
                    <Button content='Close' onClick={onClose} loading={loading} />
                }
            </Modal.Actions>
        </Modal>
    )
}
const styles = {
    comment_container: {
        background: 'var(--light)',
        border: '1px solid var(--border)',
        margin: '0.5rem 0rem',
        padding: '0.5rem',
    },
    text_area: {
        display: "flex",
        width: "100%",
        height: "auto",
        borderColor: "var(--border)",
    }
}


export default RatingModal
