import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Button, Icon, Label } from "semantic-ui-react";
import ActionButton from "../../../components/buttons/ActionButton";
import DataTable from "../../../components/tables/DataTable";
import { routes } from "../../../store/routes";
import { dateService } from "../../../store/services/custom/DateServices";

const ReservationTable = ({ data }) => {
    let history = useHistory()
    const [dataToTable, setDataToTable] = useState([])
    const pref = useSelector(state => state.preferences)
    const profile = useSelector(state => state.profile)

    useEffect(() => {
        async function fetchData() {
            setDataToTable(
                data.map((item => (
                    {
                        uuid: item.uuid,
                        is_premium: item.is_premium,
                        status: item.status,
                        created_when: item.created_when,
                        valid_until: item.valid_until,
                        tableActions: item.id,
                    }
                )))
            )

        };
        fetchData();
    }, [data, profile.user.role]);
    function getStatus(input) {
        let result
        pref.status_choices.filter(item => {
            if (item[0] === input) {
                result = item[1]
            }
            return item
        })
        return result
    }
    function getStatusStyle(status) {
        if (status === 1) return { textAlign: 'center', fontWeight: "bold", color: 'var(--black)', background: 'var(--white)', padding: '0 0.5rem', border: '1px solid var(--black)', borderRadius: 'var(--radius-label)' }
        if (status === 2) return { textAlign: 'center', fontWeight: "bold", color: 'var(--white)', background: 'var(--primary)', padding: '0 0.5rem', borderRadius: 'var(--radius-label)' }
        if (status === 3) return { textAlign: 'center', fontWeight: "bold", color: 'var(--white)', background: 'var(--black)', padding: '0 0.5rem', borderRadius: 'var(--radius-label)' }
        return
    }
    function getTotalPrice(input) {
        let result = 0
        let new_data
        if (profile.user.role === 2) new_data = data[input].provider_selected_service_offers
        if (profile.user.role === 3) new_data = data[input].selected_service_offers
        new_data.filter(item => {
            result += (parseFloat(item.price_display))
            return item
        })
        return `${result} ${new_data[0].service_offer.currency}`
    }
    function getTotalPeople(input) {
        let result = 0
        let new_data
        if (profile.user.role === 2) new_data = data[input].provider_selected_service_offers
        if (profile.user.role === 3) new_data = data[input].selected_service_offers
        new_data.filter(item => {
            result += parseFloat(item.total_men)
            return item
        })

        return result
    }
    function getAllTechnologies(input) {
        let array = []
        let new_data
        if (profile.user.role === 2) new_data = data[input].provider_selected_service_offers
        if (profile.user.role === 3) new_data = data[input].selected_service_offers
        new_data.filter(item => {
            array = array.concat(item.service_offer.name)
            return item
        })
        let result = [];
        array.forEach((c) => {
            if (!result.includes(c)) {
                result.push(c);
            }
        })
        return result.map((item, key) => (
            <Label key={key} style={styles.technology}><Icon name='cog' />{item}</Label>
        ))
    }
    function getNumberOfOffers(input) {
        let new_data
        if (profile.user.role === 2) new_data = data[input].provider_selected_service_offers
        if (profile.user.role === 3) new_data = data[input].selected_service_offers
        return new_data.length
    }
    const orderedHeaders = [
        'uuid',
        'status',
        'total_price',
        'total_people',
        'number_of_offers',
        'created_when',
        'valid_until',
        'technologies',
        'tableActions'
    ];
    const TableAction = ({ row }) => {
        return (
            <Button.Group>
                <ActionButton icon="eye" action={() => action(row)} circular={true} />
            </Button.Group>
        );
    }
    const headers = {
        is_premium: {
            invisible: true
        },
        uuid: {
            text: 'UUID',
            sortable: true,
            filterable: true,
            transform: (value, index, row) => {
                if (row.is_premium) return (<div style={styles.premium}>#{value}<Icon name="star" /></div>)
                return (<div style={styles.basic}>#{value}</div>)
            }
        },
        status: {
            text: 'Status',
            sortable: true,
            filterable: true,
            transform: (value, index, row) => {
                return (<div style={getStatusStyle(value)}>{getStatus(value)}</div>)
            }
        },
        created_when: {
            text: 'Created',
            sortable: true,
            filterable: true,
            transform: (value, index, row) => {
                return dateService.convertDateWithDots(row.created_when)
            },
        },
        valid_until: {
            text: 'Valid until',
            sortable: true,
            filterable: true,
            transform: (value, index, row) => {
                if (row.valid_until) return dateService.convertDateWithDots(row.valid_until)
                return
            },
        },
        total_price: {
            text: 'Total price',
            sortable: false,
            filterable: true,
            transform: (value, index, row) => {
                return (<div style={{ textAlign: 'right' }}>{getTotalPrice(index)}</div>)
            },
        },
        total_people: {
            text: 'Total people',
            sortable: false,
            filterable: true,
            transform: (value, index, row) => {
                return (<div style={{ textAlign: "center" }}>{getTotalPeople(index)}</div>)
            },
        },
        number_of_offers: {
            text: 'Offers',
            sortable: false,
            filterable: true,
            transform: (value, index, row) => {
                return (<div style={{ textAlign: "center" }}>{getNumberOfOffers(index)}</div>)
            },
        },
        technologies: {
            text: 'Technologies',
            sortable: false,
            filterable: true,
            transform: (value, index, row) => <>{getAllTechnologies(index)}</>
        },
        tableActions: {
            text: 'Detail',
            sortable: false,
            filterable: true,
            transform: (value, index, row) => {
                return <TableAction row={value} index={index} />
            },
        }
    }
    function action(row) {
        history.push(routes.RESERVATIONS + row)
    }


    return (
        <Fragment>
            <DataTable
                data={dataToTable}
                orderedHeaders={orderedHeaders}
                headers={headers}
            />
        </Fragment>
    )
}
const styles = {
    container: {
        background: 'var(--light)',
        borderStyle: 'solid',
        borderColor: 'var(--border)',
        borderWidth: 1,
        color: 'var(--black)',
        marginBottom: '1rem'
    },
    premium: {
        background: 'var(--primary)',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0.05rem',
        color: 'var(--white)',
        borderRadius: 'var(--radius-label)',
    },
    basic: {
        fontWeight: 'bold',
        background: 'var(--variant3)',
        padding: '0.05rem',
        color: 'var(--white)',
        borderRadius: 'var(--radius-label)',
    },
    content: {
        background: 'var(--white)',
        padding: '1rem'
    },
    between: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    technology: {
        margin: '0.25rem'
    }
}
export default ReservationTable