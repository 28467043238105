import React, { useState } from 'react';

// table styles
import 'react-smart-data-table/dist/react-smart-data-table.css'

// components
import SmartDataTable from 'react-smart-data-table';
import { Container, Pagination, Search, Grid, Header, Form } from 'semantic-ui-react';
import Loading from '../general/Loading';



const DataTable = (props) => {
    const [query, setQuery] = useState('');
    const [perPage, setPerPage] = useState(10);

    const perPageOptions = [
        { key: '10', value: '10', text: '10 per page' },
        { key: '25', value: '25', text: '25 per page' },
        { key: '50', value: '50', text: '50 per page' },
        { key: '100', value: '100', text: '100 per page' },
        { key: 'all', value: props.data.length.toString(), text: 'All records' },
    ]

    const onSearch = (e, data) => {
        setQuery(data.value);
    }

    const setDataPerPage = (e, value) => {
        setPerPage(parseInt(value));
    }

    const CustomPagination = ({ activePage, totalPages, onPageChange }) => (
        <Grid>
            <Grid.Column computer={8} table={8} mobile={16} style={{ padding: "0" }}>
                <Pagination defaultActivePage={activePage} totalPages={totalPages} onPageChange={onPageChange} />
            </Grid.Column>

            <Grid.Column computer={8} table={8} mobile={16} style={{ paddingRight: "1rem", textAlign: "right" }}>
                <Header as="h4">Total rows in the table: {props.data.length}</Header>
            </Grid.Column>
        </Grid>
    );


    return (
        <Container fluid>
            { props.data.length ?
                <Grid style={{ marginBottom: "1rem" }}>
                    <Grid.Column computer={8} table={8} mobile={16} style={{ padding: "0" }}>
                        <Form.Select
                            onChange={(e, data) => setDataPerPage(e, data.value)}
                            defaultValue={perPage.toString()}
                            options={perPageOptions} />
                    </Grid.Column>
                    <Grid.Column computer={4} table={4} mobile={16} style={{ padding: "0" }} />
                    <Grid.Column computer={4} table={4} mobile={16} style={{ padding: "0" }}>
                        <Search className="table-search" fluid placeholder="Enter search term..." onSearchChange={onSearch} value={query} open={false} />
                    </Grid.Column>
                </Grid>
                : ''}
            <SmartDataTable
                data={props.data}
                dataKey=''
                headers={props.headers}
                orderedHeaders={props.orderedHeaders}
                //hideUnordered={props.hideUnordered}
                name='employees-table'
                className="ui striped selectable table"
                filterValue={query}
                perPage={perPage}
                sortable
                //withToggles
                withLinks
                withHeader
                loader={<Loading />}
                parseBool={{
                    yesWord: 'Yes',
                    noWord: 'No',
                }}
                dynamic
                paginator={CustomPagination}
                emptyTable={(
                    <div className="ui message" style={{ textAlign: "center" }}>
                        There is no data available to display.
                    </div>
                )}
            />

        </Container>
    );
};

export default DataTable;