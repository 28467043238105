import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Divider, Grid, Icon } from 'semantic-ui-react'
import { routes } from '../../../../store/routes'
import { dateService } from '../../../../store/services/custom/DateServices'
import InfoCol from './InfoCol'
import RatingModal from './RatingModal'

const DetailData = ({ data, providers, id, handleRating, ratings }) => {
    const profile = useSelector(state => state.profile)
    const [providerToRate, setProviderToRate] = useState(null)
    const [openRate, setOpenRate] = useState(false)

    function getDateAndTime(input) {
        let result = input.split(' ')
        return `${dateService.convertDateWithDots(result[0])} / ${result[1]}`
    }
    function getTotalPrice() {
        let new_data = profile.user.role === 2 ? data.provider_selected_service_offers : data.selected_service_offers
        let result = new_data.reduce((accumulator, value) => {
            return accumulator + (parseFloat(value.price) * value.total_men)
        }, 0)

        return `${result} ${new_data[0].service_offer.currency}`
    }
    function getTotalPeople() {
        let new_data = profile.user.role === 2 ? data.provider_selected_service_offers : data.selected_service_offers
        let result = new_data.reduce((accumulator, value) => {
            return accumulator + parseFloat(value.total_men)
        }, 0)

        return result
    }
    function openRatingModal(input) {
        setProviderToRate(input)
        setOpenRate(true)
    }
    const GetOffers = () => {
        let new_data = profile.user.role === 2 ? data.provider_selected_service_offers : data.selected_service_offers
        //OBMEDENIE NA LEN JEDEN RATING
        return new_data.map((item, key) => {
            let provider_id = null
            let provider_name = null
            // let rated = false
            for (let i in providers) {
                for (let j in providers[i].selected_service_offers) {
                    if (item.id === providers[i].selected_service_offers[j].id) {
                        provider_id = providers[i].provider_id
                        provider_name = providers[i].provider_name
                    }
                }
            }

            return (
                <Grid.Row key={key} style={{ border: '1px solid var(--border)', marginTop: '0.5rem', background: 'var(--light)' }} >
                    <Grid.Column computer={4} tablet={4} mobile={8} style={{ fontWeight: "bold" }}>Technology: {item.service_offer.name}</Grid.Column>
                    <Grid.Column computer={4} tablet={4} mobile={8} style={{ fontWeight: "bold" }}>Total people: {item.total_men}</Grid.Column>
                    <Grid.Column computer={4} tablet={4} mobile={8} style={{ fontWeight: "bold" }}>Price: {item.price} {item.service_offer.currency}</Grid.Column>
                    {provider_id &&
                        <Grid.Column computer={4} tablet={4} mobile={8}>
                            <ProviderLink id={provider_id} name={provider_name} />
                            <div style={{ cursor: 'pointer', fontWeight: "bold", display: 'flex' }} onClick={() => openRatingModal({ id: provider_id, name: provider_name })}>
                                <Icon name='star' />
                                Rate
                            </div>
                        </Grid.Column>}
                </Grid.Row>
            )
        })
    }
    const ProviderLink = ({ id, name }) => {
        return (
            <div style={{ display: 'flex' }}>
                <Link to={routes.PROVIDER_PROFILE + id} style={{ fontWeight: "bold" }}><Icon name='user' />{name}</Link>
            </div>
        )
    }
    return (
        <Grid style={{
            padding: '1rem',
            borderBottomStyle: 'solid',
            borderLeftStyle: 'solid',
            borderRightStyle: 'solid',
            borderColor: 'var(--border)',
            borderWidth: 1
        }}>
            <RatingModal order={id} provider={providerToRate} open={openRate} onClose={() => setOpenRate(false)} handleRating={(e) => handleRating(e)} />
            <Divider style={{ width: "100%", margin: '0 1rem' }} horizontal>Detail</Divider>
            <Grid.Row style={{ flexDirection: 'column' }}>
                <InfoCol name='Created on:' value={getDateAndTime(data.created_when)} />
                <InfoCol name='Premium:' value={data.is_premium ? 'yes' : 'no'} />
            </Grid.Row>
            <Divider style={{ width: "100%", margin: '0 1rem' }} horizontal>Offers</Divider>
            <GetOffers />
            <Divider horizontal>Summary</Divider>
            <Grid.Row style={{ flexDirection: 'column' }}>
                <InfoCol name='Total people:' value={getTotalPeople()} />
                <InfoCol name='Total price:' value={getTotalPrice()} />
            </Grid.Row>
        </Grid>
    )
}


export default DetailData
