import React, { useState } from 'react'
import { Modal, Button } from 'semantic-ui-react'
import WholeDraftSO from './WholeDraftSO'
import ServiceSettings from './ServiceSettings'
import { serviceService } from '../../../../../store/services/services/ServicesService'
import { serviceOfferService } from '../../../../../store/services/services/ServiceOfferService'
const CreateService = ({ open, onClose, id, addService }) => {
    const [step, setStep] = useState(0)
    const [new_service, setNewService] = useState({
        name: '',
        category: 0,
        description: '',
    })
    const [service_offers, setServiceOffers] = useState([])
    const [usedTechnologies, setUsedTechnologies] = useState([])
    const [loading, setLoading] = useState(false)

    async function handleConfirm() {
        setLoading(true)
        let incoming_offers = []
        for (let i = 0; i < service_offers.length; i++) {
            let new_offer = {
                technology: service_offers[i].technology,
                total_persons: parseInt(service_offers[i].total_persons),
                time_before_order: parseInt(service_offers[i].time_before_order),
                minimum_contract_length: parseInt(service_offers[i].minimum_contract_length),
                price: parseFloat(service_offers[i].price).toFixed(2),
                currency: service_offers[i].currency,
                time_before_order_type: service_offers[i].time_before_order_type,
                minimum_order: parseInt(service_offers[i].minimum_order),
                minimum_contract_length_type: service_offers[i].minimum_contract_length_type,
                dedication: service_offers[i].dedication,
                project_manager: service_offers[i].project_manager,
                is_active: true//POSSIBLY WILL CHANGE
            }
            let offer = await serviceOfferService.createServiceOffer(new_offer)
            incoming_offers = incoming_offers.concat(offer.response.id)
        }
        let service_data = {
            provider: id,
            service_offers: { add: incoming_offers },
            name: new_service.name,
            description: new_service.description,
            category: new_service.category,
            is_outsourcing: new_service.is_outsourcing,
            is_active: true
        }
        let service = await serviceService.createService(service_data)
        addService(service.response)
        setNewService({ name: '', category: 0, description: '', })
        setServiceOffers([])
        setLoading(false)
        setStep(0)
        onClose()
    }
    function checkDisabledFirstStep() {
        if (new_service.name === '' || new_service.description === '' || new_service.category === 0) return true
        return false
    }
    function checkDisabledSecondStep() {
        if (service_offers.length === 0) return true
        return false
    }
    function handleServiceOffers(e) {
        setServiceOffers(e)
        setUsedTechnologies(e.map(item => item.technology))
    }
    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header style={{ textAlign: "center" }}>
                {step === 0 && 'Create Service'}
                {step === 1 && 'Create Offers'}
            </Modal.Header>
            <Modal.Content>
                {step === 0 && <ServiceSettings handler={(e) => setNewService(e)} data={new_service} />}
                {step === 1 && <WholeDraftSO handler={(e) => handleServiceOffers(e)} data={service_offers} exceptions={usedTechnologies} />}
            </Modal.Content>
            <Modal.Actions>
                {step === 0 && <>
                    <Button basic content="Cancel" onClick={onClose} />
                    <Button content="Create offer" onClick={() => setStep(1)} disabled={checkDisabledFirstStep()} /></>}
                {step === 1 && <>
                    <Button basic content="Back" onClick={() => setStep(0)} disabled={loading} />
                    <Button content="Confirm" onClick={() => handleConfirm()} disabled={checkDisabledSecondStep()} loading={loading} /></>}
            </Modal.Actions>
        </Modal>
    )
}

export default CreateService
