import { APIRequest } from '../helpers/requests';
import { API } from '../../config';


export const projectService = {
    getProjects,
    createProject,
    getProjectById,
    updateProject,
    deleteProject,
}

async function getProjects() {
    return await APIRequest({
        url: API.PROJECTS,
        method: 'GET',
        data: {},
        private: true
    });
}
async function createProject(data) {
    return await APIRequest({
        url: API.PROJECTS,
        method: 'POST',
        data: data,
        private: true
    });
}
async function getProjectById(id) {
    return await APIRequest({
        url: API.PROJECTS + id,
        method: 'GET',
        data: {},
        private: true
    });
}
async function updateProject(id, data) {
    return await APIRequest({
        url: API.PROJECTS + id,
        method: 'PATCH',
        data: data,
        private: true
    });
}
async function deleteProject(id) {
    return await APIRequest({
        url: API.PROJECTS + id,
        method: 'DELETE',
        data: {},
        private: true
    });
}