import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"

import { Button } from 'semantic-ui-react'
import DataTable from '../../../../components/tables/DataTable'
import ActionButton from '../../../../components/buttons/ActionButton'

import { dateService } from '../../../../store/services/custom/DateServices'
import { routes } from "../../../../store/routes/index";
const Providers = ({ data }) => {
    let history = useHistory()
    const [dataToTable, setDataToTable] = useState([])
    useEffect(() => {
        async function fetchData() {
            setDataToTable(
                data.map((item => (
                    {
                        id: item.id,
                        business_name: item.business_detail.name,
                        email: item.user.email,
                        name: item.user.name,
                        date_joined: item.user.date_joined,
                        tipper: item.tipper,
                        phone: item.phone
                    }
                )))
            )

        };

        fetchData();
    }, [data]);

    const orderedHeaders = [
        'id',
        'name',
        'business_name',
        'email',
        'date_joined',
        'tipper',
        'tableActions',
    ];
    const TableAction = ({ row }) => {
        return (
            <Button.Group>
                <ActionButton icon="eye" action={() => action(row, 'open')} />
                {/* <ActionButton icon="checkmark" action={() => action(row, 'open')} />
                <ActionButton icon="settings" action={() => action(row, 'open')} /> */}
            </Button.Group>
        );
    }
    const headers = {
        id: {
            text: 'ID',
            invisible: false,
            filterable: false,
            transform: (value) => `#${value}`,
        },
        business_name: {
            text: 'Business name',
            sortable: true,
            filterable: true,
        },
        email: {
            text: 'Email',
            sortable: true,
            filterable: true,
        },
        name: {
            text: 'Name',
            sortable: true,
            filterable: true,
        },
        date_joined: {
            text: 'Joined on',
            sortable: true,
            filterable: true,
            transform: (value, index, row) => {
                return dateService.convertDateWithDots(row.date_joined)
            },
        },
        tipper: {
            text: 'Tipper',
            sortable: true,
            filterable: true,
            transform: (value) => <>{value ? value : 'none'}</>
        },
        tableActions: {
            text: 'Actions',
            sortable: false,
            filterable: false,
            transform: (value, index, row) => {
                return <TableAction row={row} index={index} />
            },
        },
        phone: { invisible: true },


    }
    function action(row, action) {
        history.push(routes.PROVIDER_PROFILE + row.id)
    }


    return (
        <Fragment>
            <DataTable
                data={dataToTable}
                orderedHeaders={orderedHeaders}
                headers={headers}
            />
        </Fragment>
    )
}
export default Providers