import React, { useState, useEffect } from 'react'
import { Grid, Form, Divider, Button } from 'semantic-ui-react'
import { isEmpty, isPhoneError } from '../../../../store/services/helpers/validation'
const genderOptions = [
    { key: 1, value: 1, text: 'Male' },
    { key: 2, value: 2, text: 'Female' },
]

const PersonForm = ({ handleData, nextStep, data, client }) => {
    const [person, setPerson] = useState({
        firstname: data.firstname ?? '',
        lastname: data.lastname ?? '',
        middlename: data.middlename ?? '',
        titles_before: data.titles_before ?? '',
        titles_after: data.titles_after ?? '',
        gender: data.gender ?? 0,
        phone: ''
    })

    useEffect(() => {
        handleData(person)
    }, [handleData, person])

    function handlePerson(e, { name, value }) {
        setPerson((prevState) => ({ ...prevState, [name]: value }))
    }
    function isRequiredFilled() {
        if (person.firstname !== '' && person.lastname !== '' && person.gender !== 0) return true
        return false
    }
    return (
        <>
            <Divider horizontal>Personal information</Divider>
            <Grid.Row>
                {client &&
                    <Grid.Column computer={4} tablet={4} mobile={16}>
                        <span>Phone</span>
                        <Form.Input
                            fluid
                            placeholder="+123 456 789 456"
                            value={person.phone}
                            name="phone"
                            error={!isEmpty(person.phone) && isPhoneError(person.phone)}
                            onChange={handlePerson} />
                    </Grid.Column >
                }
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    <span>First name</span><span style={{ color: 'var(--danger)' }}>*</span>
                    <Form.Input
                        fluid
                        placeholder="Enter your first name"
                        value={person.firstname}
                        name="firstname"
                        onChange={handlePerson} />
                </Grid.Column >
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    <span>Middle name</span>
                    <Form.Input
                        fluid
                        placeholder="Enter your middle name"
                        value={person.middlename}
                        name="middlename"
                        onChange={handlePerson} />
                </Grid.Column >
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    <span>Last name</span><span style={{ color: 'var(--danger)' }}>*</span>
                    <Form.Input
                        fluid
                        placeholder="Enter your last name"
                        value={person.lastname}
                        name="lastname"
                        onChange={handlePerson} />
                </Grid.Column >
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    <span>Gender</span><span style={{ color: 'var(--danger)' }}>*</span>
                    <Form.Select
                        style={{ borderRadius: 0 }}
                        fluid
                        placeholder="Choose gender"
                        value={person.gender}
                        name="gender"
                        options={genderOptions}
                        onChange={handlePerson} />
                </Grid.Column >
                <Grid.Column computer={4} tablet={8} mobile={16}>
                    <span>Titles before name</span>
                    <Form.Input
                        fluid
                        placeholder="Ing,Mgr,..."
                        value={person.titles_before}
                        name="titles_before"
                        onChange={handlePerson} />
                </Grid.Column >
                <Grid.Column computer={4} tablet={8} mobile={16}>
                    <span>Titles after name</span>
                    <Form.Input
                        fluid
                        placeholder="Phd,CSc,..."
                        value={person.titles_after}
                        name="titles_after"
                        onChange={handlePerson} />
                </Grid.Column >


                <Grid.Column width={16} style={{ marginTop: '1rem' }}>
                    <Button onClick={nextStep} disabled={!isRequiredFilled()}>Next</Button>
                </Grid.Column>
            </Grid.Row>
        </>
    )
}

export default PersonForm
