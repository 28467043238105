import React, { Fragment, useState } from 'react';
import { Link } from "react-router-dom";

// routes
import { routes } from "../../../store/routes/index";
import { useSelector } from "react-redux";

import { Menu, Image, Dropdown, Label } from "semantic-ui-react";

import HorizontalSidebar from './HorizontalSidebar';

function MainNavigation({ logout, user, changeViewType }) {
    const [hSidebar, setHSidebar] = useState(false);
    const profile = useSelector(state => state.profile)
    function getName() {
        if (profile?.business_detail) return profile?.business_detail?.name
        if (profile?.person) return `${profile?.person?.firstname} ${profile?.person?.lastname[0]}.`
        return "HIT"
    }
    function getInitialsOfName() {
        if (profile?.business_detail) return profile?.business_detail?.name[0]
        if (profile?.person) return `${profile?.person?.firstname[0]}${profile?.person?.lastname[0]}`
        return "H"
    }


    const MainDropdown = () => {
        return (
            <Dropdown
                className="quick-dropdown-options"
                inline
                text={getName()}>
                <Dropdown.Menu direction="left" style={{ marginTop: "1rem", borderRadius: "0" }}>
                    <Dropdown.Item className="nav-link" as={Link} to={routes.PROFILE} icon="user circle" text="User Profile" style={styles.item} />
                    {/* <Dropdown.Item onClick={changeViewType} as="button" icon="exchange" text="Switch View" /> */}
                    <Dropdown.Item className="nav-link" as={Link} to={routes.SETTINGS} icon="cog" text="Settings" style={styles.item} />
                    <Dropdown.Item as="button" icon="log out" text="Sign out" onClick={logout} style={styles.item} />
                </Dropdown.Menu>
            </Dropdown>
        )
    }
    return (
        <Fragment>
            <div className="bigMenu">
                <HorizontalSidebar visible={hSidebar} close={() => setHSidebar(false)} />
                <Menu fixed='top' borderless style={{ marginBottom: 0 }}>
                    <Menu.Menu position='left' style={{ width: "90%" }}>
                        <Menu.Item>
                            <Link to={routes.HOME}>
                                <img src="/images/hiringINIT_logo.svg" alt="logo" width="25%" />
                            </Link>
                        </Menu.Item>
                    </Menu.Menu>
                    <Menu.Menu position='right' style={{ right: 0 }}>
                        <Menu.Item>
                            <Label circular style={{ marginRight: ".5rem", backgroundColor: "var(--light)", color: "var(--dark)" }}>
                                {getInitialsOfName()}
                            </Label>
                            <MainDropdown />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </div>

            <div className="smallMenu">
                <HorizontalSidebar visible={hSidebar} close={() => setHSidebar(false)} />
                <Menu fixed='top' borderless style={{ marginBottom: 0 }}>
                    <Menu.Menu position="left" style={{ width: "80%" }}><Menu.Item><Link to={routes.HOME}><Image size="mini" src="/images/hiringINIT_logo.svg" /></Link></Menu.Item></Menu.Menu>
                    <Menu.Menu position='right' style={{ right: 0 }}><Menu.Item><MainDropdown /></Menu.Item></Menu.Menu>
                </Menu>
            </div>
        </Fragment >
    );
}
const styles = {
    item: {
        width: '20rem'
    }
}
export default MainNavigation;