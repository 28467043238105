import { Modal, Button } from 'semantic-ui-react'
import React, { useState } from 'react'
const DESCRIPTION_LENGTH = 80 //default shortening of description

export const textService = {
    shortDescription
}
function ShowMoreModal({ input, header }) {
    const [open, setOpen] = useState(false)
    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size="small"
            trigger={<span className="show-more-text">Show more</span>}>
            <Modal.Header style={{ textAlign: "center" }}>{header}</Modal.Header>
            <Modal.Content style={{ display: 'flex', overflowWrap: 'anywhere' }}>{input}</Modal.Content>
            <Modal.Actions>
                <Button
                    content="Close"
                    onClick={() => setOpen(false)}
                />
            </Modal.Actions>
        </Modal>
    )
}

function shortDescription(input) {
    if (input) {
        if (input.length > DESCRIPTION_LENGTH) {
            return (
                <div style={{ overflowWrap: 'anywhere' }}>
                    {input.slice(0, DESCRIPTION_LENGTH) + "..."}<ShowMoreModal input={input} header={'Description'} />
                </div>
            )
        }
        return input
    }
    return ''
}