import React, { useState } from 'react'
import { Grid, Icon } from 'semantic-ui-react'
import AddingDraftFormSo from './AddingDraftFormSo'
import DraftListSO from './DraftListSO'

const AddingServiceOffers = ({ handler, data, exceptions }) => {

    const [openAddingForm, setOpenAddingForm] = useState(false)
    const [offers, setOffers] = useState(data)

    function onAdd(input) {
        let array = offers.concat([input])
        setOffers(array)
        handler(array)
        setOpenAddingForm(false)
    }
    function onRemove(input) {
        setOffers(input)
        handler(input)
    }
    return (
        <Grid style={{ width: "100%" }}>
            {!openAddingForm &&
                <Grid.Row >
                    <div style={{
                        display: 'flex',
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: 'column',
                        background: 'var(--light)',
                        border: '1px solid var(--border)',
                        width: '100%',
                        padding: '1rem',
                        cursor: 'pointer'
                    }}
                        onClick={() => setOpenAddingForm(true)} >
                        <Icon name='plus' size='huge' />
                        <span>Add service offer</span>
                    </div>
                </Grid.Row>}
            <Grid.Row >
                {openAddingForm && <AddingDraftFormSo cancel={() => setOpenAddingForm(false)} onAdd={(output) => onAdd(output)} exceptions={exceptions} />}
                {!openAddingForm && <DraftListSO data={offers} saveOffers={(e) => onRemove(e)} />}
            </Grid.Row>
        </Grid>
    )
}

export default AddingServiceOffers
