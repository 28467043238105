import React, { useEffect, useState } from 'react'
import InputRange from 'react-input-range'
import 'react-input-range/lib/css/index.css'
import { Input } from 'semantic-ui-react'
import RadioButton from './RadioButton'

const RangeInput = ({ handler, input_value, min, max, handleCheckbox, boxes }) => {
    const [maxValue, setMaxValue] = useState(max)
    const [minValue, setMinValue] = useState(min)
    const [value, setValue] = useState(
        boxes ?
            { min: Math.ceil(input_value.min / 60 / 24), max: Math.ceil(input_value.max / 60 / 24) }
            :
            { min: Math.ceil(input_value.min), max: Math.ceil(input_value.max) }
    )

    useEffect(() => {
        if (boxes) {
            let result = max / 60 / 24
            if (boxes.check === 2) result = result / 7
            if (boxes.check === 3) result = result / 30
            if (boxes.check === 4) result = result / 365
            setMaxValue(Math.ceil(result))
            setValue({ min: 0, max: Math.ceil(result) })
        } else {
            setMaxValue(max)
            setValue({ min: 0, max: max })
        }
        setMinValue(min)
    }, [min, max, boxes])


    function handleChange(input) {
        if (input.min < minValue) return
        if (input.max > maxValue) return
        setValue({ min: input.min, max: input.max })
        if (boxes) {
            if (boxes.check === 1) handler({ min: input.min * 60 * 24, max: input.max * 60 * 24 })
            if (boxes.check === 2) handler({ min: input.min * 60 * 24 * 7, max: input.max * 60 * 24 * 7 })
            if (boxes.check === 3) handler({ min: input.min * 60 * 24 * 30, max: input.max * 60 * 24 * 30 })
            if (boxes.check === 4) handler({ min: input.min * 60 * 24 * 365, max: input.max * 60 * 24 * 365 })
        } else {
            handler(input)
        }
    }
    function handleInputMax(input) {
        if (parseInt(input) > maxValue) return
        setValue((prev => ({ ...prev, max: parseInt(input) })))
        handler({ max: parseInt(input), min: value.min })
    }
    function handleInputMin(input) {
        if (parseInt(input) < minValue) return
        setValue((prev => ({ ...prev, min: parseInt(input) })))
        handler({ max: value.max, min: parseInt(input) })
    }

    const ShowCheckBoxes = () => {
        return boxes.boxes.map((item, key) => {
            return <RadioButton key={key} name={item.name} handler={() => handleCheckbox(item.type)} type={item.type} checked={boxes.check} />
        })
    }

    return (
        <>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                <Input type='number' style={{ width: "40%" }} value={value.min} onChange={(e) => handleInputMin(e.target.value)} />
                <span>-</span>
                <Input type='number' style={{ width: "40%" }} value={value.max} onChange={(e) => handleInputMax(e.target.value)} />
            </div>

            <div style={{ display: 'flex', width: '100%', marginTop: '1rem' }}>
                <InputRange
                    minValue={minValue}
                    maxValue={maxValue}
                    value={value}
                    onChange={e => handleChange(e)} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                <span style={{ textAlign: 'left' }}>{minValue}</span>
                <span style={{ textAlign: 'right' }}>{maxValue}</span>
            </div>
            {boxes &&
                <div style={{ display: 'flex', justifyContent: "space-around" }}>
                    <ShowCheckBoxes />
                </div>
            }
        </>
    )
}

export default RangeInput
