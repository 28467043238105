// validation input rules
export const isEmpty = (input) => {
    return input === "";
};

export const isNotEmpty = (input) => {
    return input !== "";
};

export const isEmptyErrorList = (input) => { // isEmptyErrorList([input1, input2, input3])
    return input.every(isNotEmpty);
};

export const isEmailError = (input) => {
    if (
        !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            input
        )
    ) {
        return {
            content: "The email address you entered is in the wrong format.",
        };
    }
    return false;
};

// password rules: 1 big letter, 1 number, 8 characters minimum
export const isPasswordError = (input) => {
    if (!/^(?=.*\d)(?=.*[A-Z]).{8,}$/.test(input)) {
        return {
            content: "The password you entered is not strong enough.",
        };
    }
    return false;
};

export const isRepeatPasswordError = (password, repeatPassword) => {
    if (password !== repeatPassword) {
        return {
            content: "The passwords you entered do not match.",
        };
    }
    return false;
};

// 09** *** *** || +421 *** *** ***
export const isPhoneError = (input) => {
    if (!/^([+][0-9]{3}|[0]) ?[0-9]{3} ?[0-9]{3} ?[0-9]{3}$/.test(input)) {
        return {
            content: "The number you entered is in the wrong format.",
        };
    }
    return false;
};
// export const isPhoneError = (input) => {
//     if (!/^[+][0-9]{2,3}[0-9+]{10,15}$/.test(input)) {
//         return {
//             content: "The number you entered is in the wrong format.",
//         };
//     }
//     return false;
// };

export const isNumberErrorWhole = (input) => {
    if (!/^([1-9][0-9]*)$/.test(input)) return "Integer value must be whole number."
    return false;
};
export const isNumberError = (input) => {
    if (!/^([+]?([0-9]*[.])?[0-9]+)$/.test(input)) return "Integer value must be greater than 0."
    return false;
};

export function lowerThanZero(input) {
    if (input < 0) return "Input can't be lower than zero"
    return false
}

export function errorPercentage(input) {
    if (!/^([+]?([0-9]*[.])?[0-9]+)$/.test(input)) return "Integer value must be greater than 0."
    if (parseFloat(input) <= 0 || parseFloat(input) > 100) return "Not valid percents, value needs to be in range of 1-100."
    return false
}

export const isDateError = (input) => {
    if (
        !/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
            input
        )
    ) {
        return {
            content: "The date you entered is in the wrong format.",
        };
    }
    return false;
};
// export const isDateError = (input) => {
//     if (
//         !/(^(19|[2-9][0-9])\d\d[-](((0[1-9]|1[012])[-](0[1-9]|1[0-9]|2[0-8]))|((0[13578]|1[02])[-](29|30|31))|((0[469]|11)[-](29|30)))$)|(^(19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)[-]02[-]29$)/.test(
//             input
//         )
//     ) {
//         return {
//             content: "The date you entered is in the wrong format.",
//         };
//     }
//     return false;
// };

export const isTimeError = (input) => {
    if (!/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(input)) {
        return {
            content: "The time entered is in the wrong format.",
        };
    }
    return false;
};

export const isURLError = (input) => {
    if (
        !/(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/.test(
            input
        )
    ) {
        return {
            content: "The link you entered is in the wrong format.",
        };
    }
    return false;
};
