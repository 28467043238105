import React from 'react'
import { Header, Grid } from 'semantic-ui-react'
const InfoColSO = ({ name, value, columns }) => {
    let result
    if (columns) result = Math.floor(16 / columns)
    return (
        <Grid.Column mobile={columns ? result : 8} tablet={columns ? result : 4} computer={columns ? result : 4}>
            <Header as='h3' style={{ margin: '0 1rem' }}>
                {value}
                <Header.Subheader>{name}</Header.Subheader>
            </Header>
        </Grid.Column>
    )
}

export default InfoColSO
