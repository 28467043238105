import React, { useState } from 'react'
import { Grid, Header } from 'semantic-ui-react'

import { Link } from 'react-router-dom';
import { routes } from '../../../../store/routes/index'

import { textService } from '../../../../store/services/custom/TextService'
import { dateService } from '../../../../store/services/custom/DateServices'
import { projectService } from '../../../../store/services/projects/ProjectService'

import EditDeleteIcons from '../../../../components/general/EditDeleteIcons'
import ResponseModal from '../../../../components/modals/ResponseModal'
import ProjectEditModal from '../ProjectEditModal'
import ProjectDeleteModal from '../ProjectDeleteModal'
import Technology from '../../../../components/general/Technology'

const ProjectCard = ({ data, onDelete, onUpdate }) => {
    const [open, setOpen] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [error, setError] = useState('')
    const [errorModal, setErrorModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showLessTech, setShowLessTech] = useState(true)


    async function onDeleteProject(input) {
        setLoading(true)
        await projectService.deleteProject(input)
        onDelete(input)
        setOpenDelete(false)
        setLoading(false)
    }
    async function onSave(input) {
        setLoading(true)
        let update = {
            name: input.name,
            ends: data.ends === input.ends ? input.ends : dateService.rotateDate(input.ends),
            starts: data.starts === input.starts ? input.starts : dateService.rotateDate(input.starts),
            project_manager: input.project_manager,
            budget: input.budget,
            currency: input.currency,
            description: input.description,
            man_days: input.man_days,
            technologies: {
                remove: data.technologies.map(item => item.id),
                add: input.technologies.map(item => item.id)
            },
        }
        let result = await projectService.updateProject(input.id, update)
        if (result.status !== 200) {
            setError(result.response.non_field_errors)
            setErrorModal(true)
            setLoading(false)
            return
        }
        onUpdate(result.response)
        setOpen(false)
        setLoading(false)
    }
    const ListOfTechnologies = () => {
        return data.technologies.map((item, key) => (
            <div key={key}>
                <Technology data={item.name} />
            </div>
        ))
    }
    const ThreeTechnologies = () => {
        return data.technologies.map((item, key) => {
            if (key < 3) return (
                <div key={key + 9999}>
                    <Technology data={item.name} />
                </div>)
            return (<div key={key + "abedces"}></div>)
        }
        )
    }
    return (
        <Grid style={styles.container} className="shadow-card">
            <ResponseModal
                onClick={() => setErrorModal(false)}
                header='Error'
                open={errorModal}
                size='mini'
                message={<span>{error}</span>}
                onClose={() => setErrorModal(false)}
            />
            <ProjectEditModal
                open={open}
                cancel={() => setOpen(false)}
                onSave={(e) => onSave(e)}
                data={data}
                loading={loading} />
            <ProjectDeleteModal
                open={openDelete}
                cancel={() => setOpenDelete(false)}
                onDelete={() => onDeleteProject(data.id)}
                loading={loading} />
            <Grid.Row style={styles.header}>
                <Link to={routes.PROJECTS_DETAIL + data.id}><Header style={{ color: 'var(--white)' }}>{data.name}</Header></Link>
                <EditDeleteIcons onEdit={() => setOpen(true)} onDelete={() => setOpenDelete(true)} />
            </Grid.Row>

            <Grid>
                <Grid.Row >
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <div style={styles.date}>
                            Date of starting project:<br />
                            <b>{dateService.convertDateWithDots(data.starts)}</b>
                        </div>
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <div style={styles.date}>
                            Planned end of project:<br />
                            <b>{dateService.convertDateWithDots(data.ends)}</b>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <div style={styles.date}>
                            Budget<br />
                            <b>{data.budget_display} {data.currency}</b>
                        </div>
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16} style={{ display: !data.project_manager && 'none' }}>
                        <div style={styles.yes}>
                            Project manager
                        </div>
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16} style={{ display: data.project_manager ? 'none' : 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {data.technologies.length <= 3 ? <ListOfTechnologies /> :
                            <>
                                {(data.technologies.length > 3 && showLessTech) ?
                                    <div>
                                        <ThreeTechnologies />
                                        <div style={styles.showMoreLess} onClick={() => setShowLessTech(false)}>Show More</div>
                                    </div>
                                    :
                                    <div>
                                        <ListOfTechnologies />
                                        <div style={styles.showMoreLess} onClick={() => setShowLessTech(true)}>Hide</div>
                                    </div>
                                }

                            </>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Grid.Row style={styles.footer}>
                {textService.shortDescription(data.description)}
            </Grid.Row>
        </Grid>
    )
}
const styles = {
    container: {
        borderStyle: 'solid',
        borderWidth: 1.5,
        borderColor: 'var(--border)'
    },
    header: {
        backgroundColor: "var(--primary)",
        padding: "1rem",
        display: "flex",
        justifyContent: 'space-between',
        color: 'var(--white)'
    },
    footer: {
        backgroundColor: "var(--light-grey)",
        padding: "1rem"
    },
    date: {
        textAlign: "center",
    },
    yes: {
        padding: "0.1rem 0.5rem",
        color: "var(--white)",
        backgroundColor: "var(--info)",
        fontWeight: "bold",
        textAlign: 'center'
    },
    no: {
        padding: "0.1rem 0.5rem",
        color: "var(--white)",
        backgroundColor: "var(--black)",
        fontWeight: "bold"
    },
    showMoreLess: {
        cursor: 'pointer',
        textAlign: 'center',
        fontWeight: 'bold',
        color: 'var(--variant3)'
    }
}
export default ProjectCard
