import React, { useState } from 'react'
import { Grid, Form, Modal, Button, Checkbox } from 'semantic-ui-react'
import CategoryInput from '../../../../components/inputs/CategoryInput'
const EditService = ({ handler, data, open, cancel, loading }) => {


    const [edit, setEdit] = useState({
        name: data.name ?? '',
        category: data.category ? data.category : 0,
        description: data.description ?? '',
        is_outsourcing: data.is_outsourcing ?? false,
    })

    function handleEditService(e, { name, value }) { setEdit(prevState => ({ ...prevState, [name]: value })) }
    const handleChangeCheckbox = () => setEdit(prevState => ({ ...prevState, is_outsourcing: !edit.is_outsourcing }))

    return (
        <Modal
            open={open}
            size='tiny'
        >
            <Modal.Header style={{ textAlign: "center", color: 'var(--white)' }}>Service update</Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <span>Name</span>
                        <Form.Input
                            fluid
                            name='name'
                            value={edit.name}
                            onChange={handleEditService}
                        />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <CategoryInput handler={(e) => setEdit(prev => ({ ...prev, category: e }))} data={edit.category} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <Form.Field style={{ display: 'flex', alignItems: "center", marginTop: '1.5rem' }}>
                            <Checkbox
                                style={{ marginRight: "0.5rem" }}
                                onChange={handleChangeCheckbox}
                                checked={edit.is_outsourcing} /><span>Is outsourcing</span>
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <span>Description</span>
                        <Form.TextArea
                            style={{ width: '100%', borderColor: "var(--border)", }}
                            rows={4}
                            name="description"
                            value={edit.description}
                            onChange={handleEditService} />
                    </Grid.Column>

                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Close" onClick={cancel} basic disabled={loading} />
                <Button content="Save" onClick={() => handler(edit)} loading={loading} />
            </Modal.Actions>
        </Modal>
    )
}

export default EditService
