import React from 'react'
import { Grid, Icon, Divider, Header } from 'semantic-ui-react'


const RegistrationTypeCard = ({ name, description, icon }) => {
    return (
        <Grid.Column style={styles.container} className="shadow-card">
            <Icon name={icon} size="huge" />
            <Header style={{ color: "var(--primary)" }}>{name}</Header>
            <Divider />
            <p>{description}</p>
        </Grid.Column>
    )
}
const styles = {
    container: {
        padding: "1rem",
        width: "20rem",
        height: "15rem",
        borderColor: "var(--border)",
        borderStyle: "solid",
        borderWidth: 1,
        textAlign: "center",
        cursor: 'pointer'
    }
}
export default RegistrationTypeCard
