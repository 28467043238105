import React, { Fragment } from "react";


// components
import Navbar from "./Navbar";
import Banner from "./Banner";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import Footer from "./Footer";


function Home() {

    return (
        <Fragment>
            <Navbar />
            <Banner />
            <SectionOne />
            <SectionTwo />
            <SectionThree />
            <SectionFour />
            <Footer />
        </Fragment>
    );
}

export default Home;