import React, { useState, Fragment } from "react";
import { Switch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "../../store/services/ServiceAuth";

// routes
import { routes } from "../../store/routes/index";

// design:
import "../../styles/dashboard.css";

// General components
import Layout from "./_views/Layout";
import Sidebar from "./_views/Sidebar";
import MainNavigation from "./_views/MainNavigation";
import DashboardPages from "./DashboardPages";
import PrivateRoute from "../../components/routes/PrivateRoute";

function Dashboard() {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.user);

    // component state
    const [viewType, setViewType] = useState(2); // 1 - as IconsView, 2 - as SidebarWidgetsView

    const changeViewType = () => {
        if (viewType === 1) {
            setViewType(2);
        } else {
            setViewType(1);
        }
    }

    const logoutUser = () => {
        authService.logout(dispatch);
        history.push(routes.LOGIN);
    };

    return (
        <div>

            <MainNavigation
                logout={logoutUser}
                user={user}
                viewType={viewType}
                changeViewType={changeViewType}
            />

            <div className="wrapper">
                <Switch>
                    {window.location.href === window.location.origin + routes.DASHBOARD ?
                        <PrivateRoute exact path={routes.DASHBOARD} component={
                            () => <Layout viewType={viewType} />
                        } />
                        :
                        <Fragment>
                            <Sidebar />
                            <div className="main">
                                <DashboardPages />
                            </div>
                        </Fragment>
                    }
                </Switch>
            </div>
        </div>
    );
}

export default Dashboard;
