import React, { useEffect, useState } from 'react'
import Technology from '../../../../components/general/Technology'
import { technologyService } from '../../../../store/services/common/TechnologyService'
const ServiceOfferTechnologies = ({ data }) => {

    const [technologies, setTechnologies] = useState([])

    useEffect(() => {
        async function fetchData() {
            let result = await technologyService.getTechnologies()
            if (result) {
                setTechnologies(result.response)
            }
        }
        fetchData()
    }, [])
    function selectTechnology(id) {
        let result
        technologies.map(item => {
            if (item.id === id) result = item.name
            return result
        })
        return result
    }

    if (data === 0) return
    return <Technology data={selectTechnology(data)} />

}

export default ServiceOfferTechnologies
