import React, { useState } from 'react'
import { Grid, Icon } from 'semantic-ui-react'

import ProjectCard from './ProjectCard'
import ProjectCreateModal from '../ProjectCreateModal'
import Loading from '../../../../components/general/Loading'
import ResponseModal from '../../../../components/modals/ResponseModal'

import { projectService } from '../../../../store/services/projects/ProjectService'
import { dateService } from '../../../../store/services/custom/DateServices'

const ProjectList = ({ data, id, addProject, deleteProject, updateProject }) => {
    const [open, setOpen] = useState(false)
    const [error, setError] = useState('')
    const [errorModal, setErrorModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const mapProjects = () => {
        if (data.length <= 0) return
        let filtered_array = data.filter((item) => item.client.id === id)

        return filtered_array.map((item, key) => (
            <Grid.Column key={key} computer={5} tablet={8} mobile={16}>
                <ProjectCard data={item} onDelete={deleteProject} onUpdate={updateProject} />
            </Grid.Column>
        ))
    }
    function getTechnologiesIds(input) {
        let result = input.map(item => (item.id))
        return result
    }
    async function onCreate(input) {
        setLoading(true)
        let newProject = {
            client: id,
            technologies: { add: getTechnologiesIds(input.technologies) },
            name: input.name,
            description: input.description,
            man_days: input.man_days,
            ends: dateService.rotateDate(input.ends),
            starts: dateService.rotateDate(input.starts),
            budget: input.budget,
            currency: input.currency,
            project_manager: input.project_manager,
        }
        let result = await projectService.createProject(newProject)
        if (result.status !== 201) {
            setError(result.response.non_field_errors)
            setErrorModal(true)
            setLoading(false)
            return
        }
        addProject(result.response)
        setOpen(false)
        setLoading(false)
    }
    return (
        <Grid >
            {id === 0 ?
                <Loading /> :
                <>
                    <ProjectCreateModal
                        open={open}
                        cancel={() => setOpen(false)}
                        onCreate={(data, check, currency) => onCreate(data, check, currency)}
                        id={id}
                        loading={loading}
                    />
                    <ResponseModal
                        onClick={() => setErrorModal(false)}
                        header='Error'
                        open={errorModal}
                        size='mini'
                        message={<span>{error}</span>}
                        onClose={() => setErrorModal(false)}
                    />
                    <Grid.Column computer={5} tablet={8} mobile={16} onClick={() => setOpen(true)}>
                        <div style={styles.add_card} className="shadow-card">
                            <Icon name='plus' size='huge' style={{ margin: '1rem' }} />
                            <span style={styles.text}>Create project</span>
                        </div>
                    </Grid.Column>
                    {mapProjects()}
                </>
            }
        </Grid>
    )
}
const styles = {
    add_card: {
        backgroundColor: "var(--light-grey)",
        color: "var(--primary)",
        padding: 0,
        borderColor: "var(--border)",
        borderWidth: 1,
        borderStyle: "solid",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        cursor: "pointer",
        height: "100%"
    },
    text: {
        fontWeight: 'bold',
        fontSize: "2rem",
        margin: "2rem"
    }
}
export default ProjectList
