import React, { useState, useEffect } from 'react'
import { Grid, Form, Checkbox } from 'semantic-ui-react'
import CategoryInput from '../../../../../components/inputs/CategoryInput'
const ServiceSettings = ({ handler, data }) => {


    const [new_service, setNewService] = useState({
        name: data.name ?? '',
        category: data.category ? data.category : 0,
        description: data.description ?? '',
        is_outsourcing: data.is_outsourcing ?? false,
    })

    function handleServiceSettings(e, { name, value }) {
        setNewService(prevState => ({ ...prevState, [name]: value }))
    }
    const handleChangeCheckbox = () => setNewService(prevState => ({ ...prevState, is_outsourcing: !new_service.is_outsourcing }))

    useEffect(() => {
        handler(new_service)
    }, [handler, new_service])
    return (
        <Grid>
            <Grid.Column computer={8} tablet={8} mobile={16}>
                <span>Name</span>
                <Form.Input
                    fluid
                    name='name'
                    value={new_service.name}
                    onChange={handleServiceSettings}
                />
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={16}>
                <CategoryInput handler={(e) => setNewService(prev => ({ ...prev, category: e }))} data={new_service.category} />
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={16}>
                <Form.Field style={{ display: 'flex', alignItems: "center", marginTop: '1.5rem' }}>
                    <Checkbox
                        style={{ marginRight: "0.5rem" }}
                        onChange={handleChangeCheckbox}
                        checked={new_service.is_outsourcing} /><span>Is outsourcing</span>
                </Form.Field>
            </Grid.Column>
            <Grid.Column width={16}>
                <span>Description</span>
                <Form.TextArea
                    style={{ width: '100%', borderColor: "var(--border)", }}
                    rows={4}
                    name="description"
                    value={new_service.description}
                    onChange={handleServiceSettings} />
            </Grid.Column>
        </Grid>
    )
}

export default ServiceSettings
