import React, { useState, useEffect } from 'react'
import { Grid, Form, Tab, Menu, Label } from 'semantic-ui-react'
import ServiceRow from './ServiceRow'
const ServiceList = ({ data, activate, deactivate }) => {
    const [list, setList] = useState(data)
    const [search, setSearch] = useState('')

    useEffect(() => {
        setList(data)
    }, [data])

    function filterActiveServices() {
        return list.filter(item => item.is_active === true)
    }
    function filterNonactiveServices() {
        return list.filter(item => item.is_active !== true)
    }
    let panes = [
        {
            menuItem: (
                <Menu.Item key='Active'>
                    Active
                    <Label circular>{filterActiveServices().length}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane><ServiceRow activate={activate} deactivate={deactivate} list={filterActiveServices()} search={search} type={'name'} /></Tab.Pane>,
        },
        {
            menuItem: (
                <Menu.Item key='Non-active'>
                    Non-active
                    <Label circular>{filterNonactiveServices().length}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane><ServiceRow activate={activate} deactivate={deactivate} list={filterNonactiveServices()} search={search} type={'name'} /></Tab.Pane>,
        },
    ]

    return (
        <Grid style={styles.container}>
            <Grid.Row style={styles.search_row}>
                <Form.Input
                    placeholder="Search service by name"
                    value={search}
                    icon='search'
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ width: "50%", margin: '1rem' }}
                />
            </Grid.Row>
            <Tab panes={panes} style={{ width: '100%', padding: 0, margin: 0 }} menu={{ secondary: true, pointing: true }} />
        </Grid>
    )
}
const styles = {
    search_row: {
        flexDirection: 'column',
    },
    container: {
        width: '100%'
    }
}
export default ServiceList

