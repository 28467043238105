import { APIRequest } from '../helpers/requests';
import { API } from '../../config';


export const businessService = {
    createBusinessDetail,
    updateBusinessDetail,
    createAddress,
    createNaceCode,
    createBankAccount,
    updateAddress,
    updateNaceCode,
    updateBankAccount,
    getNaceByID,
    getNaceCode
}
async function createBusinessDetail(input) {
    return await APIRequest({
        url: API.GET_BUSINESS,
        method: 'POST',
        data: input,
        private: true
    });
}
async function updateBusinessDetail(id, data) {
    return await APIRequest({
        url: API.GET_BUSINESS + id,
        method: 'PATCH',
        data: data,
        private: true
    });
}
async function createAddress(input) {
    return await APIRequest({
        url: API.ADDRESS,
        method: 'POST',
        data: input,
        private: true
    });
}
async function createNaceCode(data) {
    return await APIRequest({
        url: API.NACE_CODE,
        method: 'POST',
        data: data,
        private: true
    });
}
async function createBankAccount(data) {
    return await APIRequest({
        url: API.BANK_ACCOUNT,
        method: 'POST',
        data: data,
        private: true
    });
}
async function updateAddress(id, input) {
    return await APIRequest({
        url: API.ADDRESS + id,
        method: 'PATCH',
        data: input,
        private: true
    });
}
async function updateNaceCode(id, data) {
    return await APIRequest({
        url: API.NACE_CODE + id,
        method: 'PATCH',
        data: data,
        private: true
    });
}
async function getNaceByID(id) {
    return await APIRequest({
        url: API.NACE_CODE + id,
        method: 'GET',
        data: {},
        private: true
    });
}
async function getNaceCode() {
    return await APIRequest({
        url: API.NACE_CODE,
        method: 'GET',
        data: {},
        private: true
    });
}
async function updateBankAccount(id, data) {
    return await APIRequest({
        url: API.BANK_ACCOUNT + id,
        method: 'PATCH',
        data: data,
        private: true
    });
}
