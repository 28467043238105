import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Icon, Label, Table } from 'semantic-ui-react'
import ActionButton from '../../../../components/buttons/ActionButton'
import ResponseModal from '../../../../components/modals/ResponseModal'
import { reservationService } from '../../../../store/services/reservations/ReservationServices'
import ReservationActions from './ReservationActions'

const OffersTable = ({ data, rerender }) => {
    const profile = useSelector(state => state.profile)
    const [statuses, setStatuses] = useState([])
    const [loading, setLoading] = useState(false)
    const [stage, setStage] = useState(0)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    useEffect(() => {
        function stageGetter() {
            let new_data
            if (profile.user.role === 2) new_data = data.provider_selected_service_offers
            if (profile.user.role === 3) new_data = data.selected_service_offers

            if (data.status !== 1) return setStage(0)
            if (profile.user.role === 2) {
                if (new_data[0].status_by_provider === 1) return setStage(1)
                if (new_data[0].status_by_provider !== 1) return setStage(0)
            }
            if (profile.user.role === 3) {
                if (new_data[0].status_by_provider === 1) return setStage(0)
                if (new_data[0].status_by_provider !== 1) {
                    if (new_data[0].status_by_client === 1) return setStage(1)
                    if (new_data[0].status_by_client !== 1) return setStage(2)
                }
            }
            return setStage(0)
        }
        stageGetter()
    }, [data.provider_selected_service_offers, data.selected_service_offers, data.status, profile.user.role])

    function getStatus(input) {
        if (input === 1) return <span>Pending</span>
        if (input === 2) return <span style={{ fontWeight: "bold", color: 'var(--success)' }}>Accepted</span>
        if (input === 3) return <span style={{ fontWeight: "bold", color: 'var(--danger)' }}>Rejected</span>
        return ''
    }
    function addStatus(id, status) {
        let found
        let array = statuses.map(item => {
            if (item.id === id) {
                found = true
                return { id: id, status: status }
            }
            return item
        })
        if (!found) array = statuses.concat([{ id: id, status: status }])
        setStatuses(array)
    }
    function disabledActions(input) {
        if (profile.user.role === 2 && input.status_by_provider !== 1) return true
        if (profile.user.role === 3 && input.status_by_client !== 1) return true
        return false
    }
    function makeActiveButton(id, status) {
        let found = false
        statuses.map(item => {
            if (item.id === id && item.status === status) {
                found = true
            }
            return item
        })
        return !found
    }
    function disabledActionsButtons() {
        // let new_data
        // if (profile.user.role === 2) new_data = data.provider_selected_service_offers
        // if (profile.user.role === 3) new_data = data.selected_service_offers
        if (loading) return true
        if (stage === 1) return statuses.length === 0
        // if (stage === 1) return true
        if (stage === 2) return false
    }
    async function acceptSelectedServiceOffers() {
        setLoading(true)
        let out_come_data = { selected_service_offers: statuses }
        if (profile.user.role === 2) out_come_data = { provider_selected_service_offers: statuses }
        if (profile.user.role === 3) out_come_data = { selected_service_offers: statuses }
        let result = await reservationService.acceptSelectedServiceOffers(data.id, out_come_data)
        if (result.status !== 200) {
            setError(true)
            setErrorMessage(result.response.message)
        }
        setLoading(false)
        setStatuses([])
        rerender()
    }
    async function acceptReservation() {
        setLoading(true)
        await reservationService.updateReservationsByID(data.id, { status: 2 })
        await reservationService.createOrder(data.id)
        rerender()
        setLoading(false)
    }
    async function rejectReservation() {
        setLoading(true)
        await reservationService.updateReservationsByID(data.id, { status: 3 })
        rerender()
        setLoading(false)
    }
    function SpecificOffers() {
        let new_data
        if (profile.user.role === 2) new_data = data.provider_selected_service_offers
        if (profile.user.role === 3) new_data = data.selected_service_offers
        return new_data.map((item, key) => (
            <Table.Row
                style={styles.adding}
                key={key + 100}
                className="service-offer-row"
            >
                <Table.Cell>{key + 1}.</Table.Cell>
                <Table.Cell ><Label ><Icon name='cog' />{item.service_offer.name}</Label></Table.Cell>
                <Table.Cell>{getStatus(item.status_by_provider)}</Table.Cell>
                <Table.Cell>{getStatus(item.status_by_client)}</Table.Cell>
                <Table.Cell>{item.total_men}</Table.Cell>
                <Table.Cell >{parseFloat(item.price_display)} {item.service_offer.currency}</Table.Cell>
                <Table.Cell><Actions item={item} /></Table.Cell>
            </Table.Row>
        ))
    }
    const Actions = ({ item }) => {
        if (profile.user.role === 3 && item.status_by_provider === 1) return (<></>)
        if (profile.user.role === 3 && item.status_by_client !== 1) return (<></>)
        if (profile.user.role === 2 && item.status_by_provider !== 1) return (<></>)
        return (
            <>
                <ActionButton action={() => addStatus(item.id, 2)} icon='checkmark' disabled={disabledActions(item)} loading={false} basic={makeActiveButton(item.id, 2)} />
                <ActionButton action={() => addStatus(item.id, 3)} icon='close' disabled={disabledActions(item)} loading={false} basic={makeActiveButton(item.id, 3)} />
            </>
        )
    }
    const Heading = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Technology</Table.HeaderCell>
                <Table.HeaderCell>{profile.user.role === 2 ? <span>Your status</span> : <span>Provider status</span>}</Table.HeaderCell>
                <Table.HeaderCell>{profile.user.role === 3 ? <span>Your status</span> : <span>Client status</span>}</Table.HeaderCell>
                <Table.HeaderCell>Total people</Table.HeaderCell>
                <Table.HeaderCell>Price</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
        )
    }
    return (
        <Grid>
            <Table style={{ padding: 0, marginTop: '1rem' }} fixed>
                <Table.Header><Heading /></Table.Header>
                <Table.Body><SpecificOffers /></Table.Body>
            </Table>
            <ReservationActions
                onAcceptOffers={() => acceptSelectedServiceOffers()}
                onAcceptReservation={() => acceptReservation()}
                onRejectReservation={() => rejectReservation()}
                disabled={disabledActionsButtons()}
                stage={stage}
                loading={loading}
                data={data} />
            <ResponseModal
                open={error}
                onClose={() => setError(false)}
                header={'Error'}
                message={errorMessage}
                onClick={() => setError(false)}
                actionButton={'OK'}
            />
        </Grid>
    )
}

export default OffersTable

const styles = {
    row: {
        borderTopStyle: 'solid',
        borderTopColor: 'var(--border)',
        borderTopWidth: 1,
        padding: '1rem',
        fontFamily: 'Jura',
    },
    adding: {
        fontFamily: 'Jura'
    },
    row_active: {
        borderTopStyle: 'solid',
        borderTopColor: 'var(--border)',
        borderTopWidth: 1,
        backgroundColor: "var(--primary)",
        fontFamily: 'Jura'
    },

    head: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'var(--border)',
        textDecoration: 'underline',
        padding: '1rem',
        justifyContent: 'space-between'
    },
    column: {
        display: 'flex',
        width: '8rem',
    },
    column_short: {
        display: 'flex',
        width: '4rem',
    },
}