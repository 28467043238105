import React from 'react'
import { Modal } from 'semantic-ui-react'

const TermsAndConditions = ({ open, onClose }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            closeIcon
            size='small'
        >
            <Modal.Header>TERMS AND CONDITIONS</Modal.Header>
            <Modal.Content>tu treba dodat texty</Modal.Content>
        </Modal>
    )
}

export default TermsAndConditions
