import React, { useState } from 'react'
import { Grid, Button, Form, Divider } from 'semantic-ui-react'
import CurrencyInput from '../../../../components/inputs/CurrencyInput'
import AddressForm from '../../../../components/inputs/AddressForm'
const BankForm = ({ handleData, nextStep, previousStep, data }) => {
    const [account, setAccount] = useState(
        data ? data.account : {
            name: '',
            iban: '',
            alias: '',
            currency: '',
            swift_code: '',
            bank_detail: '',
        })
    const [address, setAddress] = useState(
        data ? data.bank_address : {
            street: '',
            city: '',
            state: '',
            postcode: '',
            country: '',
            address_line: '',
            latitude: '',
            longitude: ''
        })
    const [currency, setCurrency] = useState(data ? data.currency : '')

    function handleAccount(e, { name, value }) {
        setAccount((prevState) => ({ ...prevState, [name]: value }))
    }

    function handleCurrency(input) {
        setCurrency(input)
    }

    function isAllFilled() {
        if (account.name &&
            account.iban &&
            account.swift_code //&&
            // account.alias &&
            // account.bank_detail &&
            // address.street &&
            // address.city &&
            // address.state &&
            // address.postcode &&
            // address.country
        ) return true
        return false
    }

    function confirm() {
        handleData({
            account: account,
            bank_address: address,
            currency: currency,
        })
        nextStep()
    }
    return (
        <>
            <Divider horizontal>Bank information</Divider>
            <Grid.Row>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <span>Name of bank</span><span style={{ color: 'var(--danger)' }}>*</span>
                    <Form.Input
                        fluid
                        placeholder="Enter bank name"
                        value={account.name}
                        name="name"
                        onChange={handleAccount} />
                </Grid.Column >
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <span>IBAN</span><span style={{ color: 'var(--danger)' }}>*</span>
                    <Form.Input
                        fluid
                        placeholder="Enter your IBAN"
                        value={account.iban}
                        name="iban"
                        onChange={handleAccount} />
                </Grid.Column >
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    <span>SWIFT</span><span style={{ color: 'var(--danger)' }}>*</span>
                    <Form.Input
                        fluid
                        placeholder="Enter SWITF"
                        value={account.swift_code}
                        name="swift_code"
                        onChange={handleAccount} />
                </Grid.Column >
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <span>Bank alias</span>
                    <Form.Input
                        fluid
                        placeholder="Enter bank alias"
                        value={account.alias}
                        name="alias"
                        onChange={handleAccount} />
                </Grid.Column >
                <Grid.Column computer={4} tablet={4} mobile={16} style={{ display: 'flex', flexDirection: 'column' }}>
                    <CurrencyInput handler={(e) => handleCurrency(e)} data={currency} />
                </Grid.Column >
                <Grid.Column computer={16} tablet={16} mobile={16}>
                    <span>Bank detail</span>
                    <Form.Input
                        fluid
                        placeholder="Enter bank detail"
                        value={account.bank_detail}
                        name="bank_detail"
                        onChange={handleAccount} />
                </Grid.Column >
            </Grid.Row>
            <Divider horizontal>Bank address</Divider>
            <Grid.Row>
                <AddressForm
                    handleData={(e) => setAddress(e)}
                    data={address}
                    notRequired={true} />
            </Grid.Row>
            <Grid.Column width={16} style={{ marginTop: '1rem' }}>
                <Button onClick={previousStep}>Previous</Button>
                <Button onClick={() => confirm()} disabled={!isAllFilled()}>Next</Button>
            </Grid.Column>
        </>
    )
}

export default BankForm
