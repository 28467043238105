import React, { Fragment, useEffect, useState } from 'react';
// requests
import { userService } from '../../../store/services/users/ServiceUser';
import { useParams } from 'react-router-dom'
import { useSelector } from "react-redux";

// components
import { Grid } from 'semantic-ui-react';
import Loading from '../../../components/general/Loading';
import BaseDetails from './details/BaseDetails'
import BusinessDetails from './details/BusinessDetails';
import ContactDetails from './details/ContactDetails';
import InitialLogin from './InitialLogin'
import ClientProfileCreate from './ClientProfileCreate'


const Profile = () => {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [client, setClient] = useState(false)


    const profile = useSelector(state => state.profile)
    const params = useParams()

    useEffect(() => {
        async function fetchData() {
            if (!params.id) {
                if (!profile) {
                    let result = await userService.getUserProfile()
                    if (result.status === 200) {
                        setData(result.response);
                    }
                }
                setData(profile)
                if (profile.user.role === 3) {
                    setClient(true)
                }
            } else {
                let result = await userService.getUserByID(parseInt(params.id))
                if (result.status === 200) {
                    setData(result.response);
                }
            }
            setIsLoading(false);
        };

        fetchData();
    }, [params.id, profile]);
    function resetData(input) {
        setData((prevState) => (
            {
                ...prevState,
                business_detail: input.business,
                person: input.person
            }))
    }
    function resetDataClient(input) {
        setData((prevState) => (
            {
                ...prevState,
                business_detail: input.business,
                person: input.person,
                phone: input.phone
            }))
    }


    return (
        <Fragment>
            {isLoading ? <Loading /> :
                <Grid style={styles.container}>
                    <>
                        {data.person &&
                            <Grid>
                                <Grid.Column mobile={16} tablet={4} computer={4} style={{ marginTop: '1rem' }}>
                                    <BaseDetails
                                        data={data.person}
                                        username={data.user.username}
                                        joined={data.user.date_joined} />
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={12} computer={12}>
                                    <Grid>
                                        {(data.user.role !== 1 && data.user.role !== 5) && <Grid.Row><BusinessDetails data={data.business_detail} /></Grid.Row>}
                                        <Grid.Row><ContactDetails data={data} /></Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid>
                        }
                        {(!data.person && client) && <ClientProfileCreate client profile={data} rerender={(e) => resetDataClient(e)} />}
                        {(!data.person && !client) && <InitialLogin profile={data} rerender={(e) => resetData(e)} />}
                    </>
                </Grid>
            }
        </Fragment >
    );
};
const styles = {
    container: {
        backgroundColor: 'var(--light)',
        margin: '1rem',
        borderColor: 'var(--border)',
        borderStyle: 'solid',
        borderWidth: 1,
    }
}
export default Profile;
