import React from 'react';
import { Bar } from 'react-chartjs-2';

// components
import GraphSegment from './GraphSegment';

function GraphBar() {

    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: '#6b48ff',
                borderColor: "var(--variant3)",
                borderWidth: 1,
                hoverBackgroundColor: "#552fe4",
                hoverBorderColor: "var(--variant2)",
                data: [65, 59, 80, 81, 56, 55, 40]
            }
        ]
    };

    return (
        <GraphSegment
            header="Bar Chart"
            content={
                <Bar
                    data={data}
                    width={100}
                    height={400}
                    options={{
                        maintainAspectRatio: false
                    }}
                />
            }
        />
    );
}

export default GraphBar