import { APIRequest } from '../helpers/requests';
import { API } from '../../config';


export const serviceService = {
    getServices,
    getAllServices,
    createService,
    getServiceById,
    updateService,
    deleteService,
    getFilteredServicesByProject
}

async function getServices() {
    return await APIRequest({
        url: API.SERVICES,
        method: 'GET',
        data: {},
        private: true
    });
}
async function getAllServices(input) {
    if (!input) {
        return await APIRequest({
            url: API.ALL_SERVICES,
            method: 'GET',
            data: {},
            private: true
        });
    }
    let data = {
        category: input.category,
        is_active: input.is_active,
        is_outsourcing: input.is_outsourcing,
        minimum_contract_length_max: input.minimum_contract_length_max,
        minimum_contract_length_min: input.minimum_contract_length_min,
        minimum_order_max: input.minimum_order_max,
        minimum_order_min: input.minimum_order_min,
        name: input.name,
        price_max: input.price_max,
        price_min: input.price_min,
        project_manager: input.project_manager,
        time_before_order_max: input.time_before_order_max,
        time_before_order_min: input.time_before_order_min,
        total_persons_max: input.total_persons_max,
        total_persons_min: input.total_persons_min
    }
    let result = '?'
    Object.keys(data).forEach(function (value) {
        if (data[value]) {
            result += `${value}=${data[value]}&`
        }
    });
    result = result.slice(0, result.length - 1)
    if (result.length === 1) result = ''
    return await APIRequest({
        url: API.ALL_SERVICES + result,
        method: 'GET',
        data: {},
        private: true
    });
}
async function getFilteredServicesByProject(id) {
    return await APIRequest({
        url: API.FILTERED_SERVICES + id,
        method: 'GET',
        data: {},
        private: true
    });
}

async function createService(data) {
    return await APIRequest({
        url: API.SERVICES,
        method: 'POST',
        data: data,
        private: true
    });
}
async function getServiceById(id) {
    return await APIRequest({
        url: API.SERVICES + id,
        method: 'GET',
        data: {},
        private: true
    });
}
async function updateService(id, data) {
    return await APIRequest({
        url: API.SERVICES + id,
        method: 'PATCH',
        data: data,
        private: true
    });
}
async function deleteService(id) {
    return await APIRequest({
        url: API.SERVICES + id,
        method: 'DELETE',
        data: {},
        private: true
    });
}