import React, { Fragment, useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { useParams, useHistory } from 'react-router-dom'

import Loading from '../../../../components/general/Loading'
import HeaderReservation from './HeaderReservation'
import PremiumUUID from './PremiumUUID'
import OffersTable from './OffersTable'

import { reservationService } from '../../../../store/services/reservations/ReservationServices'
import ResponseModal from '../../../../components/modals/ResponseModal'
import UpdateReservationModal from './modal/UpdateReservationModal'
import { useSelector } from 'react-redux'
import BackButton from '../../../../components/buttons/BackButton'

const ReservationDetail = () => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const [workLoading, setWorkLoading] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [saveModal, setSaveModal] = useState(false)
    const params = useParams()
    const history = useHistory()
    const profile = useSelector(state => state.profile)



    useEffect(() => {
        async function fetchData() {
            let result
            if (profile.user.role === 2) result = await reservationService.getProviderReservationsByID(parseInt(params.id));
            if (profile.user.role === 3) result = await reservationService.getReservationsByID(parseInt(params.id));
            if (result.status === 200) {
                setData(result.response);
            }
            setLoading(false);
        };
        fetchData();
    }, [params.id, profile.user.role])

    async function deleteReservation() {
        await reservationService.deleteReservationsByID(params.id)
        history.goBack()
        setDeleteModal(false)
    }
    async function saveReservation(input) {
        setWorkLoading(true)
        //NEED TO ADD SELECTED SERVICE OFFER 
        let new_data = {
            is_premium: input.is_premium,
            // selected_service_offers: input.selected_service_offers,
            valid_until: input.valid_until,
        }
        let result = await reservationService.updateReservationsByID(params.id, new_data)
        if (result.status === 200) setData(result.response)
        setSaveModal(false)
        setWorkLoading(false)
    }

    async function rerender() {
        let result
        if (profile.user.role === 2) result = await reservationService.getProviderReservationsByID(parseInt(params.id));
        if (profile.user.role === 3) result = await reservationService.getReservationsByID(parseInt(params.id));
        if (result.status === 200) {
            setData(result.response);
        }
    }
    return (
        <Fragment>
            <ResponseModal
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
                icon='close'
                header='Delete'
                message='Dou you really want to delete this reservation?'
                onClick={() => deleteReservation()}
                cancel={true}
                actionButton='Delete'
            />
            <UpdateReservationModal
                open={saveModal}
                onClose={() => setSaveModal(false)}
                onAction={(e) => saveReservation(e)}
                input={data}
                loading={workLoading}
            />
            {loading ? <Loading /> :
                <Grid style={{ padding: '1rem' }}>
                    <BackButton content="Back to list" />
                    <HeaderReservation data={data} />
                    <PremiumUUID data={data} />
                    <OffersTable data={data} rerender={() => rerender()} />
                </Grid>
            }
        </Fragment>
    )
}

export default ReservationDetail
