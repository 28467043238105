import React, { useState } from 'react'
import { Header, Button, Grid, Form } from 'semantic-ui-react'

//services
import { isEmpty } from '../../../store/services/helpers/validation'
import { userService } from '../../../store/services/users/ServiceUser'
import { connect } from 'react-redux'
import { changeUsername } from "../../../store/actions/index";

//Components
import ResponseModal from '../../../components/modals/ResponseModal'

const UsernameChange = ({ container, dispatch }) => {
    const [password, setPassword] = useState("")
    const [response, setResponse] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [username, setUsername] = useState("")
    const [loading, setLoading] = useState(false)

    const updateNewUsername = async () => {
        setLoading(true)
        let data = {
            current_password: password,
            new_username: username,
        }
        let result = await userService.setUserName(data)
        if (result.status === 204) {
            setResponseMessage('Username was changed successfully.')
            dispatch(changeUsername(username))
        } else {
            setResponseMessage(result.response.new_username ? result.response.new_username : "Wrong password")
        }
        setResponse(true)
        setPassword('')
        setUsername('')
        setLoading(false)
    }
    return (
        <Grid style={container}>
            <ResponseModal
                open={response}
                onClose={() => setResponse(false)}
                header={'Changing username'}
                message={responseMessage}
                onClick={() => setResponse(false)}
            />
            <Grid.Column width={16} >
                <Header>Change username</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <span>Your new username</span>
                <Form.Input
                    fluid
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    error={isEmpty(username)}
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <span>Your current password</span>
                <Form.Input
                    fluid
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={isEmpty(username)}
                />
            </Grid.Column>
            <Grid.Column width={16} >
                <Button
                    content="Save new username"
                    onClick={() => updateNewUsername()}
                    loading={loading}
                    disabled={isEmpty(password) || isEmpty(password)}
                />
            </Grid.Column>
        </Grid>

    )
}
export default connect()(UsernameChange)
