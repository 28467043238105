import React, { useEffect, useState } from 'react'
import { Button, Input, Grid, Icon, Label, Modal, Header, Segment } from 'semantic-ui-react'
import DateInputCustom from '../../../../../components/inputs/DateInputCustom'
import { dateService } from '../../../../../store/services/custom/DateServices'

const UpdateReservationModal = ({ open, onClose, onAction, input, loading }) => {
    const [data, setData] = useState(input)

    useEffect(() => {
        setData(input)
    }, [input])


    function handleTotalMen(value, index) {
        let array = data.selected_service_offers.map((item, key) => {
            if (key === index) item.total_men = value
            return item
        })
        setData((prevState) => ({ ...prevState, selected_service_offers: array }))
    }
    function deleteServiceOffer(index) {
        let array = data.selected_service_offers.filter((item) => item.id !== index)
        setData((prevState) => ({ ...prevState, selected_service_offers: array }))
    }
    function getBetterTimeFormat(input) {
        if (input) {
            let time = input.split(' ')
            return dateService.rotateDate(time[0])
        }
        return '0'
    }

    const ListOfOffers = () => {
        return data.selected_service_offers.map((item, key) => (
            <div key={key} style={styles.offer}>
                <Input
                    label='Total men:'
                    style={{ display: 'flex', width: '6%' }}
                    value={item.total_men}
                    onChange={(e) => handleTotalMen(e.target.value, key)}
                />
                <Label>{item.service_offer.name}</Label>
                {data.selected_service_offers.length > 1 &&
                    <Icon name='close' style={{ cursor: 'pointer' }} onClick={() => deleteServiceOffer(item.id)} />
                }
            </div>
        ))
    }
    function discard() {
        setData(input)
        onClose()
    }


    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Modal.Header style={{ textAlign: "center" }}>Update reservation</Modal.Header>
            <Modal.Content>
                <Grid>
                    <div style={{ marginTop: '1rem', display: 'flex', alignItems: "flex-end", justifyContent: "space-between", width: "100%" }}>
                        <DateInputCustom
                            label={'Valid until'}
                            min={getBetterTimeFormat(data.created_when)}
                            input={getBetterTimeFormat(data.valid_until)}
                            handler={(e) => setData((prevState) => ({ ...prevState, valid_until: e }))} />
                        <Button
                            circular icon='star'
                            content={data.is_premium ? "Activated premium" : "No premium"}
                            basic={!data.is_premium}
                            onClick={() => setData((prevState) => ({ ...prevState, is_premium: !data.is_premium }))} />
                    </div>
                    <Segment disabled style={{ width: '100%' }}>
                        <Header>Selected service offers</Header>
                        <ListOfOffers />
                    </Segment>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button content='Discard changes' onClick={() => discard()} basic disabled={loading} />
                <Button content='Save changes' onClick={() => onAction(data)} loading={loading} />
            </Modal.Actions>
        </Modal>
    )
}
const styles = {
    offer: {
        borderBottom: '1px solid var(--border)',
        display: "flex",
        width: "100%",
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '.5rem'
    }
}
export default UpdateReservationModal
