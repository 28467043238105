import React, { useState } from 'react'
import { Container, Input, Header, Button } from 'semantic-ui-react'
import ResponseModal from '../../../../components/modals/ResponseModal'
import { isEmailError } from '../../../../store/services/helpers/validation'
import { userService } from '../../../../store/services/users/ServiceUser'
const Recommend = () => {

    const [data, setData] = useState('')
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')


    function getEmailError() {
        if (data === '') return false
        return isEmailError(data) ? true : false
    }
    async function proceedRecommendation() {
        setLoading(true)
        let result = await userService.recommendPerson(data)
        setOpen(true)
        setMessage(result.response.message)
        setData('')
        setLoading(false)
    }

    return (
        <Container style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: 'column', height: '80vh' }}>
            <ResponseModal
                open={open}
                onClick={() => setOpen(false)}
                message={message}
            />
            <Header>Recommend person by email</Header>
            <Input
                placeholder="example@example.com"
                style={{ width: "50%" }}
                value={data}
                onChange={(e) => setData(e.target.value)}
                error={getEmailError()}
            />
            <Button content="Recommend" onClick={() => proceedRecommendation()} style={{ marginTop: '1rem' }} disabled={getEmailError() || data === ''} loading={loading} />
        </Container>
    )
}

export default Recommend
