import React, { useState } from 'react'
import { Grid, Icon, Popup } from 'semantic-ui-react'

import InfoColSO from './InfoColSO'
import EditCreateService from '../services/EditCreateService'
import DeactivationService from '../services/DeactivationService'
import ActivationService from '../services/ActivationService'
import Technology from '../../../../components/general/Technology'

import { serviceOfferService } from '../../../../store/services/services/ServiceOfferService'

const CardSO = ({ data, active, rerender, updateEditedOffer }) => {
    const [editModal, setEditModal] = useState(false)
    const [deactivation, setDeactivation] = useState(false)
    const [activation, setActivation] = useState(false)

    async function onDeactivation() {
        setDeactivation(false)
        let result = await serviceOfferService.deactivateServiceOffer(data.id)
        if (result.status === 200) {
            rerender(result.response)
        }
    }

    async function onEdit(input) {
        let editOffer
        if (input.technology.id) editOffer = { ...input, technology: input.technology.id }
        if (!input.technology.id) editOffer = { ...input, technology: input.technology }
        let result = await serviceOfferService.updateServiceOffer(data.id, editOffer)
        if (result.status === 200) {
            updateEditedOffer(result.response)
            setEditModal(false)
        }
    }

    async function onActivation() {
        setActivation(false)
        let result = await serviceOfferService.activateServiceOffer(data.id)
        if (result.status === 200) {
            rerender(result.response)
        }
    }

    function convertTypeToText(number, type) {
        let result
        if (type === 1) result = number + " minute"
        if (type === 2) result = number + " hour"
        if (type === 3) result = number + " day"
        if (type === 4) result = number + " week"
        if (type === 5) result = number + " month"
        if (number > 1) return result + "s"
        return result
    }

    function projectManagerConvertToString(manager) {
        if (manager) return "Yes"
        return "No"
    }

    return (
        <Grid style={styles.container}>
            <EditCreateService
                open={editModal}
                editing
                cancel={() => setEditModal(false)}
                onEdit={(id) => onEdit(id)}
                data={data}
            />
            <DeactivationService open={deactivation} cancel={() => setDeactivation(false)} onDeactivation={() => onDeactivation()} />
            <ActivationService open={activation} cancel={() => setActivation(false)} onActivation={() => onActivation()} />

            <div style={styles.icons_group}>
                <Popup content='Edit service offer' trigger={
                    <Icon name='pencil' style={styles.icn_one} onClick={() => setEditModal(true)} />} />
                {active
                    ? <Popup content='Deactivate service offer' trigger={
                        <Icon name='minus square outline' style={styles.icn_two} onClick={() => setDeactivation(true)} />} />
                    : <Popup content='Activate service offer' trigger={
                        <Icon name='plus square outline' style={styles.icn_three} onClick={() => setActivation(true)} />} />}
            </div>
            <InfoColSO name="Price" value={data.price + " " + data.currency} />
            <InfoColSO name="Total people" value={data.total_persons} />
            <InfoColSO name="Minimum order" value={data.minimum_order} />
            <InfoColSO name="Dedication" value={data.dedication} />
            <InfoColSO name="Time before order" value={convertTypeToText(data.tbo_display, data.time_before_order_type)} />
            <InfoColSO name="Minimum contract length" value={convertTypeToText(data.mcl_display, data.minimum_contract_length_type)} />
            <InfoColSO name="Project manager" value={projectManagerConvertToString(data.project_manager)} />
            <Grid.Column style={{ width: 'auto', display: 'flex', alignItems: "center", justifyContent: "center" }}>
                <Technology data={data.technology.name} />
            </Grid.Column>
        </Grid >
    )
}
const styles = {
    container: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: "var(--border)",
        width: "100%",
        backgroundColor: "#f3f6f9"
    },
    row: { padding: 0 },
    info: { display: 'flex', alignItems: 'center' },
    dif_row: { padding: 0, },
    icn_one: { color: 'var(--black)', fontSize: '1.25rem', marginRight: '1rem', cursor: 'pointer' },
    icn_two: { color: 'var(--primary)', fontSize: '1.25rem', cursor: 'pointer' },
    icn_three: { color: 'var(--success)', fontSize: '1.25rem', cursor: 'pointer' },
    icons_group: {
        position: "absolute",
        right: 0,
        zIndex: 10,
        margin: '1rem'
    }

}
export default CardSO
