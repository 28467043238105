import React from 'react'
import { Grid, Icon } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import { routes } from '../../../store/routes';

const Footer = () => {
    return (
        <Grid style={{ backgroundColor: "var(--black)", color: "var(--white)", width: "100%", paddingBottom: "3%" }} className="text-inc home-footer">
            <Grid.Row columns={5} style={{ display: "flex", justifyContent: "center" }}>
                <Grid.Column
                    mobile={16} tablet={3} computer={3} className="img-footer"
                    style={{ filter: "invert(100%)" }}>
                    <img src="/images/hiringINIT_logo.svg" alt="logo" height="50%" />
                </Grid.Column>
                <Grid.Column
                    style={styles.column}
                    className="footer-column"
                    mobile={16} tablet={3} computer={3}>
                    <p style={{ fontSize: 18 }}>Employers</p>
                    <ul style={styles.list_text}>
                        <li><Link to={"#"}>For Employers</Link></li>
                        <li><Link to={"#"}>How it Works</Link></li>
                        <li><Link to={"#"}>Meet our Talent</Link></li>
                        <li><Link to={"#"}>Resources</Link></li>
                        <li><Link to={"#"}>Success Stories</Link></li>
                        <li><Link to={"#"}>FAQ</Link></li>
                        <li><Link to={"#"}>Contracta</Link></li>
                    </ul>
                    <div className="fooer-divider"></div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={3} computer={3} style={styles.column} className="footer-column">
                    <p style={{ fontSize: 18 }}>Talent</p>
                    <ul style={styles.list_text}>
                        <li><Link to={"#"}>Talent</Link></li>
                        <li><Link to={"#"}>How it Works</Link></li>
                        <li><Link to={"#"}>Success Stories</Link></li>
                        <li><Link to={"#"}>FAQ</Link></li>
                        <li><Link to={"#"}>Contract</Link></li>
                        <li><Link to={"#"}>Blog</Link></li>
                    </ul>
                    <div className="fooer-divider"></div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={3} computer={3} style={styles.column} className="footer-column">
                    <p style={{ fontSize: 18 }}>Company</p>
                    <ul style={styles.list_text}>
                        <li><Link to={routes.TIPPER_REGISTER}>Tippers</Link></li>
                        <li><Link to={"#"}>About</Link></li>
                        <li><Link to={"#"}>Careers</Link></li>
                        <li><Link to={"#"}>Press</Link></li>
                        <li><Link to={"#"}>Blog</Link></li>
                        <li><Link to={"#"}>Press</Link></li>
                    </ul>
                    <div className="fooer-divider"></div>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={3} computer={3} style={styles.column} className="footer-column">
                    <p style={{ fontSize: 18 }}>Information</p>
                    <ul style={styles.list_text}>
                        <li><Link to={"#"}>Help Center</Link></li>
                        <li><Link to={"#"}>Terms{" & "}Conditions</Link></li>
                        <li><Link to={"#"}>Privacy</Link></li>
                        <li><Link to={"#"}>Cancellation</Link></li>
                        <li><Link to={"#"}>Contact</Link></li>
                    </ul>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ display: 'flex', flexDirection: 'column', justifyContent: "center", textAlign: 'center' }}>
                <div style={{ padding: "1rem" }}>
                    <Link to={"#"}><Icon size="large" name="facebook f" style={{ margin: "1rem" }} /></Link>
                    <Link to={"#"}><Icon size="large" name="instagram" style={{ margin: "1rem" }} /></Link>
                    <Link to={"#"}><Icon size="large" name="linkedin" style={{ margin: "1rem" }} /></Link>
                </div>
                <div style={{ fontSize: "1rem" }}>HIRING. CODING. OUTSOURCING</div>
                {/* <div>
                    <img src="/images/sodonbos_logo.png" alt="logo" width="8%" style={{ textAlign: 'center' }} />
                </div> */}
            </Grid.Row>
        </Grid >
    )
}
const styles = {
    list_text: {
        listStyleType: "none",
        padding: 0,
        fontSize: 16,
        lineHeight: 1.2,
        margin: 0
    },
    column: {
        marginTop: "5%",
    }
}

export default Footer
