import React from 'react'
import { Button, Dropdown, Icon } from 'semantic-ui-react'
import Sticky from "react-stickynode";
import { Link } from "react-router-dom";

// routes
import { routes } from "../../../store/routes/index";

const Navbar = () => {
    const trigger = (
        <span>
            <Icon name='bars' size="big" />
        </span>
    )

    const options = [
        {
            key: 0,
            text: (
                <Link to={"#"} style={styles.linkFirst}>For Companies</Link>
            ),
        },
        {
            key: 1,
            text: (
                <Link to={"#"} style={styles.link}> How It Works</Link>
            ),
        },
        {
            key: 2,
            text: (
                <Link to={"#"} style={styles.link}>About Us</Link>
            ),
        },
        {
            key: 3,
            text: (
                <Link to={routes.LOGIN} style={styles.link}>Log In</Link>
            ),
        },
        {
            key: 4,
            text: (
                <Link to={routes.REGISTRATION} style={styles.link} className="navbar-link-button">
                    <Button style={styles.button}>REGISTER NOW</Button>
                </Link>
            ),
        },
    ]
    return (
        <Sticky innerZ={10} activeClass={"stickyBar"}>
            <div style={styles.nav} className="navbar">
                <div className="nav-logo"><img src={"/images/hiringINIT_logo.svg"} alt="Logo" height="50" /></div>
                <div className="navbar-links">
                    <ul>
                        <Link to={"#"}><li style={styles.linkFirst} className="navbar-link">For Companies</li></Link>
                        <Link to={"#"}><li style={styles.link} className="navbar-link">How It Works</li></Link>
                        <Link to={"#"}><li style={styles.link} className="navbar-link">About Us</li></Link>
                        <Link to={routes.LOGIN}><li style={styles.link} className="navbar-link">Log In</li></Link>
                        <Link to={routes.REGISTRATION}><li style={styles.link} className="navbar-link-button">
                            <Button style={styles.button} className="navbar-link">REGISTER NOW</Button>
                        </li></Link>
                    </ul>
                </div>
                <div className="navbar-drop">
                    <Dropdown trigger={trigger} options={options} pointing='top right' icon={null} />
                </div>
            </div>
        </Sticky>
    )
}
const styles = {
    nav: {
        display: "flex",
        padding: '0.2rem 7%',
        backgroundColor: "var(--white)",
        alignItems: "center",
        justifyContent: "space-between",
        color: "var(--black) !important",
    },
    link: {
        fontWeight: 'bold',
        fontSize: "1rem",
        width: "auto",
    },
    linkFirst: {
        fontWeight: 'bold',
        fontSize: "1rem",
        color: "var(--primary)",
        width: "auto",
    },
    button: {
        backgroundColor: "var(--primary)",
        color: "var(--white)",
        padding: "1rem 2rem"
    }
}
export default Navbar
