import React from 'react';
import { List } from 'semantic-ui-react';

import { routes } from '../../../store/routes/index';
import SidebarItem from '../_views/SidebarItem';
import { useSelector } from "react-redux";

const Sidebar = () => {
    const profile = useSelector(state => state.profile)

    return (
        <div className="sidebar scrollable">
            {profile &&
                <List.Content>
                    <List className="siderbar-list">
                        {profile.person &&
                            <>
                                <SidebarItem keyId="profile" to={routes.PROFILE} text="Profile" icon="user" />
                                <SidebarItem keyId="settings" to={routes.SETTINGS} text="Settings" icon="setting" />
                                {profile?.user?.role === 1 && //ADMIN
                                    <>
                                        <SidebarItem keyId="requests" to={routes.REQUESTS} text="Requests" icon="mail" />
                                        <SidebarItem keyId="technologies" to={routes.TECHNOLOGIES} text="Technologies" icon="cubes" />
                                        <SidebarItem keyId="users-management" to={routes.USERS} text="Users management" icon="users" />
                                        <SidebarItem keyId="bundles-management" to={routes.BUNDLES_MANAGEMENT} text="Bundles management" icon="boxes" />
                                    </>
                                }
                                {profile?.user?.role === 2 && //PROVIDER
                                    <>
                                        <SidebarItem keyId="services" to={routes.SERVICES} text="My Services" icon="handshake" />
                                    </>
                                }
                                {profile?.user?.role === 3 && //CLIENT
                                    <>
                                        <SidebarItem keyId="bundles" to={routes.BUNDLES} text="Bundles" icon="box" />
                                        <SidebarItem keyId="projects" to={routes.PROJECTS} text="Project" icon="file" />
                                        <SidebarItem keyId="service-offers" to={routes.SERVICE_OFFERS} text="Service offers" icon="sitemap" />
                                    </>
                                }
                                {profile?.user?.role === 4 && //TIPPER
                                    <>
                                        <SidebarItem keyId="recommend" to={routes.RECOMMEND} text="Recommend" icon="user" />
                                        <SidebarItem keyId="recommendations" to={routes.RECOMMENDATIONS} text="Recommendations" icon="users" />
                                    </>
                                }
                                {(profile?.user?.role === 2 || profile?.user?.role === 3) &&
                                    <>
                                        <SidebarItem keyId="reservations" to={routes.RESERVATIONS} text="Reservations" icon="book" />
                                        <SidebarItem keyId="orders" to={routes.ORDERS} text="Orders" icon="balance scale" />
                                    </>
                                }
                            </>}
                    </List>
                </List.Content>
            }
        </div>
    );
};

export default Sidebar;