import React from 'react';
import { Switch } from 'react-router-dom';

// routes
import { routes } from "../../store/routes/index";

// routes components
import PrivateRoute from "../../components/routes/PrivateRoute";

// Apps components
import Profile from "./profile/Profile";
import Requests from "./admin/requests/Requests";
import Technologies from "./admin/technologies/Technologies";
import UsersManagement from "./admin/users/UsersManagement";
import Projects from "./projects/Projects";
import ProjectDetail from "./projects/detail/ProjectDetail";
import Services from "./services/Services";
import ServiceDetail from "./services/detail/ServiceDetail";
import ServiceOffers from "./offers/ServiceOffers";
import Settings from "./settings/Settings";
import Reservations from './reservations/Reservations';
import ReservationDetail from './reservations/detail/ReservationDetail';
import Orders from './orders/Orders';
import OrderDetail from './orders/detail/OrderDetail';
import Recommendations from './tipper/recommendations/Recommendations';
import Recommend from './tipper/recommend/Recommend';
import BundlesManagement from './admin/bundles/BundlesManagement';
import Bundles from './bundles/Bundles';


import { useSelector } from "react-redux";
/*
# Add any Route you want here to extend dashboard pages
# This component contain all accessible routes for dashboard pages
*/
const DashboardPages = () => {
    const profile = useSelector(state => state.profile)
    return (
        <Switch>
            <PrivateRoute exact path={routes.PROFILE} component={Profile} />
            <PrivateRoute exact path={routes.CLIENT_PROFILE + ":id"} component={Profile} />
            <PrivateRoute exact path={routes.PROVIDER_PROFILE + ":id"} component={Profile} />
            <PrivateRoute exact path={routes.TALENT_PROFILE + ":id"} component={Profile} />
            <PrivateRoute exact path={routes.TIPPER_PROFILE + ":id"} component={Profile} />
            <PrivateRoute exact path={routes.SETTINGS} component={Settings} />
            {profile?.user?.role === 1 &&
                <>
                    <PrivateRoute exact path={routes.REQUESTS} component={Requests} />
                    <PrivateRoute exact path={routes.USERS} component={UsersManagement} />
                    <PrivateRoute exact path={routes.TECHNOLOGIES} component={Technologies} />
                    <PrivateRoute exact path={routes.BUNDLES_MANAGEMENT} component={BundlesManagement} />
                </>}
            {profile?.user?.role === 2 &&
                <>
                    <PrivateRoute exact path={routes.SERVICES} component={Services} />
                    <PrivateRoute exact path={routes.SERVICE_DETAIL + ":id"} component={ServiceDetail} />
                    <PrivateRoute exact path={routes.RESERVATIONS} component={Reservations} />
                    <PrivateRoute exact path={routes.RESERVATIONS + ":id"} component={ReservationDetail} />
                    <PrivateRoute exact path={routes.ORDERS} component={Orders} />
                    <PrivateRoute exact path={routes.ORDERS + ":id"} component={OrderDetail} />
                </>}
            {profile?.user?.role === 3 &&
                <>
                    <PrivateRoute exact path={routes.PROJECTS} component={Projects} />
                    <PrivateRoute exact path={routes.PROJECTS_DETAIL + ":id"} component={ProjectDetail} />
                    <PrivateRoute exact path={routes.SERVICE_OFFERS} component={ServiceOffers} />
                    <PrivateRoute exact path={routes.RESERVATIONS} component={Reservations} />
                    <PrivateRoute exact path={routes.RESERVATIONS + ":id"} component={ReservationDetail} />
                    <PrivateRoute exact path={routes.ORDERS} component={Orders} />
                    <PrivateRoute exact path={routes.ORDERS + ":id"} component={OrderDetail} />
                    <PrivateRoute exact path={routes.BUNDLES} component={Bundles} />
                </>}
            {profile?.user?.role === 4 &&
                <>
                    <PrivateRoute exact path={routes.RECOMMEND} component={Recommend} />
                    <PrivateRoute exact path={routes.RECOMMENDATIONS} component={Recommendations} />
                </>
            }
        </Switch>
    );
};

export default DashboardPages;