import React from 'react';
import { Link } from 'react-router-dom';
import { List, Icon } from 'semantic-ui-react';

const SidebarItem = (props) => {

    return (
        <List.Item key={props.keyId}
            as={Link} to={props.to}
            className={window.location.href.includes(props.to) ? "sidebar-item is-active" : "sidebar-item"}>
            <Icon name={props.icon} style={{ color: 'var(--black)' }} />
            <List.Content>
                {props.text}
            </List.Content>
        </List.Item>
    );

};

export default SidebarItem;