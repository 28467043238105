import React, { useState, useEffect } from 'react'
import { Form } from 'semantic-ui-react'
import { technologyService } from '../../store/services/common/TechnologyService'

const TechnologySingleSelect = ({ handler, data, exceptions }) => {
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(true)
    const [output, setOutput] = useState(data)

    const handleChange = (e, { value }) => {
        handler(value)
        setOutput(value)
    }
    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            let result = await technologyService.getTechnologies();
            if (result.status === 200) {
                setOptions(toObjectOptions(result.response))
                setLoading(false);
            }
        };
        fetchData()
    }, [])



    useEffect(() => {
        setOutput(data ? data : 0)
    }, [data])

    useEffect(() => {
        let array = options
        for (let i in options) {
            for (let j in exceptions) {
                if (options[i].value === exceptions[j]) {
                    array = array.filter(item => item.value !== exceptions[j])
                }
            }
        }
        setOptions(array)
    }, [exceptions, options])

    function toObjectOptions(input) {
        let array = []
        let result = input.map((item, id) => (
            array[id] = { key: id, value: item.id, text: item.name }))
        return result
    }

    return (
        <Form.Select
            disabled={loading}
            style={{ borderRadius: 0, width: '100%' }}
            placeholder='Select your technology'
            options={options}
            value={output}
            onChange={handleChange} />
    )
}

export default TechnologySingleSelect
