import React from 'react'
import { Grid, Modal, Header, Icon, Button } from 'semantic-ui-react'
//Services
import { dateService } from '../../store/services/custom/DateServices'

const InfoModal = ({ open, data, onClose, onAccept, onDeny, loading }) => {
    // open ={bool}
    // data ={object}
    // onClose ={func}
    // onAccept ={func}
    // onDeny ={func}
    function getDate(input) {
        return dateService.convertDateWithDots(input)
    }
    return (
        <Modal
            open={open}
            onClose={onClose}
            size="small"
        >
            <Modal.Content>
                <Grid>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Grid>
                            {(data.role !== 3 && data.role !== 5) &&
                                <Grid.Row>
                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                        <Header as='h2'>
                                            <Icon name='user' />
                                            <Header.Content>{data.company_name}</Header.Content>
                                        </Header>
                                    </Grid.Column>
                                </Grid.Row>
                            }
                            <Grid.Row style={styles.icons}>
                                <Grid.Column width={16} >
                                    <Header.Subheader><Icon name="clock" />{data.create_date ? getDate(data.create_date) : "1-1-1"}</Header.Subheader>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={8} >
                                    <Header.Subheader style={{ display: 'flex' }}><Icon name="mail" />{data.email}</Header.Subheader>
                                    {data.role !== 3 && <Header.Subheader><Icon name="phone" />{data.phone}</Header.Subheader>}
                                    {data.tipper &&
                                        <Header.Subheader><Icon name="user secret" />{data.tipper}</Header.Subheader>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    {(data.role !== 3 && data.role !== 5) &&
                        <Grid.Column mobile={16} tablet={8} computer={8}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={16} style={{ paddingBottom: '1rem' }}>
                                        <Header as='h2'>
                                            <Icon name='mobile alternate' />
                                            <Header.Content>Business</Header.Content>
                                        </Header>
                                    </Grid.Column>
                                    <Grid.Column mobile={6} tablet={5} computer={4}><Header as='h3'>ID:</Header></Grid.Column>
                                    <Grid.Column mobile={10} tablet={11} computer={12}><Header as='h3'>{data.identification_number}</Header></Grid.Column>
                                    <Grid.Column mobile={6} tablet={5} computer={4}><Header as='h3'>DIT:</Header></Grid.Column>
                                    <Grid.Column mobile={10} tablet={11} computer={12}><Header as='h3'>{data.dit_number}</Header></Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Header as='h3'>Request message</Header>
                                        <p style={{ color: 'var(--black)' }}>{data.message}</p>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    }
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button icon onClick={onClose} basic disabled={loading}>Cancel</Button>
                <Button icon onClick={onDeny} loading={loading}><Icon name="close" />Reject</Button>
                <Button icon onClick={onAccept} loading={loading}><Icon name="checkmark" />Accept</Button>
            </Modal.Actions>
        </Modal >
    )
}
const styles = {
    icons: {
        color: "var(--variant2)"
    }
}
export default InfoModal
