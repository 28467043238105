import React from 'react'
import { Grid, Divider } from 'semantic-ui-react'
const DividerHead = ({ title }) => {
    return (
        <>
            <Grid.Row style={{ padding: 0 }} />
            <Divider horizontal>{title}</Divider>
            <Grid.Row style={{ padding: 0 }} />
        </>
    )
}

export default DividerHead
