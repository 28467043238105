import React, { useEffect, useState } from 'react'
import { Header, Grid, Icon, Segment } from 'semantic-ui-react'
import OfferTable from './OfferTable'

import CreateEditSSO from './CreateEditSSO'
import Basket from './basket/Basket'

import { serviceService } from '../../../store/services/services/ServicesService'
import { reservationService } from '../../../store/services/reservations/ReservationServices'
import { bundlesService } from '../../../store/services/bundles/BundlesService'
import Filter from './Filter'
import ProjectFilter from './ProjectFilter'
// import { dateService } from '../../../store/services/custom/DateServices'

const ServiceOffers = () => {
    const [data, setData] = useState([])
    const [item, setItem] = useState(null)
    const [serviceId, setServiceId] = useState([])
    const [item_edit, setItemEdit] = useState(null)
    const [edit, setEdit] = useState(false)
    const [create, setCreate] = useState(false)
    const [selected, setSelected] = useState([])
    const [loading, setLoading] = useState(false)
    const [premium, setPremium] = useState(false)
    // const [valid, setValid] = useState(null)
    const [filtersVisible, setFiltersVisible] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [maxDatas, setMaxDatas] = useState({
        minimum_contract_length_max: 100,
        minimum_order_max: 100,
        total_price_max: 100,
        time_before_order_max: 100,
        total_persons_max: 100,
        done: false
    })
    const [projectFilterVisible, setProjectFilterVisible] = useState(false)

    useEffect(() => {
        async function fetch() {
            let result = await serviceService.getAllServices()
            if (result.status === 200) {
                setData(filterOnlyActiveServices(result.response))
                getMaxDatas(result.response)
            }
        }
        fetch()
    }, [])
    // function formatDate(input) {
    //     return new Date(dateService.rotateDate(input))
    // }
    function getMaxDatas(input) {
        let mcl = 0, mo = 0, price = 0, tbo = 0, people = 0
        input.filter(service => {
            service.service_offers.filter(offer => {
                if (offer.minimum_contract_length > mcl) mcl = offer.minimum_contract_length
                if (offer.minimum_order > mo) mo = offer.minimum_order
                if (parseFloat(offer.price) > price) price = parseFloat(offer.price)
                if (offer.time_before_order > tbo) tbo = offer.time_before_order
                if (offer.total_persons > people) people = offer.total_persons
                return offer
            })
            return service
        })
        setMaxDatas({
            minimum_contract_length_max: mcl,
            minimum_order_max: mo,
            total_price_max: price,
            time_before_order_max: tbo,
            total_persons_max: people,
            done: true
        })

    }
    function filterOnlyActiveServices(input) {
        let array = input.filter(x => x.is_active === true)
        // array = array.filter(item => {
        //     if (item.service_offers.length === 0) return false
        //     item.service_offers = item.service_offers.filter(x => x.is_active === true)
        //     item.service_offers = item.service_offers.filter(x => x.total_persons !== 0)
        //     return item
        // })
        return array
    }
    function addReservation(input, id) {
        let array = serviceId.concat([parseInt(id)])
        setServiceId(array)
        setItem(input)
        setCreate(true)
    }
    function editReservation(input, id) {
        let array = serviceId.concat([parseInt(id)])
        setServiceId(array)
        let result = input
        selected.filter(item => {
            if (item.data.id === input.id) result = item
            return item
        })
        setItem(input)
        setItemEdit(result)
        setEdit(true)
    }
    function addSelectedOffer(input) {
        setSelected(selected.concat([input]))
        setCreate(false)
    }
    function editSelectedOffer(input) {
        setSelected(selected.map(item => {
            if (item.data.id === input.data.id) return input
            return item
        }))
        setEdit(false)
    }
    function removeSelectedOffer(input) {
        setSelected(selected.filter(item => item.data.id !== input.data.id))
    }
    async function createReservation() {
        setLoading(true)
        let array_of_result_sso = []
        for (let i = 0; i < selected.length; i++) {
            let new_selected_offer = await reservationService.createSelectedServiceOffers({
                service_offer: selected[i].data.id,
                total_men: selected[i].total,
                price: selected[i].price_eur,
                currency: selected[i].currency
            })
            if (selected[i].bundle) {
                await bundlesService.postUsedBundle({
                    total_men: selected[i].total,
                    selected_service_offer: new_selected_offer.response.id,
                    used_bundle: selected[i].bundle
                })
            }
            array_of_result_sso = array_of_result_sso.concat([new_selected_offer.response.id])
        }
        let unique = [...new Set(serviceId)];
        await reservationService.createReservations({
            selected_service_offers: { "add": array_of_result_sso },
            is_premium: premium,
            // valid_until: formatDate(valid),
            services: { "add": unique }
        })
        resetBasket()
        //possible update
        let result = await serviceService.getAllServices()
        if (result.status === 200) {
            let array = filterOnlyActiveServices(result.response)
            setData(array)
        }
        setLoading(false)
    }
    async function manualFilterServices(input) {
        setFiltering(true)
        let filtered_data = {
            category: input.category === 0 ? null : input.category,
            is_active: true,
            is_outsourcing: input.is_outsourcing,
            minimum_contract_length_max: input.minimum_contract_length.max,
            minimum_contract_length_min: input.minimum_contract_length.min,
            minimum_order_max: input.minimum_order.max,
            minimum_order_min: input.minimum_order.min,
            name: input.name === "" ? null : input.name,
            price_max: input.total_price.max,
            price_min: input.total_price.min,
            project_manager: input.project_manager,
            time_before_order_max: input.time_before_order.max,
            time_before_order_min: input.time_before_order.min,
            total_persons_max: input.total_persons.max,
            total_persons_min: input.total_persons.min
        }
        let result = await serviceService.getAllServices(filtered_data)
        if (result.status === 200) {
            setData(filterOnlyActiveServices(result.response))
            getMaxDatas(result.response)
        }
        setFiltersVisible(false)
        setFiltering(false)
    }
    async function projectFilterServices(input) {
        setFiltering(true)
        let result = await serviceService.getFilteredServicesByProject(input)
        if (result.status === 200) {
            setData(filterOnlyActiveServices(result.response))
            getMaxDatas(result.response)
        }
        setFiltersVisible(false)
        setFiltering(false)
    }
    async function resetFilter() {
        setFiltering(true)
        let result = await serviceService.getAllServices()
        if (result.status === 200) {
            setData(filterOnlyActiveServices(result.response))
            getMaxDatas(result.response)
        }
        setFiltering(false)
    }
    function resetBasket() {
        setSelected([])
        setServiceId([])
    }
    function openCloseProjectFilter() {
        if (!projectFilterVisible) {
            if (filtersVisible) setFiltersVisible(false)
            setProjectFilterVisible(true)
        } else {
            setProjectFilterVisible(false)
        }
    }
    function openCloseManualFilter() {
        if (!filtersVisible) {
            if (projectFilterVisible) setProjectFilterVisible(false)
            setFiltersVisible(true)
        } else {
            setFiltersVisible(false)
        }
    }
    return (
        <Grid style={{ flexDirection: 'column' }}>
            <CreateEditSSO
                onClose={() => setCreate(false)}
                onCreate={(e) => addSelectedOffer(e)}
                open={create}
                type='create'
                input={item}
            />
            <CreateEditSSO
                onClose={() => setEdit(false)}
                onEdit={(e) => editSelectedOffer(e)}
                type='edit'
                input={item}
                open={edit}
                edit={item_edit}
                selected={selected}
            />
            <Header as='h1' style={{ color: 'var(--primary)' }}>
                Service offers
                <Header.Subheader>
                    Hire the INIT you need.
                </Header.Subheader>
            </Header>
            <div>

                {maxDatas.done &&
                    <Segment loading={filtering} style={{ margin: 0, padding: '0 1rem' }}>
                        <Filter visible={filtersVisible} max_datas={maxDatas} filter={(e) => manualFilterServices(e)} reset={() => resetFilter()} />
                        <div style={styles.arrow_button} onClick={() => openCloseManualFilter()} className="filter-services-button">
                            <Icon name={filtersVisible ? "angle up" : "angle down"} size='big' />
                            MANUAL FILTERS
                            <Icon name={filtersVisible ? "angle up" : "angle down"} size='big' />
                        </div>
                    </Segment>
                }
                <Segment loading={filtering} style={{ margin: 0, padding: '0 1rem' }}>
                    <ProjectFilter visible={projectFilterVisible} filter={(e) => projectFilterServices(e)} reset={() => resetFilter()} />
                    <div style={styles.arrow_button} onClick={() => openCloseProjectFilter()} className="filter-services-button">
                        <Icon name={projectFilterVisible ? "angle up" : "angle down"} size='big' />
                        PROJECT FILTER
                        <Icon name={projectFilterVisible ? "angle up" : "angle down"} size='big' />
                    </div>
                </Segment>
            </div>
            <OfferTable
                loading={filtering}
                data={data}
                onReserve={(x, id) => addReservation(x, id)}
                selected={selected}
                onEdit={(x, id) => editReservation(x, id)} />
            {selected.length !== 0 &&
                <Basket
                    data={selected}
                    currency={"EUR"}
                    removeOffer={(e) => removeSelectedOffer(e)}
                    confirm={() => createReservation()}
                    reset={() => resetBasket()}
                    loading={loading}
                    premiumChange={() => setPremium(!premium)}
                    premium={premium}
                // getValidUntil={(e) => setValid(e)}
                // valid={valid}
                />
            }
        </Grid>
    )
}
const styles = {
    arrow_button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        cursor: 'pointer',
    },

}
export default ServiceOffers
