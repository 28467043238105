const userReducerProfile = (profile = {}, action) => {
    switch (action.type) {
        case "ADD_PROFILE":
            return {
                id: action.profile.id,
                business_detail: action.profile.business_detail,
                person: action.profile.person,
                tipper: action.profile.tipper,
                tips: action.profile.tips,
                phone: action.profile.phone,
                language: action.profile.language,
                user: action.profile.user,
            };
        case "UPDATE_PROFILE_BUSINESS":
            return {
                ...profile,
                business_detail: action.business_detail
            }
        case "ADD_BUNDLE":
            return {
                ...profile,
                user: {
                    ...profile.user,
                    bundles: action.bundle
                }
            }
        case "CHANGE_USERNAME":
            return {
                ...profile,
                user: {
                    ...profile.user,
                    username: action.username
                }
            }
        case "DELETE_PROFILE":
            return {};
        default:
            return profile;
    }
};

export default userReducerProfile;
