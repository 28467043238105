import React from 'react'
import { Header } from 'semantic-ui-react'
import DraftSo from './DraftSO'

const ListAddsOfServiceOffers = ({ data, saveOffers }) => {

    function removeDraft(input) {
        let array = data.filter((item, key) => key !== input)
        saveOffers(array)
    }

    if (data.length === 0) return <Header style={{ textAlign: "center", width: "100%" }}>No offers yet</Header>
    if (!data) return <></>
    return data.map((item, key) => (
        <DraftSo key={key} data={item} removeAds={() => removeDraft(key)} />
    ))
}

export default ListAddsOfServiceOffers
