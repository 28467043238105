import React, { useState } from 'react'
import { Modal, Button, Grid, Form, Checkbox } from 'semantic-ui-react'
import moment from 'moment';

import TechnologyInput from '../../../components/inputs/TechnologyInput'
import DateInputCustom from '../../../components/inputs/DateInputCustom'
import CurrencyInput from '../../../components/inputs/CurrencyInput'

const ProjectCreateModal = ({ open, cancel, onCreate, loading }) => {
    const [errorDateStart, setErrorDateStart] = useState(false)
    const [errorDateEnd, setErrorDateEnd] = useState(false)
    const [data, setData] = useState({
        name: '',
        project_manager: false,
        budget: '',
        man_days: '',
        description: '',
        ends: moment().format("DD-MM-YYYY"),
        starts: moment().format("DD-MM-YYYY"),
        technologies: [],
        currency: 'EUR'
    })

    function handleConfirm() {
        onCreate(data)
    }
    const handleChange = (e, { name, value }) => setData((prevState) => ({ ...prevState, [name]: value }))
    function isAllFilled() {
        return (
            data.name !== '' &&
            data.budget !== '' &&
            data.man_days !== '' &&
            data.starts !== '' &&
            data.ends !== '') &&
            (data.technologies.length !== 0 || data.project_manager)
    }

    function validationMD() {
        if (parseInt(data.man_days) > 0 || data.man_days === '') return false
        return 'This number is not valid'
    }
    function budgetValidation() {
        if (parseInt(data.budget) > 0 || data.budget === '') return false
        return 'Budget is not valid'
    }
    function handleManager() {
        setData((prevState) => (
            {
                ...prevState,
                technologies: [],
                project_manager: !data.project_manager
            }))
    }

    return (
        <Modal
            open={open}
            onClose={cancel}
            size="tiny"
        >
            <Modal.Header style={{ textAlign: "center" }}>Create project</Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Column width={16}>
                        <span>Name:</span>
                        <Form.Input
                            fluid
                            value={data.name}
                            name="name"
                            onChange={handleChange} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <span>Planned start of project:</span>
                        <DateInputCustom input={data.starts} handler={(e) => setData((prevState) => ({ ...prevState, starts: e }))} errorHandle={(e) => setErrorDateStart(e)} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <span>Planned end of project:</span>
                        <DateInputCustom input={data.ends} handler={(e) => setData((prevState) => ({ ...prevState, ends: e }))} min={data.starts} errorHandle={(e) => setErrorDateEnd(e)} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <span>Budget:</span>
                        <Form.Input
                            fluid
                            value={data.budget}
                            name="budget"
                            onChange={handleChange}
                            error={budgetValidation()} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <CurrencyInput handler={(e) => setData((prevState) => ({ ...prevState, currency: e }))} />
                    </Grid.Column>

                    <Grid.Column computer={8} tablet={16} mobile={16} >
                        <span>Mandays:</span>
                        <Form.Input
                            type='number'
                            fluid
                            value={data.man_days}
                            name="man_days"
                            onChange={handleChange}
                            error={validationMD()} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16} style={{ display: data.project_manager && 'none' }}>
                        <span>Technologies:</span>
                        <TechnologyInput handler={(e) => setData((prevState) => ({ ...prevState, technologies: e }))} disabled={data.project_manager} />
                    </Grid.Column>

                    <Grid.Column computer={8} tablet={16} mobile={16} style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                        <Form.Field >
                            <Checkbox
                                style={{ marginRight: "0.5rem" }}
                                onChange={() => handleManager()}
                                checked={data.project_manager} />
                            <span>Project manager</span>
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <span>Description:</span>
                        <Form.TextArea
                            style={styles.input_column_text}
                            rows={5}
                            value={data.description}
                            onChange={handleChange}
                            name="description" /></Grid.Column>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button basic onClick={cancel} disabled={loading}>Cancel</Button>
                <Button
                    onClick={() => handleConfirm()}
                    disabled={!isAllFilled() || validationMD() || budgetValidation() || errorDateStart || errorDateEnd}
                    loading={loading}
                >Create</Button>
            </Modal.Actions>
        </Modal>
    )
}
const styles = {
    input_column_text: {
        display: "flex",
        width: "100%",
        height: "auto",
        borderColor: "var(--border)",
    },
}

export default ProjectCreateModal
