import React from 'react'
import { Grid } from 'semantic-ui-react'
import ActionButton from '../../../../components/buttons/ActionButton'
// import DateInputCustom from '../../../../components/inputs/DateInputCustom'

const Totals = ({ people, price, currency, confirm, reset, loading, premiumChange, premium, getValidUntil, valid }) => {
    return (
        <Grid style={{ background: 'var(--light)', padding: '1rem', }}>
            <Grid.Row style={{ justifyContent: 'space-between', padding: "0.5rem" }}>
                <div>Total people</div>
                <div style={{ paddingRight: '1rem' }}>{people}</div>
            </Grid.Row>
            <Grid.Row style={{ justifyContent: 'space-between', padding: "0.5rem" }}>
                <div>Total price</div>
                <div style={{ paddingRight: '1rem' }}>{price.toFixed(2)} {currency}</div>
            </Grid.Row>
            {/* <Grid.Row style={{ justifyContent: 'space-between', alignItems: 'center', padding: "0.5rem" }}>
                <span>Valid until</span>
                <DateInputCustom handler={(e) => getValidUntil(e)} input={''} />
            </Grid.Row> */}
            <Grid.Row style={{ justifyContent: 'space-between', padding: "0.5rem" }}>
                <ActionButton icon="trash" action={reset} disabled={loading} />
                <div>
                    {premium && "activated premium "}
                    {!premium && "no activated premium "}
                    {premium && <ActionButton icon="star" action={premiumChange} disabled={loading} />}
                    {!premium && <ActionButton icon="star" action={premiumChange} disabled={loading} basic />}
                    <ActionButton icon="checkmark" action={confirm} loading={loading} />
                </div>
            </Grid.Row>
        </Grid>
    )
}

export default Totals
