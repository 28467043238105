import React, { useState } from 'react'
import { Grid, Header } from 'semantic-ui-react'
import { Link } from "react-router-dom";

// routes
import { routes } from "../../../../store/routes/index";

//components
import RegistrationTypeCard from './RegistrationTypeCard'
import RegisterForm from './RegisterForm'

const Registration = () => {
    const [state, setState] = useState(0)
    /* 0 CHOOSING,1 ROOT, 2 PROVIDER, 3 CLIENT, 4 TIPPER, 5 TALENT */

    return (
        <Grid>
            <Grid.Row style={styles.header}>
                <Link to={routes.HOME}><img src="/images/hiringINIT_logo.svg" alt="logo" /></Link>
                <Header>REGISTRATION</Header>
            </Grid.Row>
            {state === 0 ?
                <>
                    <Grid.Row style={{ display: 'flex', justifyContent: "center", textAlign: 'center' }}><Header>Choose type of registration</Header></Grid.Row>
                    <Grid.Row style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <div onClick={() => setState(2)} style={{ margin: "1rem" }}>
                            <RegistrationTypeCard
                                name="Provider"
                                description="come on lets work together"
                                icon="building"
                            />
                        </div>
                        <div onClick={() => setState(3)} style={{ margin: "1rem" }}>
                            <RegistrationTypeCard
                                name="Client"
                                description="i have a great project to share with others"
                                icon="user circle outline"
                            />
                        </div>
                        <div onClick={() => setState(5)} style={{ margin: "1rem" }}>
                            <RegistrationTypeCard
                                name="Talent"
                                description="talent is always needed"
                                icon="star"
                            />
                        </div>
                    </Grid.Row>
                </>
                : <RegisterForm type={state} handler={() => setState(0)} />
            }
        </Grid>
    )
}
const styles = {
    header: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "var(--light)",
        flexDirection: "column",
        fontSize: "4rem",
        textAlign: "center"

    }
}

export default Registration
