import React from 'react'
import { Modal, Button } from 'semantic-ui-react'

function SuperModal(props) {
    // onClose = {func}
    // onOpen = {func}
    // open = {bool}
    // trigger = {node}
    // size = {enum} mini, tiny, small, large, fullscreen
    // className = {string}
    // header = {string}
    // content = {elementType}
    // onAccept = {func}
    // onDeny = {func}
    // positive = {bool} decider between true -> onAccept or false -> onDeny
    return (

        <Modal
            onClose={props.onClose}
            onOpen={props.onOpen}
            open={props.open}
            trigger={props.trigger}
            size={props.size ? props.size : 'mini'}
            className={props.className}
            style={{ textAlign: "center" }}

        >
            <Modal.Header>{props.header}</Modal.Header>
            <Modal.Content>{props.content}</Modal.Content>
            <Modal.Actions>
                <Button onClick={props.onClose} basic disabled={props.loading}>Close</Button>
                {props.positive
                    ? <Button onClick={props.onAccept} icon="checkmark" content="Accept" loading={props.loading} />
                    : <Button onClick={props.onDeny} icon="close" content="Reject" loading={props.loading} />
                }
            </Modal.Actions>

        </Modal>

    )
}

export default SuperModal