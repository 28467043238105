import React from 'react'
import { Button, Icon } from 'semantic-ui-react'

const ActionButton = ({ action, icon, circular, disabled, loading, basic }) => {
    return (
        <Button icon onClick={action} circular={circular} disabled={disabled} loading={loading} basic={basic}>
            <Icon name={icon} />
        </Button>
    )
}

export default ActionButton
