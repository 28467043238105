import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, Header } from 'semantic-ui-react'

import { dateService } from '../../../../store/services/custom/DateServices'

const HeaderReservation = ({ data }) => {
    const profile = useSelector(state => state.profile)
    function getTotalPrice() {
        let result = 0
        let new_data
        if (profile.user.role === 2) new_data = data.provider_selected_service_offers
        if (profile.user.role === 3) new_data = data.selected_service_offers
        new_data.filter(item => {
            result += (parseFloat(item.price_display))
            return item
        })
        return `${result} ${new_data[0].service_offer.currency}`
    }
    function getTotalPeople() {
        let result = 0
        let new_data
        if (profile.user.role === 2) new_data = data.provider_selected_service_offers
        if (profile.user.role === 3) new_data = data.selected_service_offers
        new_data.filter(item => {
            result += parseFloat(item.total_men)
            return item
        })
        return result
    }

    return (
        <Grid.Row style={{ padding: 0 }}>
            <Grid.Column computer={4} tablet={8} mobile={16}>
                <Header as='h2' style={styles.heading}>{dateService.convertDateWithDots(data.created_when)}<Header.Subheader>Created</Header.Subheader></Header>
            </Grid.Column>
            {!data.is_premium &&
                <Grid.Column computer={4} tablet={8} mobile={16} >
                    <Header as='h2' style={styles.heading}>{dateService.convertDateWithDots(data.valid_until)}<Header.Subheader>Valid until</Header.Subheader></Header>
                </Grid.Column>
            }
            <Grid.Column computer={4} tablet={8} mobile={16} >
                <Header as='h2' style={styles.heading}>{getTotalPrice()}<Header.Subheader>Total price</Header.Subheader></Header>
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16} >
                <Header as='h2' style={styles.heading}>{getTotalPeople()}<Header.Subheader>Total people</Header.Subheader></Header>
            </Grid.Column>
        </Grid.Row>
    )
}
const styles = {
    heading: {
        background: 'var(--light)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem',
        marginBottom: '1rem',
        borderColor: 'var(--border)',
        borderStyle: 'solid',
        borderWidth: 1
    },
}
export default HeaderReservation
