import React, { useState, useEffect } from 'react'
import { Grid, Tab, Menu, Label, Header } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'

import DetailHeader from './DetailHeader'
import CardSO from './CardSO'
import EditCreateService from '../services/EditCreateService'
import Loading from '../../../../components/general/Loading'

import { serviceService } from '../../../../store/services/services/ServicesService'
import { serviceOfferService } from '../../../../store/services/services/ServiceOfferService'
import EditService from '../services/EditService'

const ServiceDetail = () => {
    const [data, setData] = useState({
        service_offers: []
    })
    const params = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [editModal, setEditModal] = useState(false)
    const [editServiceModal, setEditServiceModal] = useState(false)
    const [editModalLoading, setEditModalLoading] = useState(false)
    const [activatingLoading, setActivatingLoading] = useState(false)

    useEffect(() => {
        async function fetchData() {
            let result = await serviceService.getServiceById(parseInt(params.id));
            if (result.status === 200) {
                setData(result.response)
            }
            setIsLoading(false)
        }
        fetchData();
    }, [params.id]);


    function rerender(input) {
        setData((prevState) => ({
            ...prevState,
            service_offers: prevState.service_offers.filter(item => {
                if (item.id === input.id) {
                    item.is_active = input.is_active
                }
                return item
            })
        }))
    }
    function updateEdit(input) {
        setData((prevState) => ({
            ...prevState,
            service_offers: prevState.service_offers.filter(item => {
                if (item.id === input.id) {
                    item.price = input.price
                    item.currency = input.currency
                    item.total_persons = input.total_persons
                    item.minimum_order = input.minimum_order
                    item.time_before_order = input.time_before_order
                    item.time_before_order_type = input.time_before_order_type
                    item.minimum_contract_length = input.minimum_contract_length
                    item.minimum_contract_length_type = input.minimum_contract_length_type
                    item.dedication = input.dedication
                    item.technology = input.technology
                    item.project_manager = input.project_manager
                }
                return item
            })
        }))
    }

    async function createNewOffer(input) {
        setEditModalLoading(true)
        let new_offer = {
            price: parseFloat(input.price).toFixed(2),
            currency: input.currency,
            total_persons: parseInt(input.total_persons),
            minimum_order: parseInt(input.minimum_order),
            time_before_order: parseInt(input.time_before_order),
            time_before_order_type: input.time_before_order_type,
            minimum_contract_length: parseInt(input.minimum_contract_length),
            minimum_contract_length_type: input.minimum_contract_length_type,
            dedication: input.dedication,
            technology: input.technology,
            project_manager: input.project_manager,
            is_active: true//POSSIBLY WILL CHANGE
        }
        let offer = await serviceOfferService.createServiceOffer(new_offer)
        let service = await serviceService.updateService(data.id, { service_offers: { add: [offer.response.id] } })

        setData(service.response)
        setEditModal(false)
        setEditModalLoading(false)
    }

    async function changeStateActive() {
        setActivatingLoading(true)
        let service = await serviceService.updateService(data.id, { is_active: !data.is_active })
        setData(service.response)
        setActivatingLoading(false)
    }

    async function updateService(input) {
        setEditModalLoading(true)
        let update = {
            name: input.name,
            category: input.category,
            description: input.description,
            is_outsourcing: input.is_outsourcing,
        }
        let service = await serviceService.updateService(data.id, update)
        setData(service.response)
        setEditModalLoading(false)
        setEditServiceModal(false)
    }

    const ListOfCardSOs = ({ data, active, rerender }) => {
        if (data.length === 0) return <Header as='h2' style={{ textAlign: "center", color: 'var(--variant3)' }}>No service offers here</Header>
        return data.map((item, key) => (
            <CardSO data={item} key={key} active={active} rerender={rerender} updateEditedOffer={(e) => updateEdit(e)} />
        ))
    }
    let panes = [
        {
            menuItem: (
                <Menu.Item key='Active'>
                    Active
                    <Label circular>{data.service_offers.filter(item => item.is_active === true).length}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane attached={false}>
                <ListOfCardSOs active data={data.service_offers.filter(item => item.is_active === true)} rerender={(e) => rerender(e)} />
            </Tab.Pane>,
        },
        {
            menuItem: (
                <Menu.Item key='Non-active'>
                    Non-active
                    <Label circular>{data.service_offers.filter(item => item.is_active === false).length}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane attached={false}>
                <ListOfCardSOs data={data.service_offers.filter(item => item.is_active === false)} rerender={(e) => rerender(e)} />
            </Tab.Pane>,
        }
    ]

    return (
        <Grid style={styles.container}>
            {isLoading ? <Loading /> :
                <>
                    <EditCreateService
                        open={editModal}
                        create
                        cancel={() => setEditModal(false)}
                        onEdit={(x) => createNewOffer(x)}
                        loading={editModalLoading}
                    />
                    <EditService
                        open={editServiceModal}
                        cancel={() => setEditServiceModal(false)}
                        data={data}
                        handler={(e) => updateService(e)}
                        loading={editModalLoading}
                    />
                    <DetailHeader data={data} edit={() => setEditServiceModal(true)} activate={() => changeStateActive()} offerCreate={() => setEditModal(true)} activatingLoading={activatingLoading} />
                    <Tab menu={{ secondary: true, pointing: true }} panes={panes} style={styles.tab} />
                </>
            }
        </Grid>
    )
}
const styles = {
    container: {
        width: "100%"
    },
    tab: {
        width: '100%',
    },
    yes: {
        color: 'var(--info)',
        fontWeight: 'bold'
    },
    no: {
        color: 'var(--danger)',
        fontWeight: 'bold'
    }
}
export default ServiceDetail