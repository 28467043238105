import React from 'react'
import { Grid, Button, Header, Icon, Card } from 'semantic-ui-react'
const ConfirmData = ({ data, previousStep, confirm, load }) => {
    const convertAdress = (type) => {
        let address
        if (type === 1) address = data.addresses.billing_address
        if (type === 2) address = data.addresses.shipping_address
        return address.street + ', ' + address.city + ' ' + address.postcode + ', ' + address.state + ', ' + address.country
    }
    const BankAccountCard = () => {
        return (
            <Card style={styles.card} centered>
                <Card.Content style={styles.card_head}>
                    <Card.Header>Bank account</Card.Header>
                </Card.Content>
                <Card.Content>
                    <b>{data.bank.account.name}</b>
                    <Card.Meta>{data.bank.account.alias}</Card.Meta>
                    <Card.Meta>{data.bank.bank_address.address_line}</Card.Meta>
                </Card.Content>
                <Card.Content>IBAN: <b>{data.bank.account.iban}</b></Card.Content>
                <Card.Content>SWIFT: <b>{data.bank.account.swift_code}</b></Card.Content>
                <Card.Content>
                    <Card.Description>{data.bank.account.bank_detail}</Card.Description>
                    <Card.Description>default currency: <b>{data.bank.currency}</b></Card.Description>
                </Card.Content>
            </Card>)
    }
    return (
        <Grid style={styles.container}>
            <Grid.Column mobile={16} tablet={8} computer={8} style={styles.row_column}>
                <Header><Icon name="user" size="large" />{data.person.titles_before} {data.person.firstname} {data.person.middlename} {data.person.lastname} {data.person.titles_after}</Header>
                <Header style={{ margin: 0 }}><Header.Subheader>Nace code</Header.Subheader>{`${data.business_detail.nace_code} - ${data.business_detail.nace_name}`}</Header>
                <Header style={{ margin: 0 }}><Header.Subheader>Vat number</Header.Subheader>{data.business_detail.vat_number}</Header>
                <Header style={{ margin: 0 }}><Header.Subheader>Business register folder</Header.Subheader>{data.business_detail.business_register_folder}</Header>
                <Header><Header.Subheader>Billing address</Header.Subheader>{convertAdress(1)}</Header>
                <Header><Header.Subheader>Shipping address</Header.Subheader>{convertAdress(2)}</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <BankAccountCard />
            </Grid.Column>
            <Button content='Back' onClick={previousStep} disabled={load} />
            <Button content='Confirm all' onClick={confirm} loading={load} />
        </Grid>
    )
}
const styles = {
    container: {
        display: 'flex',
        width: '100%'
    },
    row_column: {
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem'
    },
    card: {
        width: '100%'
    },
    card_head: {
        background: 'var(--variant5)'
    }
}
export default ConfirmData
