/* USER */
export const addUser = (user) => ({
    type: "ADD_USER",
    user,
});

export const logoutUser = () => ({
    type: "LOGOUT_USER",
});

/* PROFILE */
export const addProfile = (profile) => ({
    type: "ADD_PROFILE",
    profile,
})
export const updateProfileBusiness = (business_detail) => ({
    type: "UPDATE_PROFILE_BUSINESS",
    business_detail,
})

export const deleteProfile = () => ({
    type: "DELETE_PROFILE",
})
export const addPreferences = (preferences) => ({
    type: "ADD_PREFERENCES",
    preferences,
})

export const deletePreferences = () => ({
    type: "DELETE_PREFERENCES",
})
export const addBundlesToProfile = (bundle) => ({
    type: "ADD_BUNDLE",
    bundle
})
export const changeUsername = (username) => ({
    type: "CHANGE_USERNAME",
    username
})