const dashboard = "/dashboard/"; // this is main prefix for dashboard pages

export const routes = {
    // Public
    HOME: "/",

    // Auth/Users
    LOGIN: "/login/",
    REGISTRATION: '/registration/',
    TIPPER_REGISTER: '/registration-tipper/',
    ACTIVATION: '/activation/',
    PASSWORD_RESET: "/password-reset",

    // Dashboard
    DASHBOARD: dashboard,

    // User Profile
    PROFILE: dashboard + "profile/",
    CLIENT_PROFILE: dashboard + 'profile/client/',
    PROVIDER_PROFILE: dashboard + 'profile/provider/',
    TALENT_PROFILE: dashboard + 'profile/talent/',
    TIPPER_PROFILE: dashboard + 'profile/tipper/',
    SETTINGS: dashboard + 'settings/',

    //Admin
    REQUESTS: dashboard + "requests/",
    USERS: dashboard + 'users/',
    TECHNOLOGIES: dashboard + 'technologies/',
    BUNDLES_MANAGEMENT: dashboard + 'bundles-management/',

    //Client
    PROJECTS: dashboard + "projects/",
    PROJECTS_DETAIL: dashboard + "projects/detail/",
    BUNDLES: dashboard + 'bundles/',

    //Provider
    SERVICES: dashboard + 'services/',
    SERVICE_DETAIL: dashboard + 'services/detail/',
    SERVICE_OFFERS: dashboard + 'offers/',

    //Tipper
    RECOMMEND: dashboard + 'recommend/',
    RECOMMENDATIONS: dashboard + 'recommendations/',

    //Client + Provider
    RESERVATIONS: dashboard + 'reservations/',
    ORDERS: dashboard + 'orders/'
}