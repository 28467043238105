import React, { useState, useEffect } from 'react'
import { Grid, Form, Divider } from 'semantic-ui-react'
import GoogleSearchAddress from './GoogleSearchAddress'
import { googleSearchService } from '../../store/services/common/GoogleSearchService'
import { preferencesService } from '../../store/services/common/PreferencesService'
import { connect, useSelector } from "react-redux";
import { addPreferences } from '../../store/actions/index'

const AddressForm = (props) => {
    const [countryOptions, setCountryOptions] = useState([])
    const [loading, setLoading] = useState(false)
    const storedChoices = useSelector(state => state.preferences)

    const [address, setAddress] = useState(
        props.data ? props.data : {
            street: '',
            city: '',
            state: '',
            postcode: '',
            country: '',
            address_line: '',
            latitude: 0,
            longitude: 0,
        })

    useEffect(() => {
        async function fetchData() {
            if (storedChoices.length === 0) {
                setLoading(true)
                let result = await preferencesService.getChoices()
                if (result.status === 200) {
                    props.dispatch(addPreferences(result.response))
                }
            }
            setCountryOptions(toObjectOptions(Object.entries(storedChoices.countries)))
            setLoading(false)
        }
        fetchData()
        if (!props.data) {
            if (address.street !== ''
                || address.city !== ''
                || address.state !== ''
                || address.country !== ''
                || address.postcode !== '') {
                props.handleData(address)
                return
            }
        }
        if (props.data) {
            if (address.street !== props.data.street
                || address.city !== props.data.city
                || address.state !== props.data.state
                || address.country !== props.data.country
                || address.postcode !== props.data.postcode) {
                props.handleData(address)
            }
        }
        if (props.disabled) setAddress(props.data)
    }, [address, props.data, props.disabled, props.handleData, props, storedChoices])

    function toObjectOptions(input) {
        let array = []
        let result = input.map((item, id) => (
            array[id] = { key: id, value: item[0], text: item[1] }))
        return result
    }

    function handleAddress(e, { name, value }) {
        let street = address.street
        let city = address.city
        let postcode = address.postcode
        let state = address.state
        let country = address.country
        if (name === 'street') street = value
        if (name === 'city') city = value
        if (name === 'postcode') postcode = value
        if (name === 'state') state = value
        if (name === 'country') country = value
        let line = `${street}, ${city} ${postcode}, ${state}, ${country}`
        setAddress((prevState) => ({
            ...prevState,
            [name]: value,
            address_line: line
        }))
    }
    function handleGoogleData(data_From_Google) {
        if (!data_From_Google) {
            return
        }
        let google_data = data_From_Google.address_components
        setAddress((prevState) =>
        ({
            ...prevState,
            street: googleSearchService.getAddress(google_data) ? googleSearchService.getAddress(google_data) : '',
            city: googleSearchService.getCity(google_data) ? googleSearchService.getCity(google_data) : '',
            state: googleSearchService.getState(google_data) ? googleSearchService.getState(google_data) : '',
            postcode: googleSearchService.getPostalCode(google_data) ? googleSearchService.getPostalCode(google_data) : '',
            country: googleSearchService.getCountry(google_data) ? googleSearchService.getCountry(google_data) : '',
            address_line: googleSearchService.getAddressLine(data_From_Google) ? googleSearchService.getAddressLine(data_From_Google) : getOtherAddressLine(google_data)
        }))
    }
    function LatLngSet({ lat, lng }) {
        if (lat && lng) {
            setAddress((prevState) => ({ ...prevState, latitude: lat }))
            setAddress((prevState) => ({ ...prevState, longitude: lng }))
        }
    }

    function getOtherAddressLine(google_data) {
        return (
            googleSearchService.getAddress(google_data) + ', ' +
            googleSearchService.getCity(google_data) + ' ' +
            googleSearchService.getPostalCode(google_data) + ', ' +
            googleSearchService.getState(google_data) + ', ' +
            googleSearchService.getCountry(google_data)
        )
    }


    return (
        <Grid style={{ width: "100%" }}>
            {props.title && <Divider horizontal>{props.title}{props.title ? ' address' : ''}</Divider>}
            <Grid.Row>
                <Grid.Column width={16}>
                    <GoogleSearchAddress disabled={props.disabled} handleData={(data_new) => handleGoogleData(data_new)} handleLatLng={(data_new) => LatLngSet(data_new)} />
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <span>Street</span>{!props.notRequired && <span style={{ color: 'var(--danger)' }}>*</span>}
                    <Form.Input
                        disabled={props.disabled}
                        fluid
                        placeholder="Enter street"
                        value={address.street}
                        name="street"
                        onChange={handleAddress} />
                </Grid.Column >
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <span>City</span>{!props.notRequired && <span style={{ color: 'var(--danger)' }}>*</span>}
                    <Form.Input
                        disabled={props.disabled}
                        fluid
                        placeholder="Enter city"
                        value={address.city}
                        name="city"
                        onChange={handleAddress} />
                </Grid.Column >
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <span>State</span>
                    <Form.Input
                        disabled={props.disabled}
                        fluid
                        placeholder="Enter state"
                        value={address.state}
                        name="state"
                        onChange={handleAddress} />
                </Grid.Column >
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <span>Postcode</span>{!props.notRequired && <span style={{ color: 'var(--danger)' }}>*</span>}
                    <Form.Input
                        disabled={props.disabled}
                        fluid
                        placeholder="Enter postcode"
                        value={address.postcode}
                        name="postcode"
                        onChange={handleAddress} />
                </Grid.Column >
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <span>Country</span>{!props.notRequired && <span style={{ color: 'var(--danger)' }}>*</span>}
                    <Form.Select
                        disabled={loading || props.disabled}
                        style={{ borderRadius: 0, width: '100%' }}
                        placeholder='Select your country'
                        options={countryOptions}
                        value={address.country}
                        onChange={(e) => setAddress((prevState) => ({ ...prevState, country: e.target.value }))} />
                </Grid.Column >
            </Grid.Row>
        </Grid>
    )
}

export default connect()(AddressForm)
