import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { routes } from '../../store/routes/index';
import { authService } from '../../store/services/ServiceAuth';
import Loading from '../general/Loading';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [currentPath] = useState(window.location.pathname);

    useEffect(() => {
        async function checkUser() {
            const result = await authService.validateToken();
            setIsLoading(false);

            if (result === false) {
                history.push(routes.LOGIN);
            }
        };

        checkUser();
    }, [history, currentPath]);


    return (
        <>
            { !isLoading ?
                <Route {...rest} render={props => (
                    <Component {...props} />
                )} />
                : <Loading />}
        </>
    );
};

export default PrivateRoute;