import React, { useState } from 'react'
import { Button, Grid, Form, Header } from 'semantic-ui-react'

//services
import { isEmpty, isPasswordError, isRepeatPasswordError } from '../../../store/services/helpers/validation'
import { userService } from '../../../store/services/users/ServiceUser'

import ResponseModal from '../../../components/modals/ResponseModal'

const PasswordChange = ({ container }) => {
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [repPassword, setRepPassword] = useState("")
    const [response, setResponse] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const updateNewPassword = async () => {
        setLoading(true)
        let data = {
            current_password: oldPassword,
            new_password: newPassword,
        }
        let result = await userService.setPassword(data)
        if (result.status === 204) {
            setResponseMessage('Password was changed successfully.')
        } else {
            setResponseMessage(result.response.new_username ? result.response.new_username : "Wrong password")
        }
        setResponse(true)
        resetAll()
        setLoading(false)
    }
    function errorPassword(input) {
        if (input) {
            return true
        }
        return false
    }
    function resetAll() {
        setOldPassword('')
        setNewPassword('')
        setRepPassword('')
    }
    return (
        <Grid style={container}>
            <ResponseModal
                open={response}
                onClose={() => setResponse(false)}
                header={'Changing password'}
                message={responseMessage}
                onClick={() => setResponse(false)}
            />
            <Grid.Column width={16}>
                <Header>Password change</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <span>Your new password</span>
                <Form.Input
                    fluid
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    error={
                        !isEmpty(newPassword)
                            ? isPasswordError(newPassword)
                            : false
                    }
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <span>Repeat your new password</span>
                <Form.Input
                    fluid
                    type="password"
                    value={repPassword}
                    onChange={(e) => setRepPassword(e.target.value)}
                    error={
                        isRepeatPasswordError(newPassword, repPassword)
                            ? isRepeatPasswordError(newPassword, repPassword)
                            : false
                    }
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <span>Your current password</span>
                <Form.Input
                    fluid
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                />
            </Grid.Column>
            <Grid.Column width={16}>
                <Button
                    content="Save new password"
                    onClick={() => updateNewPassword()}
                    loading={loading}
                    disabled={
                        isEmpty(oldPassword) ||
                        errorPassword(isPasswordError(newPassword)) ||
                        errorPassword(isRepeatPasswordError(newPassword, repPassword))
                    }
                />
            </Grid.Column>
        </Grid>

    )
}
export default PasswordChange
