import React from 'react'
import { Button } from 'semantic-ui-react'
import { Link } from "react-router-dom";

// routes
import { routes } from "../../../store/routes/index";
const SectionFour = () => {
    return (
        <div style={styles.container}>
            <div>
                <p className="text-inc" style={styles.header}>
                    There's a better way to<br />
                    use your potential.</p>
                <p>Apply today to code tomorrow</p>
                <Link to={routes.REGISTRATION}>
                    <Button style={{ display: "flex", width: "100%", justifyContent: "center", padding: "1.8rem 2rem", marginTop: "2rem", fontSize: 18 }} content="REGISTER NOW" />
                </Link>
            </div>
        </div>
    )
}
const styles = {
    container: {
        width: "100%",
        backgroundColor: "var(--light)",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        padding: "3%"
    },
    header: {
        fontSize: 24,
        fontWeight: "bold",
        marginBottom: "0.6rem"
    },
}
export default SectionFour
