import { APIRequest } from '../helpers/requests';
import { API } from '../../config';


export const bundlesService = {
    getBundles,
    addBundle,
    updateBundle,
    deleteBundle,
    buyBundle,
    getUsedBundles,
    getUsedBundle,
    postUsedBundle,
}
async function getBundles() {
    return await APIRequest({
        url: API.BUNDLES,
        method: 'GET',
        data: {},
        private: true
    });
}
async function addBundle(data) {
    return await APIRequest({
        url: API.BUNDLES,
        method: 'POST',
        data: data,
        private: true
    });
}
async function buyBundle(id) {
    return await APIRequest({
        url: API.BUNDLES + 'buy/' + id,
        method: 'POST',
        data: {},
        private: true
    });
}
async function updateBundle(id, data) {
    return await APIRequest({
        url: API.BUNDLES + id,
        method: 'PATCH',
        data: data,
        private: true
    });
}
async function deleteBundle(id) {
    return await APIRequest({
        url: API.BUNDLES + id,
        method: 'DELETE',
        data: {},
        private: true
    });
}
async function getUsedBundles() {
    return await APIRequest({
        url: API.USED_BUNDLES,
        method: 'GET',
        data: {},
        private: true
    });
}
async function getUsedBundle(id) {
    return await APIRequest({
        url: API.USED_BUNDLES + id,
        method: 'GET',
        data: {},
        private: true
    });
}
async function postUsedBundle(data) {
    return await APIRequest({
        url: API.USED_BUNDLES,
        method: 'POST',
        data: data,
        private: true
    });
}