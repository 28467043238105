import React from 'react'
import { Grid } from 'semantic-ui-react'
import DividerHead from '../../../../components/general/DividerHead'
import InfoRow from '../../../../components/general/InfoRow'

const ContactDetails = ({ data }) => {

    return (

        <Grid style={styles.container}>
            <DividerHead title="CONTACT" />
            <Grid.Row style={{ padding: 0 }}>
                <Grid>
                    <InfoRow name="EMAIL" value={data.user.email} />
                    <InfoRow name="PHONE" value={data.phone} />
                    {data.business_detail && <InfoRow name="BILLING ADDRESS" value={data.business_detail.billing_address ? data.business_detail.billing_address.address_line : ''} />}
                    {data.business_detail && <InfoRow name="SHIPPING ADDRESS" value={data.business_detail.shipping_address ? data.business_detail.shipping_address.address_line : ''} />}
                </Grid>
            </Grid.Row>
        </Grid>

    )
}
const styles = {
    container: {
        padding: "1rem",
        borderColor: "var(--border)",
        borderStyle: "solid",
        borderWidth: 1,
        boxShadow: "1px 1px 2px #686868c7",
        height: "100%",
        backgroundColor: 'var(--white)',
    },
    headding: {
        display: "flex",
        justifyContent: 'space-between',
    },
}
export default ContactDetails