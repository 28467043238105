import React, { useState, useEffect } from 'react'
import { Modal, Button, Grid, Form, Checkbox } from 'semantic-ui-react'
import TechnologyInput from '../../../components/inputs/TechnologyInput'
import DateInputCustom from '../../../components/inputs/DateInputCustom'
import CurrencyInput from '../../../components/inputs/CurrencyInput'
import { isNumberError } from '../../../store/services/helpers/validation'
import { dateService } from '../../../store/services/custom/DateServices'
const ProjectEditModal = ({ open, cancel, onSave, data, loading }) => {
    const [errorDateStart, setErrorDateStart] = useState(false)
    const [errorDateEnd, setErrorDateEnd] = useState(false)
    const [edit, setEdit] = useState(data ? data : {
        name: '',
        project_manager: false,
        budget: '',
        man_days: '',
        description: '',
        starts: '',
        ends: '',
        technologies: [],
        currency: 'EUR'
    })

    useEffect(() => {
        if (data) setEdit(data)
    }, [data])

    function isChanged() {
        if (data.name === edit.name &&
            data.project_manager === edit.project_manager &&
            data.budget === edit.budget &&
            data.man_days === edit.man_days &&
            data.description === edit.description &&
            data.starts === edit.starts &&
            data.ends === edit.ends &&
            data.technologies === edit.technologies
        ) return false
        return true
    }

    const handleChange = (e, { name, value }) => setEdit((prevState) => ({ ...prevState, [name]: value }))
    const handleTechnologies = (e) => setEdit((prevState) => ({ ...prevState, technologies: e }))
    function validationMD() {
        if (parseInt(edit.man_days) > 0 || edit.man_days === '') return false
        return 'This number is not valid'
    }
    function budgetValidation() {
        if (parseFloat(edit.budget) > 0 || edit.budget === '') return false
        return 'Budget is not valid'
    }
    function saveAll() {
        onSave(edit)
    }
    return (
        <Modal
            open={open}
            size="tiny"
            onClose={cancel}
        >
            <Modal.Header style={{ textAlign: "center" }}>Edit project</Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Column width={16}><span>Name:</span><Form.Input fluid value={edit.name} name="name" onChange={handleChange} /></Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <span>Planned start of project:</span>
                        <DateInputCustom input={dateService.rotateDate(edit.starts)} handler={(e) => setEdit((prevState) => ({ ...prevState, starts: e }))} errorHandle={(e) => setErrorDateStart(e)} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <span>Planned end of project:</span>
                        <DateInputCustom input={dateService.rotateDate(edit.ends)} handler={(e) => setEdit((prevState) => ({ ...prevState, ends: e }))} errorHandle={(e) => setErrorDateEnd(e)} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <span>Budget:</span>
                        <Form.Input fluid value={edit.budget} name="budget" onChange={handleChange} error={budgetValidation() || isNumberError(edit.budget)} /></Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <CurrencyInput handler={(e) => setEdit((prevState) => ({ ...prevState, currency: e }))} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <span>Mandays:</span>
                        <Form.Input value={edit.man_days} fluid name="man_days" type='number' onChange={handleChange} error={validationMD()} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16} style={{ display: edit.project_manager && 'none' }}>
                        <span>Technologies:</span>
                        <TechnologyInput handler={handleTechnologies} income={data.technologies} />
                    </Grid.Column>

                    <Grid.Column computer={8} tablet={16} mobile={16} style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                        <Form.Field style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                            <Checkbox
                                style={{ marginRight: "0.5rem" }}
                                onChange={() => setEdit((prevState) => ({ ...prevState, project_manager: !edit.project_manager, technologies: [] }))}
                                checked={edit.project_manager} /><span>Project manager</span>
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <span>Description:</span>
                        <Form.TextArea
                            style={styles.input_column_text}
                            rows={5}
                            value={edit.description}
                            onChange={handleChange}
                            name="description" /></Grid.Column>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button basic onClick={cancel} disabled={loading}>Cancel</Button>
                <Button onClick={() => saveAll()}
                    disabled={
                        !isChanged() ||
                        validationMD() ||
                        errorDateStart ||
                        errorDateEnd ||
                        budgetValidation() ||
                        isNumberError(edit.budget) ||
                        (edit.technologies.length === 0 && !edit.project_manager)
                    }
                    loading={loading}
                >Save</Button>
            </Modal.Actions>
        </Modal>
    )
}
const styles = {
    input_column_text: {
        display: "flex",
        width: "100%",
        height: "auto",
        borderColor: "var(--border)",
    },
    multiselect: {
        searchBox: { // To change search box element look
            borderRadius: 0
        },
        chips: { // To change css chips(Selected options)
            background: 'var(--primary)'
        },
        optionContainer: { // To change css for option container 
            borderRadius: 0
        }
    }
}

export default ProjectEditModal
