import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Header, Button, Grid, Form } from 'semantic-ui-react'

import { isEmpty, isEmailError, isPhoneError } from '../../../store/services/helpers/validation'
import { userService } from '../../../store/services/users/ServiceUser'

const UpdateContact = ({ container, update, modal }) => {
    const [loading, setLoading] = useState(false)
    const profile = useSelector(state => state.profile)

    const [contact, setContact] = useState(
        {
            phone: profile.phone,
            email: profile.user.email
        }
    )

    const handleConact = (e, { name, value }) => setContact((prevState) => ({ ...prevState, [name]: value }))

    const updateContact = async () => {
        let res1
        let res2
        setLoading(true)
        if (contact.email !== profile.user.email) res1 = await userService.updateUserUser({ email: contact.email })
        if (contact.phone !== profile.phone) res2 = await userService.updateProfile(profile.id, { phone: contact.phone })
        update(res2, res1)
        modal({
            open: true,
            message: 'Your change was successful.',
            icon: 'checkmark',
            header: 'Change'
        })
        setLoading(false)
    }
    function phoneErrorConverted() {
        if (isPhoneError(contact.phone)) return true
        return false
    }
    function changedSomething() {
        if (profile.phone !== contact.phone || profile.user.email !== contact.email) return true
        return false
    }

    return (
        <Grid style={container}>
            <Grid.Column width={16}>
                <Header>Update contact</Header>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <span>Email</span>
                <Form.Input
                    name="email"
                    fluid
                    value={contact.email}
                    onChange={handleConact}
                    error={
                        !isEmpty(contact.email)
                            ? isEmailError(contact.email)
                            : false
                    }
                />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <span>Phone</span>
                <Form.Input
                    name="phone"
                    fluid
                    placeholder="+123456123456"
                    value={contact.phone}
                    onChange={handleConact}
                    error={
                        !isEmpty(contact.phone)
                            ? isPhoneError(contact.phone)
                            : false
                    }
                />
            </Grid.Column>
            <Grid.Column width={16}>
                <Button
                    content="Save changes"
                    onClick={() => updateContact()}
                    disabled={phoneErrorConverted() || !changedSomething()}
                    loading={loading} />
            </Grid.Column>
        </Grid>
    )
}
export default UpdateContact
