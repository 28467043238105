import React, { Fragment, useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'

const TimeTypeInput = ({ handler, loading, data }) => {
    const [time, setTime] = useState(data ? data : 0)
    const time_type_options = [
        { key: 1, value: 1, text: 'minute/s' },
        { key: 2, value: 2, text: 'hour/s' },
        { key: 3, value: 3, text: 'day/s' },
        { key: 4, value: 4, text: 'weeks/s' },
        { key: 5, value: 5, text: 'month/s' },
    ]
    const handleTime = (e, { value }) => {
        handler(value)
        setTime(value)
    }
    useEffect(() => {
        setTime(data ? data : 0)
    }, [data])
    return (
        <Fragment>
            <span>Time type</span>
            <Form.Select
                disabled={loading}
                style={{ borderRadius: 0, width: '100%' }}
                placeholder='Time type'
                options={time_type_options}
                value={time}
                onChange={handleTime} />

        </Fragment>
    )
}

export default TimeTypeInput
