import React, { useState, useEffect } from 'react'
import { Multiselect } from 'multiselect-react-dropdown'
import { technologyService } from '../../store/services/common/TechnologyService'

const TechnologyInput = ({ handler, income, disabled }) => {

    const [data, setData] = useState(income ?? [])
    const [options, setOptions] = useState([])
    useEffect(() => {
        async function fetch() {
            let result = await technologyService.getTechnologies()
            setOptions(result.response)
        }
        fetch()
        if (disabled) {
            setData([])
        }
    }, [disabled])
    function onSelect(selectedList) {
        handler(selectedList)
        setData(selectedList)
    }
    function onRemove(selectedList) {
        handler(selectedList)
        setData(selectedList)
    }

    return (
        <Multiselect
            disable={disabled}
            options={options} // Options to display in the dropdown
            selectedValues={data} // Preselected value to persist in dropdown
            onSelect={onSelect} // Function will trigger on select event
            onRemove={onRemove} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
            closeOnSelect={false}
            style={styles.multiselect}
        />
    )
}
const styles = {
    multiselect: {
        searchBox: { // To change search box element look
            borderRadius: 0
        },
        chips: { // To change css chips(Selected options)
            background: 'var(--primary)'
        },
        optionContainer: { // To change css for option container 
            borderRadius: 0
        }
    }
}

export default TechnologyInput
