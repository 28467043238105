// import React, { Fragment, useEffect, useState } from 'react'
import React, { Fragment, useState } from 'react'
import { Form } from 'semantic-ui-react'
// import { preferencesService } from '../../store/services/common/PreferencesService'

const CategoryInput = ({ handler, data }) => {
    // const [currencyOptions, setCurrencyOptions] = useState([])
    const category_options = [
        { key: 1, value: 1, text: 'Mobile App' },
        { key: 2, value: 2, text: 'Web App' },
        { key: 3, value: 3, text: 'Desktop App' },
        { key: 4, value: 4, text: 'Security' },
        { key: 5, value: 5, text: 'IDK' },
        { key: 6, value: 6, text: 'IDK else' },
    ]
    const [category, setCategory] = useState(null)
    // const [loading, setLoading] = useState(true)

    const handleCategory = (e, { value }) => {
        handler(value)
        setCategory(value)
    }
    // useEffect(() => {
    //     async function fetchData() {
    //         let result = await preferencesService.getChoices();
    //         if (result.status === 200) {
    //             setCurrencyOptions(toObjectOptions(result.response.currency_codes))
    //             setLoading(false);
    //         }
    //         handler(currency)

    //     }
    //     fetchData()
    //     return () => (currencyOptions)
    // }, [handler, currency, currencyOptions])

    // function toObjectOptions(input) {
    //     let array = []
    //     let result = input.map((item, id) => (
    //         array[id] = { key: id, value: item[0], text: item[1] }))
    //     return result
    // }
    return (
        <Fragment>
            <span>Category</span>
            <Form.Select
                style={{ borderRadius: 0, width: '100%' }}
                placeholder='Select your category'
                options={category_options}
                value={data ?? category}
                onChange={handleCategory} />

        </Fragment>
    )
}

export default CategoryInput
