import React, { useState, useEffect } from 'react'
import { Modal, Button, Grid, Form, Checkbox } from 'semantic-ui-react'
import CurrencyInput from '../../../../components/inputs/CurrencyInput'
import TechnologySingleSelect from '../../../../components/inputs/TechnologySingleSelect'
import { isEmpty, isNumberError, errorPercentage, isNumberErrorWhole } from '../../../../store/services/helpers/validation'
const time_types = [
    { key: 1, value: 1, text: 'minute/s' },
    { key: 2, value: 2, text: 'hour/s' },
    { key: 3, value: 3, text: 'day/s' },
    { key: 4, value: 4, text: 'week/s' },
    { key: 5, value: 5, text: 'month/s' },
]
const contract_length_type = [
    { key: 1, value: 1, text: 'minute/s' },
    { key: 2, value: 2, text: 'hour/s' },
    { key: 3, value: 3, text: 'day/s' },
    { key: 4, value: 4, text: 'week/s' },
    { key: 5, value: 5, text: 'month/s' },
]

const EditService = ({ open, cancel, onEdit, data, editing, create, loading }) => {
    const [edit, setEdit] = useState({
        price: data ? data.price : '',
        currency: data ? data.currency : 'EUR',
        total_persons: data ? data.total_persons : '',
        time_before_order: data ? data.tbo_display : '',
        time_before_order_type: data ? data.time_before_order_type : 3,
        minimum_order: data ? data.minimum_order : '',
        minimum_contract_length: data ? data.mcl_display : 0,
        minimum_contract_length_type: data ? data.minimum_contract_length_type : 3,
        dedication: data ? data.dedication : '',
        technology: data ? data.technology : 0,
        project_manager: data ? data.project_manager : false
    })
    const [change, setChange] = useState(false)
    const handleEdit = (e, { name, value }) => setEdit(prevState => ({ ...prevState, [name]: value }))
    const handleChangeCheckbox = () => setEdit(prevState => ({ ...prevState, project_manager: !edit.project_manager }))
    useEffect(() => {
        if (!create
            && data.price === edit.price
            && data.currency === edit.currency
            && data.total_persons === edit.total_persons
            && data.time_before_order === edit.time_before_order
            && data.time_before_order_type === edit.time_before_order_type
            && data.minimum_contract_length === edit.minimum_contract_length
            && data.minimum_contract_length_type === edit.minimum_contract_length_type
            && data.minimum_order === edit.minimum_order
            && data.dedication === edit.dedication
            && data.technology === edit.technology
            && data.project_manager === edit.project_manager
        ) setChange(false)
        else setChange(true)
    }, [data, edit, create])

    function allNotFilled() {
        if (
            edit.price === '' ||
            edit.total_persons === '' ||
            edit.time_before_order === '' ||
            edit.minimum_contract_length === '' ||
            edit.minimum_order === '' ||
            edit.dedication === '' ||
            edit.technology === 0 ||
            isNumberError(parseFloat(edit.price)) ||
            isNumberErrorWhole(edit.total_persons) ||
            isNumberErrorWhole(edit.time_before_order) ||
            isNumberErrorWhole(edit.minimum_contract_length) ||
            isNumberErrorWhole(edit.minimum_order) ||
            errorPercentage(edit.dedication) ||
            minimumCantBeHigherThanTotal()
        ) return true
        return false
    }
    function minimumCantBeHigherThanTotal() {
        if (isNumberErrorWhole(edit.minimum_order)) return isNumberErrorWhole(edit.minimum_order)
        if (parseInt(edit.total_persons) < parseInt(edit.minimum_order)) return "Minimum order can't be higher than total."
        return false
    }
    return (
        <Modal
            open={open}
            size='tiny'
        >
            <Modal.Header style={{ textAlign: "center", color: 'var(--white)' }}>
                {editing && "Edit"}{create && "Create"} service offer
            </Modal.Header>
            <Modal.Content>
                <Grid>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <span>Price</span>
                        <Form.Input fluid value={edit.price} name="price" onChange={handleEdit} error={!isEmpty(edit.price) && isNumberError(edit.price)} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <CurrencyInput data={edit.currency} handler={(e) => setEdit(prevState => ({ ...prevState, currency: e }))} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <span>Total people</span>
                        <Form.Input fluid value={edit.total_persons} name="total_persons" onChange={handleEdit} error={!isEmpty(edit.total_persons) && isNumberErrorWhole(edit.total_persons)} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <span>Minimum order</span>
                        <Form.Input fluid value={edit.minimum_order} name="minimum_order" onChange={handleEdit} error={!isEmpty(edit.minimum_order) && minimumCantBeHigherThanTotal()} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <span>Time before order</span>
                        <Form.Input fluid value={edit.time_before_order} name="time_before_order" onChange={handleEdit} error={!isEmpty(edit.time_before_order) && isNumberErrorWhole(edit.time_before_order)} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <span>Time type</span>
                        <Form.Select
                            style={{ borderRadius: 0 }}
                            fluid
                            value={edit.time_before_order_type}
                            name="time_before_order_type"
                            onChange={handleEdit}
                            options={time_types} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <span>Minimum contract length</span>
                        <Form.Input
                            fluid
                            value={edit.minimum_contract_length}
                            name="minimum_contract_length"
                            onChange={handleEdit}
                            error={!isEmpty(edit.minimum_contract_length) &&
                                isNumberErrorWhole(edit.minimum_contract_length)} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <span>Time type</span>
                        <Form.Select
                            style={{ borderRadius: 0 }}
                            fluid
                            value={edit.minimum_contract_length_type}
                            name="minimum_contract_length_type"
                            onChange={handleEdit}
                            options={contract_length_type} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <span>Dedication</span>
                        <Form.Input fluid value={edit.dedication} name="dedication" onChange={handleEdit} error={!isEmpty(edit.dedication) && errorPercentage(edit.dedication)} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <span>Technology</span>
                        <TechnologySingleSelect handler={(e) => setEdit(prevState => ({ ...prevState, technology: e }))} data={edit.technology.id} />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <Form.Field style={{ display: 'flex', alignItems: "center", marginTop: '1.5rem' }}>
                            <Checkbox
                                style={{ marginRight: "0.5rem" }}
                                onChange={handleChangeCheckbox}
                                checked={edit.project_manager} /><span>Project manager</span>
                        </Form.Field>
                    </Grid.Column>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button basic onClick={cancel} disabled={loading}>Cancel</Button>
                <Button
                    loading={loading}
                    disabled={allNotFilled() || !change}
                    onClick={() => onEdit(edit)}
                >{editing && "Save"}{create && "Create"}</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default EditService
