import React from 'react';
import { useHistory } from 'react-router-dom';

import { Segment, Sidebar, Icon, Grid, Header, Button, Divider } from 'semantic-ui-react';
import QuickAccessLink from './QuickAccessLink';

const HorizontalSidebar = ({ visible, close }) => {
    let history = useHistory();

    const moveToLink = (to) => {
        close();
        history.push(to);
    }

    return (
        <Sidebar
            as={Segment}
            animation="overlay"
            direction="top"
            visible={visible}
        >
            <Grid stretched>
                <Grid.Column textAlign="left" computer={14} tables={14} mobile={16}>
                    <Header as='h3' style={{ paddingTop: "1rem" }} content="Quick Access"/>
                </Grid.Column>
                <Grid.Column textAlign="right" computer={2} tables={4} mobile={16}>
                    <Button size="tiny" onClick={close}>
                        <Icon name="remove"/> Close
                    </Button>
                </Grid.Column>
            </Grid>

            <Divider/>

            <Grid stackable stretched columns={6}>
                <QuickAccessLink 
                    to={() => moveToLink('/dashboard/')}
                    image="/images/icons/teams.svg"
                    text="Create an Employee"
                />
                <QuickAccessLink 
                    to={() => moveToLink('/dashboard/')}
                    image="/images/icons/contacts.svg"
                    text="Create new Account"
                />
                <QuickAccessLink 
                    to={() => moveToLink('/dashboard/')}
                    image="/images/icons/teams.svg"
                    text="Create new Team"
                />
                <QuickAccessLink 
                    to={() => moveToLink('/dashboard/')}
                    image="/images/icons/projects.svg"
                    text="Create new Project"
                />
                <QuickAccessLink 
                    to={() => moveToLink('/dashboard/employees/')}
                    image="/images/icons/settings.svg"
                    text="Create WhateverYouWant"
                />
                <QuickAccessLink 
                    to={() => moveToLink('/dashboard/employees/')}
                    image="/images/icons/settings.svg"
                    text="Create WhateverYouWant-2"
                />
            </Grid>
        </Sidebar>
    );
};

export default HorizontalSidebar;