import React, { Fragment, useEffect, useState } from 'react'
import { Divider, Grid, Header } from 'semantic-ui-react'
import Loading from '../../../components/general/Loading'
import { bundlesService } from '../../../store/services/bundles/BundlesService'
import BundleCard from './BundleCard'

const Bundles = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        async function fetch() {
            let result = await bundlesService.getBundles()
            setData(result.response.filter((item => item.is_active === true)))
            setLoading(false)
        }
        fetch()
    }, [])

    const ListOfBundles = () => {
        return data.map((item, key) => (
            <Grid.Column key={key} mobile={16} tablet={8} computer={4} style={{ marginBottom: '1rem' }}>
                <BundleCard data={item} />
            </Grid.Column>
        ))
    }


    return (
        <Fragment>
            {loading ?
                <Loading />
                :
                <Grid>
                    <Grid.Row>
                        <Header as="h1" style={{ color: 'var(--primary)', marginLeft: '1rem' }}>
                            Bundles
                            <Header.Subheader>
                                Here you can buy any bundle you like, that makes the price of your future hiring better.
                            </Header.Subheader>
                        </Header>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row>
                        <ListOfBundles />
                    </Grid.Row>
                </Grid>
            }
        </Fragment>
    )
}

export default Bundles
