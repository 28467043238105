import React from 'react';
import { Grid, Progress } from 'semantic-ui-react';

// components
import GraphSegment from './GraphSegment';
import GraphTotalSection from './GraphTotalsSection';

function GraphStatistics1() {

    return (
        <GraphSegment
            header="Statistics 1"
            content={
                <Grid columns={4}>
                    <Grid.Row>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <GraphTotalSection
                                headerColor="blue"
                                header="EARNINGS (MONTHLY)"
                                detail="$40,000"
                                icon="calendar"
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <GraphTotalSection
                                headerColor="green"
                                header="EARNINGS (ANNUAL)"
                                detail="$215,000"
                                icon="dollar"
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <GraphTotalSection
                                headerColor="yellow"
                                header="TASKS"
                                detail={
                                    <Progress percent={50} progress color='yellow' style={{ margin: 0 }} />
                                }
                                icon="clipboard list"
                            />
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={8} computer={4}>
                            <GraphTotalSection
                                headerColor="pink"
                                header="PENDING REQUESTS"
                                detail="18"
                                icon="wechat"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        />
    );
}

export default GraphStatistics1
