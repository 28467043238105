import React, { Fragment, useEffect, useState } from 'react'
import { Button, Form, Grid, Modal, Header } from 'semantic-ui-react'
import DividerHead from '../../../components/general/DividerHead'
import BundleSelect from '../../../components/inputs/BundleSelect'
import { isNumberErrorWhole } from '../../../store/services/helpers/validation'

//CREATE EDIT SELECTED SERVICE OFFER
const CreateEditSSO = ({ open, onClose, onEdit, onCreate, type, input, edit, selected }) => {
    const [data, setData] = useState(null)
    const [number, setNumber] = useState(edit ? edit.total : 0)
    const [bundleSelected, setBundleSelected] = useState(null)

    useEffect(() => {
        setData(input)
        if (selected && selected.lenght > 0) {
            for (let i in input) {
                if (input.id === selected[i]?.data.id) {
                    for (let j in selected) {
                        if (input.active_bundles[j].id === selected[i].bundle) {
                            setBundleSelected(input.active_bundles[i])
                        }
                    }
                }

            }
        }
        if (edit) return setNumber(edit.total)
        if (input) return setNumber(input.minimum_order)
    }, [input, edit, selected])


    function convertTypeToText(number, type) {
        if (type === 1) return number + " min"
        if (type === 2) return number + " H"
        if (type === 3) return number + " D"
        if (type === 4) return number + " W"
        if (type === 5) return number + " M"
        return '-'
    }
    function checkLeftPeople(input) {
        if (data) {
            let result = data.people_left - input
            if (input < data.minimum_order) return data.people_left
            if (result === 0) return 0
            if (result < 0) return "Cannot order more than total"
            if (isNumberErrorWhole(result)) return data.people_left
            return result
        }
        return "error"
    }
    function checkPrice() {
        if (data) {
            let result = parseFloat(data.price_display) * number
            if (bundleSelected) {
                result = parseFloat(result) * ((100 - parseFloat(bundleSelected.discount)) / 100)
                result = result.toFixed(2)
                return (
                    <div>
                        <span style={{ fontSize: '2rem' }}>{result}</span>
                        <span style={{ textDecoration: 'line-through', color: 'var(--variant3)' }}>{(parseFloat(data.price_display) * number).toFixed(2)}</span>
                    </div>)
            }
            return result.toFixed(2)
        }
        return "error"
    }
    function errorNumberInput(input) {
        if (data) {
            if (input < 0) return false
            if (input === 0) return "You have to reserve at least one person"
            if (input < data.minimum_order) return "You have to take more people"
            if (input > data.people_left) return "You can't take more than total"
            return false
        }
        return "error"
    }
    function onClosingModal() {
        if (input) setNumber(input.minimum_order)
        if (edit) setNumber(edit.total)
        setBundleSelected(null)
        onClose()
    }
    function checkIfOwnsBundle() {
        if (!data) return false
        if (data.active_bundles.length > 0) return true
        return false
    }
    function handleBundleSelect(e) {
        // setLoadingBundle(true)
        let array = data.active_bundles.filter(item => item.id === e)
        setBundleSelected(array[0])
        // let result = await bundlesService.postUsedBundle({
        //     total_men: number,
        //     selected_service_offer: 94,
        //     used_bundle: array[0].id
        // })
        // setLoadingBundle(false)
    }
    function handlePriceChange(price, currency) {
        let result_price = price
        let result_currency = currency
        if (bundleSelected) {
            result_price = parseFloat(price) * ((100 - parseFloat(bundleSelected.discount)) / 100)
            result_price = result_price.toFixed(2)
        }
        return `${result_price} ${result_currency}`
    }
    function handleCreate() {
        let result_price = data.price_display
        if (bundleSelected) {
            result_price = parseFloat(data.price_display) * ((100 - parseFloat(bundleSelected.discount)) / 100)
            result_price = result_price.toFixed(2)
        }
        onCreate({
            data: data,
            total: parseInt(number),
            price: result_price * parseInt(number),
            bundle: bundleSelected ? bundleSelected.id : null,
            price_eur: parseFloat(data.price),
            currency: data.currency
        })
        setBundleSelected(null)
    }
    function handleEdit() {
        let result_price = data.price_display
        if (bundleSelected) {
            result_price = parseFloat(data.price_display) * ((100 - parseFloat(bundleSelected.discount)) / 100)
            result_price = result_price.toFixed(2)
        }
        onEdit({
            data: data,
            total: parseInt(number),
            price: result_price * parseInt(number),
            bundle: bundleSelected ? bundleSelected.id : null,
            price_eur: parseFloat(data.price),
            currency: data.currency
        })
        setBundleSelected(null)
    }


    return (
        <Fragment>
            {data &&
                <Modal
                    open={open}
                    onClose={onClose}
                    size='mini'
                >
                    <Modal.Content>
                        <Grid columns={2} centered>
                            <DividerHead title={data.technology.name} />
                            {checkIfOwnsBundle() &&
                                <BundleSelect
                                    input={bundleSelected ? bundleSelected.id : null}
                                    data={data.active_bundles.filter(item => item.technology === data.technology.id)}
                                    handler={(e) => handleBundleSelect(e)} />
                            }
                            <Grid.Column><Header>{convertTypeToText(data.mcl_display, data.minimum_contract_length_type)}<Header.Subheader>Minimum contract length</Header.Subheader></Header></Grid.Column>
                            <Grid.Column><Header>{convertTypeToText(data.tbo_display, data.time_before_order_type)}<Header.Subheader>Time before order</Header.Subheader></Header></Grid.Column>
                            <Grid.Column><Header>{handlePriceChange(data.price_display, data.currency)}<Header.Subheader>Price per person</Header.Subheader></Header></Grid.Column>
                            <Grid.Column><Header>{data.project_manager ? "yes" : 'no'}<Header.Subheader>Project managers</Header.Subheader></Header></Grid.Column>
                            <Grid.Column><Header>{checkLeftPeople(number)}<Header.Subheader>People left</Header.Subheader></Header></Grid.Column>
                            <Grid.Column><Header>{data.minimum_order}<Header.Subheader>Minimum order</Header.Subheader></Header></Grid.Column>
                            <Grid.Column>
                                <Form.Input
                                    fluid
                                    type="number"
                                    onChange={(e) => setNumber(e.target.value)}
                                    value={number}
                                    error={errorNumberInput(parseInt(number)) || isNumberErrorWhole(number)}
                                />
                                <Header style={{ margin: 0 }}>
                                    <Header.Subheader>People</Header.Subheader>
                                </Header>
                            </Grid.Column>
                            <Grid.Column><Header>{checkPrice()} {data.currency}<Header.Subheader>Total price</Header.Subheader></Header></Grid.Column>
                        </Grid>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button content="Cancel" basic onClick={() => onClosingModal()} />
                        {type === 'create' &&
                            <Button
                                content='Confirm'
                                onClick={() => handleCreate()}
                                disabled={(errorNumberInput(parseInt(number)) ? true : false) || (isNumberErrorWhole(number) ? true : false)} />}
                        {type === 'edit' &&
                            <Button
                                content='Update'
                                onClick={() => handleEdit()}
                                disabled={(errorNumberInput(parseInt(number)) ? true : false) || (isNumberErrorWhole(number) ? true : false)} />}
                    </Modal.Actions>
                </Modal>
            }
        </Fragment>
    )
}

export default CreateEditSSO
