import React, { useState } from "react";
// store
import { authService } from "../../../store/services/ServiceAuth";
import { isEmpty } from "../../../store/services/helpers/validation";
// router
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

// routes
import { routes } from "../../../store/routes/index";

// design
import {
    Button,
    Form,
    Grid,
    Header,
    Icon,
    Modal,
    Segment,
    Container,
    Image,
} from "semantic-ui-react";
import ResetPassword from "./ResetPassword";

// component
function LoginForm(props) {
    const { i18n } = useTranslation();
    // state
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
    const [errorMessage] = useState("Username or Password is invalid!");
    const [isProcessing, setIsProcessing] = useState(false);
    let history = useHistory();


    const handleLogin = async (event) => {
        event.preventDefault();
        setIsProcessing(true);

        // validate form fields
        let formError = false;

        if (!formError) {
            const result = await authService.login(
                username,
                password,
                props.dispatch,
                i18n
            );

            if (result.status !== 200) {
                handleErrorOpen();
            } else {
                history.push(routes.DASHBOARD);
                return;
            }
        }

        setIsProcessing(false);
    };

    const handleErrorOpen = () => {
        setErrorOpen(true);
    };

    const handleErrorClose = () => {
        setErrorOpen(false);
    };

    // template
    return (
        <Container>
            <Link to={routes.HOME}>
                <Image centered src="/images/hiringINIT_logo.svg" />
            </Link>
            <Grid centered>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Form onSubmit={handleLogin}>
                        <Segment>
                            <h1 style={{ textAlign: "center" }}>Log in</h1>
                            <Form.Group>
                                <Form.Input
                                    icon="user"
                                    iconPosition="left"
                                    width={16}
                                    size="large"
                                    required
                                    autoFocus
                                    fluid
                                    label="Username"
                                    placeholder="Username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    error={
                                        !isEmpty(username)
                                            ? false
                                            : false
                                    }
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Input
                                    icon="lock"
                                    iconPosition="left"
                                    width={16}
                                    size="large"
                                    required
                                    fluid
                                    type="password"
                                    label="Password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                            </Form.Group>

                            <Header as="h5" style={{ cursor: 'pointer' }} onClick={() => setForgotPasswordModal(true)}>
                                Did you forget your password?
                                <Modal
                                    open={forgotPasswordModal}
                                    onClose={() => setForgotPasswordModal(false)}
                                    size="mini">
                                    <ResetPassword onClose={() => setForgotPasswordModal(false)} />
                                </Modal>
                            </Header>

                            <div style={{ width: "100%" }}>
                                <Form.Button
                                    fluid primary
                                    size="huge"
                                    type="submit"
                                    loading={isProcessing}
                                    disabled={
                                        isProcessing ||
                                        isEmpty(username) ||
                                        isEmpty(password)
                                    }
                                    style={{ margin: "0.5rem auto" }}
                                >
                                    Login
                                </Form.Button>
                            </div>
                            <Header as="h5" style={{ margin: '1rem 0' }} >
                                <Link to={routes.REGISTRATION}>
                                    Don't you have account? Register now!
                                </Link>
                            </Header>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>

            <Modal open={errorOpen} onClose={handleErrorClose} size="mini">
                <Header icon="warning circle" content="Error occured" />
                <Modal.Content>
                    <h3>{errorMessage}</h3>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="red" onClick={handleErrorClose} inverted>
                        <Icon name="checkmark" /> Ok
                    </Button>
                </Modal.Actions>
            </Modal>
        </Container>
    );
}

export default connect()(LoginForm);
