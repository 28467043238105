import moment from 'moment';

const dateFormat = "DD-MM-YYYY"
const apiDateFormat = "YYYY-MM-DD"
const months = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export const dateService = {
    dateToMY,
    dateToMY_to_String,
    convertDateWithDots,
    daysLeft,
    convertToMomentDate,
    getToday,
    formatDateForApi,
    rotateDate
};

function dateToMY(date) {
    //let d = date.getDate();
    let m = date.getMonth() + 1; //Month from 0 to 11
    let y = date.getFullYear();
    return (m <= 9 ? '0' + m : m) + '/' + y;
}

function dateToMY_to_String(date) {
    const splitted_date = date.split("/");
    let month = splitted_date[0];
    const year = splitted_date[1];

    if (month.startsWith("0")) {
        // remove 0 from string
        month = month.substring(1);
    }

    return months[parseInt(month - 1)] + ' ' + year;
}

function convertDateWithDots(input) {
    let m = moment(input).format(dateFormat)
    let a = m.split("-")
    return a[0] + "." + a[1] + "." + a[2]
}
function rotateDate(input) {
    let a = input.split("-")
    return a[2] + "-" + a[1] + "-" + a[0]
}
function daysLeft(input) {
    let end = moment(new Date(), dateFormat)
    let today = moment(new Date(moment(input, dateFormat).format()))
    return today.diff(end, 'days')
}
function convertToMomentDate(input) {
    return moment(input)
}
function getToday() {
    return moment()
}
function formatDateForApi(input) {
    return moment(input).format(apiDateFormat)
}