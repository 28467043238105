import React, { useEffect, useState } from 'react'
import { Button, Grid, Header } from 'semantic-ui-react'
import Loading from '../../../../components/general/Loading'
import ResponseModal from '../../../../components/modals/ResponseModal'
import { bundlesService } from '../../../../store/services/bundles/BundlesService'
import BundleTable from './BundleTable'
import CreateEditModal from './modals/CreateEditModal'
import ShowModal from './modals/ShowModal'

const BundlesManagement = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [processing, setProcessing] = useState(false)
    const [openCreate, setOpenCreate] = useState(false)
    const [item, setItem] = useState({})
    const [openShow, setOpenShow] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)

    useEffect(() => {
        async function fetchData() {
            let result = await bundlesService.getBundles()
            if (result.status === 200) setData(result.response)
            setLoading(false)
        }
        fetchData()
    }, [])
    async function createBundle(e) {
        setProcessing(true)
        let result = await bundlesService.addBundle({
            name: e.name,
            currency: e.currency,
            discount: parseFloat(e.discount),
            duration: parseInt(e.duration_display),
            duration_type: parseInt(e.duration_type),
            man_day_limit: parseInt(e.man_day_limit),
            price: parseFloat(e.price),
            technology: parseInt(e.technology),
            is_active: e.is_active
        })
        if (result.status === 201) addNewBundleToList(result.response)
        setOpenCreate(false)
        setProcessing(false)
    }
    async function updateBundle(e) {
        setProcessing(true)
        let result = await bundlesService.updateBundle(e.id, {
            name: e.name,
            currency: e.currency,
            discount: parseFloat(e.discount),
            duration: parseInt(e.duration_display),
            duration_type: parseInt(e.duration_type),
            man_day_limit: parseInt(e.man_day_limit),
            price: parseFloat(e.price),
            technology: parseInt(e.technology),
            is_active: e.is_active
        })
        if (result.status === 200) updateBundleInList(result.response)
        setProcessing(false)
        setOpenEdit(false)
    }
    async function deleteBundle(e) {
        setProcessing(true)
        let result = await bundlesService.deleteBundle(e.id, {
            name: e.name,
            currency: e.currency,
            discount: parseFloat(e.discount),
            duration: parseInt(e.duration),
            duration_type: parseInt(e.duration_type),
            man_day_limit: parseInt(e.man_day_limit),
            price: parseFloat(e.price),
            technology: parseInt(e.technology),
            is_active: e.is_active
        })
        if (result.status === 204) deleteBundleFromList(item)
        setProcessing(false)
        setOpenDelete(false)
    }
    function addNewBundleToList(input) { setData(data.concat([input])) }
    function updateBundleInList(input) {
        let array = data.filter(item => item.id !== input.id)
        array = array.concat([input])
        setData(array)
    }
    function deleteBundleFromList(input) { setData(data.filter(item => item.id !== input.id)) }
    function onEdit(e) {
        setItem(e)
        setOpenEdit(true)
    }
    function onShow(e) {
        setItem(e)
        setOpenShow(true)
    }
    function onDelete(e) {
        setItem(e)
        setOpenDelete(true)
    }
    return (
        <Grid style={styles.container}>
            <CreateEditModal create open={openCreate} onClose={() => setOpenCreate(false)} onCreate={(e) => createBundle(e)} loading={processing} />
            <CreateEditModal data={item} edit open={openEdit} onClose={() => setOpenEdit(false)} onEdit={(e) => updateBundle(e)} loading={processing} />
            <ShowModal data={item} open={openShow} onClose={() => setOpenShow(false)} />
            <ResponseModal
                icon="trash"
                header='Delete'
                message={`Do you really want to delete ${item.name}?`}
                onClick={() => deleteBundle(item)}
                actionButton="Remove"
                cancel
                open={openDelete}
                loading={processing}
                onClose={() => setOpenDelete(false)} />
            <Grid.Row style={styles.header}>
                <Header as='h1' style={{ color: 'var(--primary)', marginLeft: '1rem' }}>
                    Bundles management
                    <Header.Subheader>Manage bundles</Header.Subheader>
                </Header>
                <div>
                    <Button content='Add bundle' icon="plus" onClick={() => setOpenCreate(true)} />
                </div>
            </Grid.Row>
            {loading ?
                <Loading />
                :
                <Grid.Row style={{ padding: 0, }}>
                    <BundleTable
                        data={data}
                        onEdit={(e) => onEdit(e)}
                        onShow={(e) => onShow(e)}
                        onDelete={(e) => onDelete(e)}
                        loading={processing} />
                </Grid.Row>
            }
        </Grid>
    )
}
const styles = {
    container: {
        padding: '1rem'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 0
    }
}


export default BundlesManagement
