import React from 'react'
import { Search } from 'semantic-ui-react'
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";

export default function GoogleSearchAddress(props) {
    /*
    -------------------------DATA EXAMPLE-------------------------
    const [address, setAddress] = useState({
        street: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
        address_line: '',
        latitude: '',
        longitude: '',
    })

    -------------------------COMPONENT EXAMPLE-------------------------
    <GoogleSearchAddress handleData={(data_new) => handleData(data_new)} handleLatLng={(data_new) => LatLngSet(data_new)} />

    -------------------------FUNCTIONS TO GET ADDRESSES LATITUDE AND LONGITUDE-------------------------
    function handleData(data_From_Google) {
        if (!data_From_Google) {
            return
        }
        let google_data = data_From_Google.address_components
        setAddress((prevState) =>
        ({
            ...prevState,
            street: googleSearchService.getAddress(google_data),
            city: googleSearchService.getCity(google_data),
            postcode: googleSearchService.getPostalCode(google_data),
            country: googleSearchService.getCountry(google_data),
            address_line: googleSearchService.getAddressLine(data_From_Google)
        }))
    }
    function LatLngSet({ lat, lng }) {
        if (lat && lng) {
            setAddress((prevState) => ({ ...prevState, latitude: lat }))
            setAddress((prevState) => ({ ...prevState, longitude: lng }))
        }
    }
    */

    const {
        ready,
        value,
        suggestions: { data },
        setValue,
    } = usePlacesAutocomplete()

    const handleInput = (e) => {
        setValue(e.target.value);
    }

    const handleDatas = (newData) => {
        setValue(newData.description, false);
        getGeocode({ address: newData.description })
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => { props.handleLatLng({ lat, lng }) })
            .catch((error) => { console.log("😱 Error: ", error) })
        getGeocode({ address: newData.description })
            .then((results) => props.handleData(results[0]))
            .catch((error) => { console.log("😱 Error: ", error) })
    }
    return (
        <Search
            className="google-search-bar"
            style={{ width: "100%" }}
            loading={!ready}
            value={value}
            onSearchChange={handleInput}
            placeholder="Enter your full address"
            noResultsMessage="No address found"
            onResultSelect={(e, new_data) => handleDatas(new_data.result)}
            results={data}
            disabled={props.disabled}
        />
    );
}
