import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Checkbox, Form, Grid, Header } from 'semantic-ui-react'
import ResponseModal from '../../../../components/modals/ResponseModal'
import { routes } from '../../../../store/routes'
import { isEmpty, isEmailError, isPhoneError, isEmptyErrorList } from '../../../../store/services/helpers/validation'
import { registerService } from '../../../../store/services/users/RegistrationService'

const TipperRegister = () => {
    const [data, setData] = useState({
        email: '',
        phone: '',
        message: '',
        name: '',
        id: '',
        dit: '',
        checked: false,
    })
    const history = useHistory()
    const requiredItems = [data.email, data.phone, data.id, data.dit, data.checked, data.name]
    const [isProcessing, setIsProcessing] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    function handleForm(e, { name, value }) { setData((prevState) => ({ ...prevState, [name]: value })) }
    function handleCheck() { setData((prevState) => ({ ...prevState, checked: !data.checked })) }

    async function registerTipper() {
        setIsProcessing(true)
        const new_tipper = {
            phone: data.phone,
            email: data.email,
            role: 4,
            company_name: data.name,
            identification_number: data.id,
            dit_number: data.dit,
            language: 1,
            message: data.message,
        }
        let result = await registerService.registerUser(new_tipper)
        if (result.status !== 201) {
            if (result.response.email) {
                setErrorMessage("Email address is taken");
            } else {
                setErrorMessage(result.response.message)
            }
            setErrorOpen(true)
        } else {
            history.push(routes.HOME);
        }
        setIsProcessing(false)

    }
    return (
        <Grid centered style={styles.container}>
            <ResponseModal
                open={errorOpen}
                onClose={() => setErrorOpen(false)}
                icon="warning circle"
                header="Error"
                message={errorMessage}
                onClick={() => setErrorOpen(false)}
            />
            <Grid.Row style={styles.headding}>
                <Link to={routes.HOME}><img src="/images/hiringINIT_logo.svg" alt="logo" /></Link>
                <Header as='h1' style={styles.header}>TIPPER REGISTRATION</Header>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column mobile={10} tablet={10} computer={5}>
                    <span>EMAIL</span><span style={styles.required}>*</span>
                    <Form.Input
                        placeholder='Email'
                        name='email'
                        value={data.email}
                        fluid
                        onChange={handleForm}
                        error={
                            !isEmpty(data.email)
                                ? isEmailError(data.email)
                                : false
                        }
                    />
                </Grid.Column>
                <Grid.Column mobile={10} tablet={10} computer={5}>
                    <span>PHONE</span><span style={styles.required}>*</span>
                    <Form.Input
                        placeholder='+132456789123'
                        name='phone'
                        value={data.phone}
                        fluid
                        onChange={handleForm}
                        error={
                            !isEmpty(data.phone)
                                ? isPhoneError(data.phone)
                                : false
                        }
                    />
                </Grid.Column>
                <Grid.Column width={10}>
                    <span>BUSINESS NAME</span><span style={styles.required}>*</span>
                    <Form.Input
                        placeholder='BUSINESS NAME'
                        name='name'
                        value={data.name}
                        fluid
                        onChange={handleForm}
                    />
                </Grid.Column>
                <Grid.Column width={10}>
                    <span>ID</span><span style={styles.required}>*</span>
                    <Form.Input
                        placeholder='ID'
                        name='id'
                        value={data.id}
                        fluid
                        onChange={handleForm}
                    />
                </Grid.Column>
                <Grid.Column width={10}>
                    <span>DIT</span><span style={styles.required}>*</span>
                    <Form.Input
                        placeholder='DIT'
                        name='dit'
                        value={data.dit}
                        fluid
                        onChange={handleForm}
                    />
                </Grid.Column>
                <Grid.Column width={10}>
                    <span>REQUEST</span>
                    <Form.TextArea
                        style={styles.input_column_text}
                        rows={4}
                        placeholder="Write down your request message."
                        name="message"
                        onChange={handleForm}
                        value={data.message}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 0 }}>
                <Form.Field style={{ display: 'flex', alignItems: "center" }}>
                    <Checkbox
                        style={{ marginRight: "0.5rem" }}
                        onChange={() => handleCheck()}
                        checked={data.checked}
                    />
                    <span>I agree to the <Link to={"#"} style={styles.conditions}>Terms and Conditions</Link></span>
                    <span style={styles.required}>*</span>
                </Form.Field>
            </Grid.Row>
            <Grid.Column width={10}>
                <Button
                    onClick={() => registerTipper()}
                    content="REGISTER"
                    style={{ width: "100%" }}
                    disabled={
                        isProcessing ||
                        !isEmptyErrorList(requiredItems) ||
                        !data.checked
                    }
                    loading={isProcessing}
                />
            </Grid.Column>
        </Grid>
    )
}
const styles = {
    container: { background: 'var(--variant1)', color: 'var(--white)', height: '100vh' },
    image: { filter: " brightness(0) invert(1)", },
    header: { color: 'var(--white)', fontSize: '2.5rem', margin: 0 },
    headding: { display: 'flex', alignItems: "center", flexDirection: 'column' },
    required: { color: "var(--danger)", fontWeight: "bold" },
    input_column_text: { display: "flex", width: "100%", height: "auto", borderColor: "var(--border)", },
    conditions: { fontWeight: "bold", textDecoration: "underline" }
}
export default TipperRegister
