import React from 'react'
import { Grid, Header, Button } from 'semantic-ui-react'
import BackButton from '../../../../components/buttons/BackButton'
import DividerHead from '../../../../components/general/DividerHead'

const DetailHeader = ({ data, edit, offerCreate, activate, activatingLoading }) => {
    function getCategory(cat) {
        if (cat === 1) return 'Mobile App'
        if (cat === 2) return 'Web App'
        if (cat === 3) return 'Desktop App'
        if (cat === 4) return 'Security App'
        if (cat === 5) return 'IDK App'
        if (cat === 6) return 'IDK else App'
        return 'none'
    }
    return (
        <Grid style={styles.container}>
            <BackButton content="Back to list" />

            <Grid.Row columns={2}>
                <Grid.Column >
                    <Header style={{ color: 'var(--primary)', fontSize: '2rem' }}>{data.name}</Header>
                </Grid.Column>
                <Grid.Column style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button content="Edit" onClick={edit} icon="pencil" />
                    <Button content="Create offer" onClick={offerCreate} icon="plus" />
                    {data.is_active && <Button content="Deactivate" onClick={activate} loading={activatingLoading} icon="caret down" />}
                    {!data.is_active && <Button content="Activate" onClick={activate} loading={activatingLoading} icon="caret up" />}
                </Grid.Column>
            </Grid.Row>
            <DividerHead title='Service detail' />
            <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <p>
                        {data.is_outsourcing
                            ? <span style={styles.yes}>outsourcing</span>
                            : <span style={styles.no}>not outsourcing</span>
                        }
                    </p>

                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    uuid: <b>{data.uuid}</b>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <span>categories: {getCategory(data.category)}</span>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    {data.description}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
const styles = {
    container: {
        width: "100%"
    },
    yes: {
        color: 'var(--info)',
        fontWeight: 'bold'
    },
    no: {
        color: 'var(--danger)',
        fontWeight: 'bold'
    }
}
export default DetailHeader