import React from 'react'
import { Grid } from 'semantic-ui-react'
import ListOfSSO from './ListOfSSO'
import Totals from './Totals'

const Basket = ({ data, currency, confirm, reset, removeOffer, loading, premiumChange, premium, getValidUntil, valid }) => {

    function getPrice() {
        let price = 0
        data.map(item => {
            price += parseFloat(item.price)
            return item
        })
        return price
    }
    function getPeople() {
        let people = 0
        data.map(item => {
            people += parseFloat(item.total)
            return item
        })
        return people
    }
    return (
        <Grid columns={2} style={{ width: "100%", margin: 0, padding: 0 }}>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <ListOfSSO data={data} removeOffer={removeOffer} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <Totals
                    price={getPrice()}
                    people={getPeople()}
                    currency={currency}
                    confirm={confirm}
                    reset={reset}
                    loading={loading}
                    premiumChange={premiumChange}
                    premium={premium}
                // getValidUntil={getValidUntil}
                // valid={valid}
                />
            </Grid.Column>
        </Grid>
    )
}

export default Basket
