import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";

// components
import Loading from "../../../components/general/Loading";
import { Grid, Header } from "semantic-ui-react";
import OrdersTable from "./OrdersTable";

// others
import { orderService } from "../../../store/services/orders/OrderServices";


const Orders = () => {
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(true)

    const profile = useSelector(state => state.profile)

    useEffect(() => {
        (async function () {
            let result
            if (profile.user.role === 2) result = await orderService.getProviderOrders()
            if (profile.user.role === 3) result = await orderService.getClientOrders()
            if (result.status === 200)
                setOrders(result.response)


            setLoading(false)
        })()
    }, [profile.user.role])


    return (
        <Grid>
            {loading
                ? <Loading />
                : <>
                    <Grid.Row style={{ padding: '1rem' }}>
                        <Header as='h1' style={{ color: 'var(--primary)' }}>
                            Orders
                            <Header.Subheader>Check your recent orders.</Header.Subheader>
                        </Header>
                    </Grid.Row>
                    <Grid.Row>
                        <OrdersTable data={orders} />
                    </Grid.Row>
                </>
            }
        </Grid>
    )
}

export default Orders
