import React, { useState } from 'react'
import { Grid, Popup, Icon, Table, Segment } from 'semantic-ui-react'

const OfferTable = ({ data, onReserve, selected, onEdit, loading }) => {
    const [chosen, setChosen] = useState(0)

    function getTotalCapacity(input) {
        let result = 0
        input.service_offers.map((item) => (result += item.total_persons * 8 * item.dedication / 100))
        return result
    }
    function getCapacity(input) {
        return input.people_left * 8 * input.dedication / 100
    }
    function getOverallMTBO(input) {
        let index = 0
        let lowest_number = 0
        input.map((item, key) => {
            if (lowest_number > (parseFloat(item.tbo_display))) index = key
            return item
        })

        return convertTypeToText(input[index].tbo_display, input[index].time_before_order_type)
    }
    function getMTBO(input) {
        return convertTypeToText(input.tbo_display, input.time_before_order_type)
    }
    function getOverallMCL(input) {
        let index = 0
        let lowest_number = 0
        input.map((item, key) => {
            if (lowest_number > parseFloat(item.mcl_display)) index = key
            return item
        })

        return convertTypeToText(input[index].mcl_display, input[index].minimum_contract_length_type)
    }
    function getMCL(input) {
        return convertTypeToText(input.mcl_display, input.minimum_contract_length_type)
    }
    function convertTypeToText(number, type) {
        if (type === 1) return number + "min"
        if (type === 2) return number + "H"
        if (type === 3) return number + "D"
        if (type === 4) return number + "W"
        if (type === 5) return number + "M"
        return '-'
    }
    function getMMDO(input) {
        let array = input.map(item => item.minimum_order)
        return Math.min(...array)
    }
    function formatToIntervalRatePrices(input) {
        if (input.length === 1) return parseFloat(input[0].price_display)
        let array_of_prices = input.map(item => parseFloat(item.price_display))
        let lowest = Math.min(...array_of_prices)
        let highest = Math.max(...array_of_prices)
        if (lowest === highest) return lowest
        return lowest + " - " + highest

    }
    function findIfAlreadySelected(id) {
        let result = false
        selected.filter(item => {
            if (item.data.id === id) result = true
            return item
        })
        return result
    }
    function ListAllDatas() {
        return data.map((item, key) => (
            <Table.Body key={key}>
                <Table.Row
                    style={chosen !== key + 1 ? styles.row : styles.row_active}
                    className={(chosen !== key + 1) ? "service-offer-row" : ''}>
                    <Table.Cell>{key + 1}.</Table.Cell>
                    <Table.Cell>{item.uuid}</Table.Cell>
                    <Table.Cell>{item.name}</Table.Cell>
                    <Table.Cell>{getTotalCapacity(item)}</Table.Cell>
                    <Table.Cell>{getOverallMTBO(item.service_offers)}</Table.Cell>
                    <Table.Cell>{getOverallMCL(item.service_offers)}</Table.Cell>
                    <Table.Cell>{getMMDO(item.service_offers)}</Table.Cell>
                    <Table.Cell>{formatToIntervalRatePrices(item.service_offers)}</Table.Cell>
                    <Table.Cell >
                        {chosen !== key + 1
                            ? <Icon name="chevron down" style={{ cursor: 'pointer' }} onClick={() => setChosen(key + 1)} />
                            : <Icon name="chevron circle up" style={{ cursor: 'pointer' }} onClick={() => setChosen(0)} />
                        }
                    </Table.Cell>
                </Table.Row>
                {chosen === key + 1 && <ListChosenAddings input={item.service_offers} id={item.id} />}
            </Table.Body>
        ))
    }
    function ListChosenAddings({ input, id }) {
        return input.map((item, key) => (
            <Table.Row
                style={styles.adding}
                key={key + 100}
                className="service-offer-row"
            >
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell >{item.technology.name}({item.people_left})</Table.Cell>
                <Table.Cell >{getCapacity(item)}</Table.Cell>
                <Table.Cell>{getMTBO(item)}</Table.Cell>
                <Table.Cell>{getMCL(item)}</Table.Cell>
                <Table.Cell>{item.minimum_order}</Table.Cell>
                <Table.Cell >{parseFloat(item.price_display)} {item.currency}</Table.Cell>
                <Table.Cell >
                    {findIfAlreadySelected(item.id)
                        ? <Icon.Group style={{ cursor: 'pointer', color: 'var(--primary)' }} onClick={() => onEdit(item, id)}>
                            <Icon name="calendar" />
                            <Icon corner='bottom right' name='checkmark' style={{ color: 'var(--black)' }} />
                        </Icon.Group>
                        : <Icon name="calendar" style={{ cursor: item.people_left !== 0 && 'pointer' }} onClick={() => onReserve(item, id)} disabled={item.people_left === 0} />}
                </Table.Cell>
            </Table.Row>
        ))
    }
    const Heading = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>id</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell><span>Capacity(MD)</span><Popup content='Mandays' trigger={<Icon name="info circle" color="grey" />} /></Table.HeaderCell>
                <Table.HeaderCell><span>MTBO</span><Popup content='Minimal time before order' trigger={<Icon name="info circle" color="grey" />} /></Table.HeaderCell>
                <Table.HeaderCell><span>MCL</span><Popup content='Minimal contract length' trigger={<Icon name="info circle" color="grey" />} /></Table.HeaderCell>
                <Table.HeaderCell><span>MMDO</span><Popup content='Minimum order' trigger={<Icon name="info circle" color="grey" />} /></Table.HeaderCell>
                <Table.HeaderCell>Price</Table.HeaderCell>
                <Table.HeaderCell >Reserve</Table.HeaderCell>
            </Table.Row>
        )
    }
    return (
        <Grid as={Segment} loading={loading}>
            <Table style={{ padding: 0 }} fixed>
                <Table.Header><Heading /></Table.Header>
                <ListAllDatas />
            </Table>
        </Grid>
    )
}
const styles = {
    row: {
        borderTopStyle: 'solid',
        borderTopColor: 'var(--border)',
        borderTopWidth: 1,
        padding: '1rem',
        fontFamily: 'Jura',
    },
    adding: {
        fontFamily: 'Jura'
    },
    row_active: {
        borderTopStyle: 'solid',
        borderTopColor: 'var(--border)',
        borderTopWidth: 1,
        backgroundColor: "var(--primary)",
        fontFamily: 'Jura'
    },

    head: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'var(--border)',
        textDecoration: 'underline',
        padding: '1rem',
        justifyContent: 'space-between'
    },
    column: {
        display: 'flex',
        width: '8rem',
    },
    column_short: {
        display: 'flex',
        width: '4rem',
    },
}
export default OfferTable
