import React, { useState } from 'react'
import { Button, Grid, Form, Checkbox } from 'semantic-ui-react'
// import TechnologyInput from '../../../../../components/inputs/TechnologyInput'
import CurrencyInput from '../../../../../components/inputs/CurrencyInput'
import TechnologySingleSelect from '../../../../../components/inputs/TechnologySingleSelect'
import { isEmpty, isNumberError, errorPercentage, isNumberErrorWhole } from '../../../../../store/services/helpers/validation'
const time_types = [
    { key: 1, value: 1, text: 'minute/s' },
    { key: 2, value: 2, text: 'hour/s' },
    { key: 3, value: 3, text: 'day/s' },
    { key: 4, value: 4, text: 'week/s' },
    { key: 5, value: 5, text: 'month/s' },
]
const contract_length_type = [
    { key: 1, value: 1, text: 'minute/s' },
    { key: 2, value: 2, text: 'hour/s' },
    { key: 3, value: 3, text: 'day/s' },
    { key: 4, value: 4, text: 'week/s' },
    { key: 5, value: 5, text: 'month/s' },
]

const AddingForm = ({ cancel, onAdd, exceptions }) => {
    const [edit, setEdit] = useState({
        price: '',
        currency: 'EUR',
        total_persons: '',
        time_before_order: '',
        time_before_order_type: 3,
        minimum_contract_length: '',
        minimum_contract_length_type: 3,
        minimum_order: '',
        dedication: '',
        technology: 0,
        project_manager: false
    })

    const handleEdit = (e, { name, value }) => {
        if (value === '') {
            setEdit(prevState => ({ ...prevState, [name]: value }))
            return
        }
        setEdit(prevState => ({ ...prevState, [name]: value }))
    }
    const handleChangeCheckbox = () => setEdit(prevState => ({ ...prevState, project_manager: !edit.project_manager }))

    function allNotFilled() {
        if (
            edit.price === '' ||
            edit.total_persons === '' ||
            edit.time_before_order === '' ||
            edit.minimum_contract_length === '' ||
            edit.minimum_order === '' ||
            edit.dedication === '' ||
            edit.technology === 0 ||
            isNumberError(parseFloat(edit.price)) ||
            isNumberErrorWhole(edit.total_persons) ||
            isNumberErrorWhole(edit.time_before_order) ||
            isNumberErrorWhole(edit.minimum_contract_length) ||
            isNumberErrorWhole(edit.minimum_order) ||
            errorPercentage(edit.dedication) ||
            minimumCantBeHigherThanTotal()
        ) return true
        return false
    }
    function minimumCantBeHigherThanTotal() {
        if (isNumberErrorWhole(edit.minimum_order)) return isNumberErrorWhole(edit.minimum_order)
        if (parseInt(edit.total_persons) < parseInt(edit.minimum_order)) return "Minimum order can't be higher than total."
        return false
    }
    return (
        <Grid>
            <Grid.Column computer={4} tablet={8} mobile={16}>
                <span>Price</span>
                <Form.Input
                    fluid
                    value={edit.price}
                    name="price"
                    onChange={handleEdit}
                    error={!isEmpty(edit.price) && isNumberError(edit.price)}
                />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16}>
                <CurrencyInput data={edit.currency} handler={(e) => setEdit(prevState => ({ ...prevState, currency: e }))} />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16}>
                <span>Total people</span>
                <Form.Input
                    fluid
                    value={edit.total_persons}
                    name="total_persons"
                    onChange={handleEdit}
                    error={!isEmpty(edit.total_persons) && isNumberErrorWhole(edit.total_persons)}
                />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16}>
                <span>Minimum order</span>
                <Form.Input
                    fluid
                    value={edit.minimum_order}
                    name="minimum_order"
                    onChange={handleEdit}
                    error={!isEmpty(edit.minimum_order) && minimumCantBeHigherThanTotal()} />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16}>
                <span>Time before order</span>
                <Form.Input
                    fluid
                    value={edit.time_before_order}
                    name="time_before_order"
                    onChange={handleEdit}
                    error={!isEmpty(edit.time_before_order) && isNumberErrorWhole(edit.time_before_order)} />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16}>
                <span>Time type</span>
                <Form.Select
                    style={{ borderRadius: 0 }}
                    fluid
                    value={edit.time_before_order_type}
                    name="time_before_order_type"
                    onChange={handleEdit}
                    options={time_types} />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16}>
                <span>Minimum contract length</span>
                <Form.Input
                    fluid
                    value={edit.minimum_contract_length}
                    name="minimum_contract_length"
                    onChange={handleEdit}
                    error={!isEmpty(edit.minimum_contract_length) &&
                        isNumberErrorWhole(edit.minimum_contract_length)} />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16}>
                <span>Time type</span>
                <Form.Select
                    style={{ borderRadius: 0 }}
                    fluid
                    value={edit.minimum_contract_length_type}
                    name="minimum_contract_length_type"
                    onChange={handleEdit}
                    options={contract_length_type} />
            </Grid.Column>
            <Grid.Column computer={4} tablet={8} mobile={16}>
                <span>Dedication %</span>
                <Form.Input
                    fluid
                    value={edit.dedication}
                    placeholder="100%"
                    name="dedication"
                    error={!isEmpty(edit.dedication) && errorPercentage(parseFloat(edit.dedication))}
                    onChange={handleEdit} />
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={16}>
                <span>Technology</span>
                <TechnologySingleSelect handler={(e) => setEdit(prevState => ({ ...prevState, technology: e }))} exceptions={exceptions} />
            </Grid.Column>
            <Grid.Column width={4}>
                <Form.Field style={{ display: 'flex', alignItems: "center", marginTop: '1.5rem', justifyContent: "center" }}>
                    <Checkbox
                        style={{ marginRight: "0.5rem" }}
                        onChange={handleChangeCheckbox}
                        checked={edit.project_manager} /><span>Project manager</span>
                </Form.Field>
            </Grid.Column>
            <Grid.Column width={16}>
                <Button basic onClick={cancel}>Cancel</Button>
                <Button onClick={() => onAdd(edit)} disabled={allNotFilled()} content="Add" icon="plus" />
            </Grid.Column>
        </Grid>
    )
}

export default AddingForm
