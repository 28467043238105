import React from 'react'
import { Header } from 'semantic-ui-react'
import { dateService } from '../../../../store/services/custom/DateServices'

const DetailHeader = ({ uuid, when }) => {
    function getDateAndTime(input) {
        let result = input.split(' ')
        return (
            <div>
                <span>{dateService.convertDateWithDots(result[0])}</span>
                {/* <span>{result[1]}</span> */}
            </div>)
    }
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: 'var(--primary)',
            padding: '1rem',
            marginTop: "1rem",
            borderTopStyle: 'solid',
            borderLeftStyle: 'solid',
            borderRightStyle: 'solid',
            borderColor: 'var(--border)',
            borderWidth: 1,
        }}>
            <Header style={{ margin: 0, color: 'var(--white)' }}>
                {uuid}
                <Header.Subheader style={{ color: 'var(--white)' }}>order uuid</Header.Subheader>
            </Header>
            <Header style={{ textAlign: 'right', margin: 0, color: 'var(--white)' }}>
                {getDateAndTime(when)}
                <Header.Subheader style={{ color: 'var(--white)' }}>ordered on</Header.Subheader>
            </Header>
        </div>
    )
}

export default DetailHeader
