import { APIRequest } from '../helpers/requests';
import { API } from '../../config';


export const preferencesService = {
    getChoices, getCountries,
}

async function getChoices() {
    return await APIRequest({
        url: API.GET_CHOICES,
        method: 'GET',
        data: {},
        private: true
    });
}
async function getCountries() {
    return await APIRequest({
        url: API.GET_COUNTRY_PREFERENCES,
        method: 'GET',
        data: {},
        private: true
    });
}