import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Header, Label, Popup, Rating } from 'semantic-ui-react'

const OrderRatings = ({ ratings }) => {
    const user = useSelector(state => state.user)
    const ListOfComments = () => {
        return ratings.map((item, key) => (
            <div key={key} style={styles.comment_container}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", }}>
                    <div>
                        {user.id === item.rater_id &&
                            <Popup
                                content={'Rated by you'}
                                trigger={<Label circular style={{ background: 'var(--primary)' }} empty />}
                            />
                        }
                        <span style={{ fontWeight: "bold" }}>{item.name}</span>
                    </div>
                    <div>
                        <Rating disabled icon='star' rating={item.rate} maxRating={5} />
                        {/* <Icon name='pencil' style={{ cursor: 'pointer', marginLeft: '1rem' }} /> */}
                    </div>
                </div>
                <div>{item.comment}</div>
            </div>
        ))
    }
    return (
        <Fragment>
            <Header>Ratings</Header>
            <ListOfComments />
        </Fragment>
    )
}
const styles = {
    comment_container: {
        background: 'var(--light)',
        border: '1px solid var(--border)',
        margin: '0.5rem 0rem',
        padding: '0.5rem',
    },
    text_area: {
        display: "flex",
        width: "100%",
        height: "auto",
        borderColor: "var(--border)",
    }
}
export default OrderRatings
