import React from 'react'
import { Grid } from 'semantic-ui-react'

const InfoCol = ({ name, value }) => {
    return (
        <Grid.Column mobile={16} tablet={8} computer={8} style={styles.col}>
            <div style={{ fontWeight: "bold" }}>{name}</div>
            <div>{value}</div>
        </Grid.Column>
    )
}
const styles = {
    col: { display: 'flex', justifyContent: 'space-between', padding: 0 }
}
export default InfoCol
