import React from 'react';
import { Header, Segment } from 'semantic-ui-react';

function GraphSegment(props) {

    return (
        <>
            <Segment style={{ backgroundColor: "var(--light-grey)", marginTop: "2rem", marginLeft: "2rem", marginRight: "2rem", marginBottom: 0 }}>
                <Header>
                    {props.header}
                </Header>
            </Segment>
            <Segment style={{ marginBottom: "2rem", marginLeft: "2rem", marginRight: "2rem", marginTop: 0 }}>
                {props.content}
            </Segment>
        </>
    );
}

export default GraphSegment