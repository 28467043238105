import React from 'react';

import { routes } from '../../../store/routes/index';

// components
import { Grid } from "semantic-ui-react";
import HexagonLink from "../../../components/general/HexagonLink";

function IconsView() {
    return (
        <div className="main">
            <Grid container style={{ marginTop: ".5rem"}}>
                <Grid.Row>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                        <h4>Human Resources</h4>
                        <p>
                            We provide easy to use platform for you to control all the processes in HR digital. 
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column computer={3} tablet={4} mobile={8}>
                        <HexagonLink to={routes.EMPLYOEES_LIST} name="Employees" icon="/images/icons/teams.svg" fill="var(--primary)"/>
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={4} mobile={8}>
                        <HexagonLink to={routes.CANDIDATES_LIST} name="Candidates" icon="/images/icons/teams.svg" fill="var(--blue)"/>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                        <h4>Project Management</h4>
                        <p>
                            We provide easy to use platform for you to control all the processes in HR digital. 
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column computer={3} tablet={4} mobile={8}>
                        <HexagonLink to="/" name="Projects" icon="/images/icons/projects.svg" fill="var(--primary)"/>
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={4} mobile={8}>
                        <HexagonLink to="/" name="Teams" icon="/images/icons/teams.svg" fill="var(--blue)"/>
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={4} mobile={8}>
                        <HexagonLink to="/" name="Timesheets" icon="/images/icons/timesheets.svg" fill="var(--primary)"/>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                        <h4>Sales Management</h4>
                        <p>
                            We provide easy to use platform for you to control all the processes in HR digital. 
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column computer={3} tablet={4} mobile={8}>
                        <HexagonLink to="/" name="Contacts" icon="/images/icons/contacts.svg" fill="var(--primary)"/>
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={4} mobile={8}>
                        <HexagonLink to="/" name="Accounts" icon="/images/icons/teams.svg" fill="var(--blue)"/>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                        <h4>Miscellanous</h4>
                        <p>
                            We provide easy to use platform for you to control all the processes in HR digital. 
                        </p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column computer={3} tablet={4} mobile={8}>
                        <HexagonLink to="/" name="Inventory" icon="/images/icons/inventories.svg" fill="var(--primary)"/>
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={4} mobile={8}>
                        <HexagonLink to="/" name="Settings" icon="/images/icons/settings.svg" fill="var(--blue)"/>
                    </Grid.Column>
                    <Grid.Column computer={3} tablet={4} mobile={8}>
                        <HexagonLink to={routes.ORGANISATION_LIST} name="Company" icon="/images/icons/settings.svg" fill="var(--primary)"/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default IconsView;