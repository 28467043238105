import React from 'react'
import { Icon } from 'semantic-ui-react';

export default function EditDeleteIcons({ onEdit, onDelete }) {
    return (
        <div>
            <Icon name="pencil" link={true} onClick={onEdit} style={{ marginRight: '1rem' }} />
            <Icon name="close" link={true} onClick={onDelete} />
        </div>

    )
}
