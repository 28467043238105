import React, { Fragment } from "react";

import IconsView from "./IconsView";
import SidebarWidgetsView from "./SidebarWidgetsView";


function Layout(props) {
    return (
        <Fragment>
            { props.viewType === 1 ? <IconsView/> : <SidebarWidgetsView/> }
        </Fragment>
    );
}

export default Layout;
