import React, { useEffect, useState } from 'react'
import { Grid, Header, Button, Modal, Form } from 'semantic-ui-react'

import TechnologiesList from './TechnologiesList'
import { technologyService } from '../../../../store/services/common/TechnologyService'
import Loading from '../../../../components/general/Loading'

const Technologies = () => {
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [name, setName] = useState("")

    useEffect(() => {
        async function fetchData() {
            let result = await technologyService.getTechnologies()
            if (result) {
                setData(result.response)
            }
            setLoading(false)
        }
        fetchData()
    }, [])
    async function createTechnology(input) {
        let result = await technologyService.createTechnology({ name: input })
        let array = data
        array.push(result.response)
        setData(array)
        setOpen(false)
    }
    async function deleteTechnology(input) {
        await technologyService.deleteTechnology(input)
    }
    async function updateTechnology(input) {
        await technologyService.updateTechnology(input.id, input.name)
    }
    return (
        <Grid style={styles.container}>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                size='mini'
                style={{ textAlign: "center" }}>
                <Modal.Header>Edit</Modal.Header>
                <Modal.Content>
                    Create technology
                    <Form.Input
                        style={{ marginTop: '1rem' }}
                        value={name}
                        onChange={(e) => setName(e.target.value.toUpperCase())}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpen(false)} basic>Close</Button>
                    <Button onClick={() => createTechnology(name)} icon="checkmark" content="Save" />
                </Modal.Actions>

            </Modal>
            <Grid.Row style={styles.header}>
                <Header as='h1' style={{ color: 'var(--primary)', marginLeft: '1rem' }}>
                    Technologies
                    <Header.Subheader>Manage technologies</Header.Subheader>
                </Header>
                <div>
                    <Button content='Create technology' onClick={() => setOpen(true)} />
                </div>
            </Grid.Row>
            {loading ?
                <Loading />
                :
                <Grid.Row style={{ padding: 0, }}>
                    <TechnologiesList
                        data={data}
                        onDelete={(e) => deleteTechnology(e)}
                        onEdit={(e) => updateTechnology(e)}
                        handler={(e) => setData(e)}
                    />
                </Grid.Row>

            }

        </Grid>
    )
}
const styles = {
    container: {
        padding: '1rem'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 0
    }
}
export default Technologies
