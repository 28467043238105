import React, { useEffect, useState } from 'react'
import { Grid, Header, Label, Menu, Tab } from 'semantic-ui-react'
import Loading from '../../../components/general/Loading'

import { reservationService } from '../../../store/services/reservations/ReservationServices'
// import ReservationCard from './ReservationCard'
import { useSelector } from "react-redux";
import ReservationTable from './ReservationTable'
const Reservations = () => {


    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(
        {
            all: [],
            pending: [],
            accepted: [],
            rejected: [],
        }
    )
    const user = useSelector(state => state.user)
    const profile = useSelector(state => state.profile)

    useEffect(() => {
        async function fetchData() {
            let result
            if (profile.user.role === 2) result = await reservationService.getProviderReservations()
            if (profile.user.role === 3) result = await reservationService.getClientReservations()
            if (result.status === 200) {
                let convert = {
                    all: result.response,
                    pending: result.response.filter((item) => item.status === 1),
                    accepted: result.response.filter((item) => item.status === 2),
                    rejected: result.response.filter((item) => item.status === 3),
                }
                setData(convert)
            }
            setLoading(false)
        }
        fetchData()
    }, [profile.user.role, user.id])

    // const ListOfCards = () => {
    //     if (data.length === 0) return (
    //         <div style={{ display: 'flex', justifyContent: "center", width: "100%" }}>
    //             <Header as='h1' style={{ color: 'var(--variant3)' }} >No Reservations here</Header>
    //         </div>
    //     )
    //     return data.map((item, key) => (
    //         <Grid.Column key={key} computer={4} tablet={8} mobile={16}>
    //             <ReservationCard data={item} />
    //         </Grid.Column>
    //     ))
    // }
    let panes = [
        {
            menuItem: (
                <Menu.Item key='All'>
                    All
                    <Label circular>{data.all.length}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane attached={false}><ReservationTable data={data.all} /></Tab.Pane>,
        },
        {
            menuItem: (
                <Menu.Item key='Pending'>
                    Pending
                    <Label circular>{data.pending.length}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane attached={false}><ReservationTable data={data.pending} /></Tab.Pane>,
        },
        {
            menuItem: (
                <Menu.Item key='Accepted'>
                    Accepted
                    <Label circular>{data.accepted.length}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane attached={false}><ReservationTable data={data.accepted} /></Tab.Pane>,
        },
        {
            menuItem: (
                <Menu.Item key='Rejected'>
                    Rejected
                    <Label circular>{data.rejected.length}</Label>
                </Menu.Item>
            ),
            render: () => <Tab.Pane attached={false}><ReservationTable data={data.rejected} /></Tab.Pane>,
        },
    ]

    return (
        <Grid>
            {loading
                ? <Loading />
                : <>
                    <Grid.Row style={{ padding: '1rem' }}>
                        <Header as='h1' style={{ color: 'var(--primary)' }}>
                            Reservations
                            <Header.Subheader>You decide about your feature</Header.Subheader>
                        </Header>
                    </Grid.Row>
                    <Grid.Row>
                        <Tab
                            menu={{ secondary: true, pointing: true }}
                            panes={panes}
                            // renderActiveOnly
                            style={{ width: "100%" }} />
                    </Grid.Row>
                </>
            }
        </Grid>
    )
}

export default Reservations
