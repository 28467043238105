import React from 'react'
import { Grid, Header, Popup, Icon } from 'semantic-ui-react'
import { dateService } from '../../../../store/services/custom/DateServices'
const ProjectHeader = ({ data, onEdit, onDelete }) => {
    return (
        <Grid.Row style={styles.container}>
            <Grid.Column computer={8} tablet={8} mobile={16} style={styles.col}>
                <Header as='h1'>{data.name}</Header>
                <div>Starting date: <b>{dateService.convertDateWithDots(data.starts)}</b></div>
                <div>Planned end: <b>{dateService.convertDateWithDots(data.ends)}</b></div>
                <div>Days left: <b>{dateService.daysLeft(dateService.convertToMomentDate(data.ends))}</b></div>
            </Grid.Column>
            <Grid.Column computer={8} tablet={8} mobile={16} style={styles.col}>
                <div style={styles.text}>Budget: <b>{data.budget} {data.currency}</b></div>
                <div style={styles.text}>Mandays: <b>{data.man_days}</b></div>
                {data.project_manager && <div style={styles.text}>
                    <span style={styles.yes}>Manager</span>
                </div>}
            </Grid.Column>
            <Grid.Column style={styles.buttons}>
                <div style={{ padding: '1rem', display: "flex", justifyContent: "flex-end", width: '100%' }}>
                    <Popup content='Edit project' trigger={
                        <Icon name='pencil' style={styles.icn_one} onClick={onEdit} />} />
                    <Popup content='Delete project' trigger={
                        <Icon name='close' style={styles.icn_two} onClick={onDelete} />} />
                </div>
            </Grid.Column>
        </Grid.Row>
    )
}
const styles = {
    container: {
        width: "100%",
        display: 'flex',
        alignItems: 'center',
        margin: '0 1rem',
    },
    col: {
        padding: '1rem 4rem',
    },
    text: {
        fontSize: "1.5rem",
        padding: '0.5rem 0'
    },
    yes: {
        padding: "0.1rem 0.5rem",
        color: "var(--white)",
        backgroundColor: "var(--info)",
        fontWeight: "bold",
        lineHeight: 1.5
    },
    no: {
        padding: "0.1rem 0.5rem",
        color: "var(--white)",
        backgroundColor: "var(--black)",
        fontWeight: "bold"
    },
    description: {
        padding: '1rem 4rem',
        textAlign: 'justify'
    },
    row: { padding: 0 },
    info: { display: 'flex', alignItems: 'center' },
    icn_one: { color: 'var(--black)', fontSize: '1.25rem', marginRight: '1rem', cursor: 'pointer' },
    icn_two: { color: 'var(--primary)', fontSize: '1.25rem', cursor: 'pointer' },
    buttons: { position: 'absolute', right: '0.5rem', top: '0.5rem' }
}
export default ProjectHeader
