import React from 'react'
import { Grid } from 'semantic-ui-react'
const SectionThree = () => {
    return (
        <Grid style={{ textAlign: 'center', width: '100%', display: "flex", justifyContent: "center", padding: "5% 10%" }}>
            <Grid.Row as="p" className="text-inc" style={styles.headers}>
                Focus on your business,<br />
                    we focus on resources.
            </Grid.Row>
            <Grid.Row as="p" style={styles.content}>
                We believe the right person is out there for each opportunity. That's<br />
                why we're reinventing the job search so both sides can find their<br />
                match with confidence.
            </Grid.Row>
            <Grid.Row className="home-steps">
                <Grid.Column className="text-inc" mobile={16} tablet={8} computer={4}><div style={styles.number}>1</div><div style={styles.text}>Create a profile</div></Grid.Column>
                <Grid.Column className="text-inc" mobile={16} tablet={8} computer={4}><div style={styles.number}>2</div><div style={styles.text}>Receive an offer</div></Grid.Column>
                <Grid.Column className="text-inc" mobile={16} tablet={8} computer={4}><div style={styles.number}>3</div><div style={styles.text}>Approve an offer</div></Grid.Column>
                <Grid.Column className="text-inc" mobile={16} tablet={8} computer={4}><div style={styles.number}>4</div><div style={styles.text}>Work on cool projects</div></Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
const styles = {
    headers: {
        fontWeight: 'bold',
        fontSize: 24,
        textAlign: 'center',
        width: '100%',
        display: "flex",
        justifyContent: 'center'
    },
    content: {
        width: '100%',
        display: "flex",
        justifyContent: 'center',
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'center',
    },
    number: {
        fontSize: 50,
        lineHeight: 1.2,
        fontWeight: 'bold',
    },
    text: {
        fontSize: 20,
    }
}
export default SectionThree
