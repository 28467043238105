import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, Grid } from 'semantic-ui-react'
import CurrencyInput from '../../../../../components/inputs/CurrencyInput'
import TechnologySingleSelect from '../../../../../components/inputs/TechnologySingleSelect'
import TimeTypeInput from '../../../../../components/inputs/TimeTypeInput'
import { errorPercentage, isNumberError, isEmpty, isNumberErrorWhole } from '../../../../../store/services/helpers/validation'

const CreateEditModal = ({ edit, create, data, onClose, open, onCreate, onEdit, loading }) => {
    const [bundle, setBundle] = useState(data ? data : {
        name: "",
        currency: "EUR",
        discount: "",
        duration_display: "",
        duration_type: "",
        man_day_limit: "",
        price: "",
        technology: "",
        is_active: true
    })
    useEffect(() => {
        setBundle(data ? data : {
            name: "",
            currency: "EUR",
            discount: "",
            duration_display: "",
            duration_type: "",
            man_day_limit: "",
            price: "",
            technology: "",
            is_active: true
        })
    }, [data])
    function handleChange(e, { name, value }) { setBundle(prevState => ({ ...prevState, [name]: value })) }
    function handleCurrency(e) { setBundle(prevState => ({ ...prevState, currency: e })) }
    function handleTimeType(e) { setBundle(prevState => ({ ...prevState, duration_type: e })) }
    function handleTechnology(e) { setBundle(prevState => ({ ...prevState, technology: e })) }
    function handleActive(e) { setBundle(prevState => ({ ...prevState, is_active: !bundle.is_active })) }
    function sendCreationRequest() {
        onCreate(bundle)
        setBundle(data ? data : {
            name: "",
            currency: "EUR",
            discount: "",
            duration_display: "",
            duration_type: "",
            man_day_limit: "",
            price: "",
            technology: "",
            is_active: true
        })
    }
    function noChange() {
        if (data) {
            if (bundle.name !== data.name) return false
            if (bundle.discount !== data.discount) return false
            if (bundle.duration_type !== data.duration_type) return false
            if (bundle.duration_display !== data.duration_display) return false
            if (bundle.man_day_limit !== data.man_day_limit) return false
            if (bundle.price !== data.price) return false
            if (bundle.technology !== data.technology) return false
            if (bundle.currency !== data.currency) return false
            if (bundle.is_active !== data.is_active) return false
        }
        return true
    }
    function allFilled() {
        if (isEmpty(bundle.name)) return true
        if (isEmpty(bundle.discount) || errorPercentage(bundle.discount)) return true
        if (isEmpty(bundle.duration_type) || isNumberErrorWhole(bundle.duration_type)) return true
        if (isEmpty(bundle.duration_display) || isNumberErrorWhole(bundle.duration_display)) return true
        if (isEmpty(bundle.man_day_limit) || isNumberErrorWhole(bundle.man_day_limit)) return true
        if (isEmpty(bundle.price) || isNumberError(bundle.price)) return true
        if (isEmpty(bundle.technology)) return true
        return false
    }
    return (
        <Modal
            open={open}
            onClose={onClose}
            size="tiny"
        >
            <Modal.Header style={{ textAlign: "center" }}>
                {edit && <span>Update bundle {data.name}</span>}
                {create && <span>Create bundle</span>}
            </Modal.Header>
            <Modal.Content as={Grid}>
                {/* NAME */}
                <Grid.Column mobile={16} tablet={8} computer={8} >
                    <span>Name</span>
                    <Form.Input fluid value={bundle.name} name="name" onChange={handleChange} />
                </Grid.Column>
                {/* PRICE */}
                <Grid.Column mobile={16} tablet={8} computer={8} >
                    <span>Price</span>
                    <Form.Input fluid value={bundle.price} name="price" onChange={handleChange} error={(!isEmpty(bundle.price) && isNumberError(bundle.price))} />
                </Grid.Column>
                {/* DURATION */}
                <Grid.Column mobile={16} tablet={8} computer={8} >
                    <span>Duration</span>
                    <Form.Input fluid value={bundle.duration_display} name="duration_display" onChange={handleChange} error={(!isEmpty(bundle.duration_display) && isNumberErrorWhole(bundle.duration_display))} />
                </Grid.Column>
                {/* DURATION TIME TYPE */}
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <TimeTypeInput data={bundle.duration_type} handler={(e) => handleTimeType(e)} />
                </Grid.Column>
                {/* MANDAY LIMIT */}
                <Grid.Column mobile={16} tablet={8} computer={8} >
                    <span>Manday limit</span>
                    <Form.Input fluid value={bundle.man_day_limit} name="man_day_limit" onChange={handleChange} error={(!isEmpty(bundle.man_day_limit) && isNumberErrorWhole(bundle.man_day_limit))} />
                </Grid.Column>
                {/* DISCOUNT */}
                <Grid.Column mobile={16} tablet={8} computer={8} >
                    <span>Discount %</span>
                    <Form.Input fluid value={bundle.discount} name="discount" onChange={handleChange} error={(!isEmpty(bundle.discount) && errorPercentage(bundle.discount))} />
                </Grid.Column>
                {/* CURRENCY */}
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <CurrencyInput data={bundle.currency} handler={(e) => handleCurrency(e)} />
                </Grid.Column>
                {/* TECHNOLOGY */}
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <span>Technology</span>
                    <TechnologySingleSelect data={bundle.technology} handler={(e) => handleTechnology(e)} />
                </Grid.Column>
                {/* ACTIVE */}
                <Grid.Column mobile={16} tablet={8} computer={8}>
                    <span>Active</span>
                    <Form.Checkbox checked={bundle.is_active} onChange={() => handleActive()} />
                </Grid.Column>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Cancel" onClick={onClose} basic disabled={loading} />
                {edit && <Button content="Update" onClick={() => onEdit(bundle)} disabled={noChange()} loading={loading} />}
                {create && <Button content="Create" onClick={() => sendCreationRequest()} disabled={allFilled()} loading={loading} />}
            </Modal.Actions>

        </Modal>
    )
}


export default CreateEditModal
