import React from 'react';
import { Pie } from 'react-chartjs-2';

// components
import GraphSegment from './GraphSegment';

function GraphPie() {

    const data = {
        labels: [
            'Blue',
            'Red',
            'Yellow'
        ],
        datasets: [{
            data: [300, 50, 100],
            backgroundColor: [
                '#6b48ff',
                '#FF4C78',
                '#EEE722'
            ],
            hoverBackgroundColor: [
                '#552fe4',
                '#e73863',
                '#c9c313'
            ]
        }]
    };

    return (
        <GraphSegment
            header="Pie Chart"
            content={
                <Pie data={data} />
            }
        />
    );
}

export default GraphPie