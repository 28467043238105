import React from 'react'
import { Grid, Icon } from 'semantic-ui-react'
import { useSelector } from "react-redux"
const PremiumUUID = ({ data }) => {
    const pref = useSelector(state => state.preferences)
    function getStatus() {
        let result
        pref.status_choices.filter(item => {
            if (item[0] === data.status) {
                result = item[1]
            }
            return item
        })
        return result
    }
    function getStatusStyle() {
        if (data.status === 1) return styles.no_premium
        if (data.status === 2) return styles.premium
        if (data.status === 3) return styles.rejected
    }
    return (
        <Grid.Row columns={3} style={{ padding: 0 }}>
            <Grid.Column>
                {data.is_premium ?
                    <div style={styles.premium}>
                        <span>Premium</span>
                        <Icon name='star' />
                    </div> :
                    <div style={styles.no_premium}>No premium
                    </div>}
            </Grid.Column>
            <Grid.Column><div style={getStatusStyle()}>status: {getStatus()}</div></Grid.Column>
            <Grid.Column><div style={styles.no_premium}>#id: {data.uuid}</div></Grid.Column>
        </Grid.Row>
    )
}
const styles = {
    premium: {
        display: 'flex',
        justifyContent: 'center',
        background: 'var(--primary)',
        color: 'var(--white)',
        fontSize: '1.5rem',
        padding: '0.5rem'
    },
    no_premium: {
        background: 'var(--light)',
        textAlign: 'center',
        fontSize: '1.5rem',
        padding: '0.5rem'
    },
    rejected: {
        display: 'flex',
        color: 'var(--white)',
        justifyContent: 'center',
        background: 'var(--black)',
        fontSize: '1.5rem',
        padding: '0.5rem'
    }
}

export default PremiumUUID
