// react
import React from "react";
// router
import { Link } from "react-router-dom";

// routes
import { routes } from "../../../../store/routes/index";

// styles
import { Button, Icon, Modal, Grid, Header } from "semantic-ui-react";

// component
function RegistrationModal({ open, state }) {
    //states => state = 1 -> Registration completed
    //          state = 2 -> Registration needs to be approved
    // template
    return (
        <Modal
            size='tiny'
            open={open}>
            <Grid style={{
                color: 'var(--black)',
                margin: '1rem',
                padding: '1rem',
                borderStyle: 'solid',
                borderColor: 'var(--border)',
                borderWidth: "0.5rem"
            }} centered>
                <Icon name="info" size="huge" circular />
                <Grid.Row style={{ display: 'flex', flexDirection: 'column' }}>
                    <Header style={{ color: "var(--primary)", fontSize: "2rem" }}>GREAT!</Header>
                    {state === 1 &&
                        <div>
                            <p>
                                Your account has been registered so you need to activate it.<br />
                                Check your email for activation code.<br />
                                After that, there will come an email with your login.</p>
                        </div>
                    }
                    {state === 2 &&
                        <div>
                            <p>
                                We are glad to work with you and appreciate your desicion.<br />
                                We will let you know when your account is approved by email.</p>
                        </div>
                    }
                </Grid.Row>
                <Grid.Row>
                    <Link to={routes.HOME}>
                        <Button size="big">
                            Back to homepage
                        </Button>
                    </Link>
                </Grid.Row>
            </Grid>
        </Modal>
    );
}

export default RegistrationModal;
