import React, { useState, useEffect } from 'react'
import { Grid, Form, Checkbox, Button, Divider } from 'semantic-ui-react'
import AddressForm from '../../../../components/inputs/AddressForm'
import NaceCodeInput from '../../../../components/inputs/NaceCodeInput'
const BusinessAdresses = ({ handleData, data, nextStep, previousStep, client }) => {
    const [sameAddresses, setSameAddresses] = useState(false)
    const [billing_address, setBillingAddress] = useState(data ? data.billing_address : {})
    const [shipping_address, setShippingAddress] = useState(data ? data.shipping_address : {})
    const [business, setBusiness] = useState(data ? data.business_detail : {
        vat_number: '',
        nace_code: '',
        nace_name: '',
        business_register_folder: '',
        identification_number: '',
        dit_number: '',
        name: '',
    })
    const [nace, setNace] = useState({
        id: 0,
        nace_code: '',
        nace_name: ''
    })
    useEffect(() => {
        if (sameAddresses) setShippingAddress(billing_address)
        handleData({
            billing_address: billing_address,
            shipping_address: shipping_address,
            business: business
        })
    }, [handleData, billing_address, shipping_address, sameAddresses, business])
    useEffect(() => {
        setBusiness((prevState) => ({ ...prevState, nace_code: nace.nace_code, nace_name: nace.nace_name }))
    }, [nace])
    function handleBillingAdress(input) { setBillingAddress(input) }
    function handleShippingAdress(input) { setShippingAddress(input) }
    function handleBusiness(e, { name, value }) { setBusiness((prevState) => ({ ...prevState, [name]: value })) }
    function isAllFilled() {
        if (
            billing_address.street &&
            billing_address.city &&
            // billing_address.state &&
            billing_address.postcode &&
            billing_address.country &&
            shipping_address.street &&
            shipping_address.city &&
            // shipping_address.state &&
            shipping_address.postcode &&
            shipping_address.country &&
            business.nace_code &&
            business.nace_name &&
            business.vat_number &&
            business.business_register_folder
        ) return true
        return false
    }
    return (
        <Grid>
            <Grid.Row style={{ padding: 0 }}></Grid.Row>
            <Divider horizontal>Business detail</Divider>
            <Grid.Row>
                {client &&
                    <>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <span>Business name</span><span style={{ color: 'var(--danger)' }}>*</span>
                            <Form.Input
                                fluid
                                placeholder="Enter your business name"
                                value={business.name}
                                name="name"
                                onChange={handleBusiness} />
                        </Grid.Column >
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <span>Identification number</span><span style={{ color: 'var(--danger)' }}>*</span>
                            <Form.Input
                                fluid
                                placeholder="Enter your identification number"
                                value={business.identification_number}
                                name="identification_number"
                                onChange={handleBusiness} />
                        </Grid.Column >
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <span>Dit number</span><span style={{ color: 'var(--danger)' }}>*</span>
                            <Form.Input
                                fluid
                                placeholder="Enter your dit number"
                                value={business.dit_number}
                                name="dit_number"
                                onChange={handleBusiness} />
                        </Grid.Column >
                    </>
                }
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <span>Nace code</span>
                    <NaceCodeInput handler={(e) => setNace({ nace_name: e.name, nace_code: e.code, id: e.id })} data={nace} />
                </Grid.Column >
                <Grid.Column computer={8} tablet={8} mobile={16}>
                    <span>Vat number</span><span style={{ color: 'var(--danger)' }}>*</span>
                    <Form.Input
                        fluid
                        placeholder="Enter your vat number"
                        value={business.vat_number}
                        name="vat_number"
                        onChange={handleBusiness} />
                </Grid.Column >
                <Grid.Column computer={8} tablet={16} mobile={16}>
                    <span>Business register folder</span><span style={{ color: 'var(--danger)' }}>*</span>
                    <Form.Input
                        fluid
                        placeholder="Enter your business register folder"
                        value={business.business_register_folder}
                        name="business_register_folder"
                        onChange={handleBusiness} />
                </Grid.Column >
            </Grid.Row>
            <Divider horizontal>Business addresses</Divider>
            <Grid.Row divided>
                <Grid.Column mobile={16} tablet={8} computer={8} style={{ padding: 0 }}>
                    <AddressForm
                        handleData={(output) => handleBillingAdress(output)}
                        title="Billing"
                        data={billing_address} />
                    <Form.Field style={{ display: 'flex', alignItems: "center", marginTop: '1rem', marginLeft: '1rem' }}>
                        <Checkbox
                            style={{ marginRight: "0.5rem" }}
                            onChange={() => setSameAddresses(!sameAddresses)}
                            checked={sameAddresses} />
                        <span>Set billing address as shipping address</span>
                    </Form.Field>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={8} style={{ padding: 0 }}>
                    <AddressForm
                        handleData={(output) => handleShippingAdress(output)}
                        title="Shipping"
                        disabled={sameAddresses}
                        data={sameAddresses ? billing_address : shipping_address} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ margin: '0 1rem' }}>
                <Button onClick={previousStep}>Previous</Button>
                <Button onClick={nextStep} disabled={!isAllFilled()}>Next</Button>
            </Grid.Row>
        </Grid>
    )
}

export default BusinessAdresses
