// for local testing purposes:
// const API_URL = "http://your-ip-address/api";
// const API_URL = "http://127.0.0.1:8000/api";
const API_URL = "https://dev-hiringinit.sodonbos.com/api";

// for loading animation
export const LOADING_TIME = 250;

// tables pagination
export const PAGINATION_LIMIT = 5;

// Google API
export const googleMapsApiKey = "AIzaSyBkzqEbiNLSqnulRtInQZJDz7X-wbRWemo"
export const libraries = ["places"]


// User Management (Auth)
export const API = {
    LOGIN: API_URL + "/users/login/",
    REFRESH: API_URL + "/users/login/refresh/",
    VERIFY: API_URL + "/users/login/verify/",
    RESEND_ACTIVATION: API_URL + "/users/resend_activation/",
    RESET_PASSWORD: API_URL + "/users/reset_password/",
    RESET_CONFIRM: API_URL + "/users/reset_password_confirm/",
    SET_PASSWORD: API_URL + "/users/set_password/",
    GET_USER_ACTIVATION: API_URL + "/users/activation/", // + <uid> / + <token>
    RECOMMENDATION: API_URL + "/users/recommendation/",

    //PROFILE
    PROFILE: API_URL + "/users/profile/",
    PROFILES: API_URL + "/users/profiles/",
    USER: API_URL + "/users/me/",
    SET_USERNAME: API_URL + "/users/set_username/",
    SET_EMAIL: API_URL + "/users/me/",
    PERSON: API_URL + '/users/person/',

    //REGISTER
    REGISTER: API_URL + "/users/registrations/",
    REGISTER_CLIENT: API_URL + "/users/client_registration/",


    //COMMON
    GET_BUSINESS: API_URL + '/common/business_details/',
    ADDRESS: API_URL + '/common/addresses/',
    GET_COUNTRY_PREFERENCES: API_URL + '/common/country_choices',
    GET_CHOICES: API_URL + '/common/choices',
    TECHNOLOGIES: API_URL + '/common/technologies/',
    BANK_ACCOUNT: API_URL + '/common/bank_accounts/',
    RATINGS: API_URL + '/common/ratings/',
    //SETTINGS
    NACE_CODE: API_URL + '/settings/nace_code/',

    //PROJECTS
    PROJECTS: API_URL + '/projects/projects/',

    //SERVICES
    SERVICES: API_URL + '/services/services/',
    SERVICES_OFFERS: API_URL + '/services/service_offers/',
    ALL_SERVICES: API_URL + '/services/all_services/',
    FILTERED_SERVICES: API_URL + '/services/filtered_services/',//+{id}

    //RESERVATIONS
    RESERVATIONS: API_URL + '/orders/reservations/',
    CLIENT_RESERVATIONS: API_URL + '/orders/clients_reservations/',
    PROVIDER_RESERVATIONS: API_URL + '/orders/providers_reservations/',
    SELECTED_SERVICE_OFFERS: API_URL + '/orders/selected_service_offers/',
    ACCEPT_SELECTED_SERVICE_OFFERS: API_URL + '/orders/reservations/acceptance/',// + {id}
    CREATE_ORDER: API_URL + '/orders/create_order/', // + {id}

    CLIENT_ORDERS: API_URL + '/orders/clients_orders/',
    PROVIDER_ORDERS: API_URL + '/orders/providers_orders/',
    ORDER: API_URL + '/orders/orders/',

    //BUNDLES
    BUNDLES: API_URL + '/bundles/bundles/',
    USED_BUNDLES: API_URL + '/bundles/used_bundles/',
};

