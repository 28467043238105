import React, { Fragment, useEffect, useState } from 'react';
// components
import DataTable from '../../../../components/tables/DataTable'
import ActionButton from '../../../../components/buttons/ActionButton'
import { Button } from "semantic-ui-react";

//Services

const BundleTable = ({ data, loading, onShow, onEdit, onDelete }) => {
    const [data_to_table, setDataToTable] = useState(data ? data : [])


    useEffect(() => {
        setDataToTable(data ? data : [])
    }, [data])
    const orderedHeaders = [
        'id',
        'name',
        'price_display',
        'duration_display',
        'man_day_limit',
        'discount',
        'technology',
        'tableActions',
        'currency',
        'duration',
        'duration_type',
        'is_active',
        'price',
    ];
    const TableAction = ({ row }) => {
        return (
            <Button.Group>
                <ActionButton icon="eye" action={() => onShow(row)} />
                <ActionButton icon="pencil" action={() => onEdit(row)} />
                <ActionButton icon="trash" action={() => onDelete(row)} />
            </Button.Group>
        );
    }
    function getDurationType(input) {
        if (input === 1) return 'min'
        if (input === 2) return 'H'
        if (input === 3) return 'D'
        if (input === 4) return 'W'
        if (input === 5) return 'M'
    }
    const headers = {
        id: {
            text: 'Id',
            filterable: false,
            transform: (value) => `#${value}`,
        },
        tableActions: {
            text: 'Actions',
            invisible: false,
            sortable: false,
            filterable: false,
            transform: (value, index, row) => {
                return <TableAction row={row} index={index} />
            },
        },
        currency: { invisible: true },
        discount: {
            text: 'Discount',
            transform: (value, index, row) => {
                return <div>{value}%</div>
            },
        },
        duration: { invisible: true },
        duration_display: {
            text: 'Duration',
            transform: (value, index, row) => {
                return <div>{value}{getDurationType(row.duration_type)}</div>
            },
        },
        duration_type: { invisible: true },
        is_active: { invisible: true },
        man_day_limit: { text: 'Manday limit', },
        name: {
            text: 'Name',
            transform: (value, index, row) => {
                return <span style={row.is_active ? styles.active : styles.no_active}>{value}</span>
            },
        },
        price: { invisible: true },
        price_display: {
            text: 'Price',
            transform: (value, index, row) => {
                return <div>{value}{row.currency}</div>
            },
        },
        technology: { invisible: true },
    }

    return (
        <Fragment>
            <DataTable
                data={data_to_table}
                orderedHeaders={orderedHeaders}
                headers={headers}
                loading={loading}
            />
        </Fragment>
    )
}
const styles = {
    active: {
        background: 'var(--primary)',
        color: 'var(--white)',
        padding: '0 0.5rem',
        fontWeight: 'bold'
    },
    no_active: {
        background: 'var(--variant5)',
        color: 'var(--black)',
        padding: '0 0.5rem',
        fontWeight: 'bold'
    }
}
export default BundleTable
