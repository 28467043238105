import React, { useState, useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import { useHistory, useParams } from 'react-router-dom';

import ProjectHeader from './ProjectHeader'
import ProjectTechnologies from './ProjectTechnologies'
import ProjectContent from './ProjectContent'
import ProjectEditModal from '../ProjectEditModal'
import ProjectDeleteModal from '../ProjectDeleteModal'
import Loading from '../../../../components/general/Loading'
import ResponseModal from '../../../../components/modals/ResponseModal'

import { routes } from '../../../../store/routes/index'
import { projectService } from '../../../../store/services/projects/ProjectService'
import { dateService } from '../../../../store/services/custom/DateServices'
import BackButton from '../../../../components/buttons/BackButton';


const ProjectDetail = () => {
    const [open, setOpen] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [error, setError] = useState('')
    const [errorModal, setErrorModal] = useState(false)
    let history = useHistory()

    const [data, setData] = useState({})
    const params = useParams()
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            let result = await projectService.getProjectById(parseInt(params.id));
            if (result.status === 200) {
                setData(result.response);
            }
            setIsLoading(false);
        };
        fetchData();
    }, [params.id])

    async function onDelete(input) {
        await projectService.deleteProject(input)
        setOpenDelete(false)
        history.push(routes.PROJECTS)
    }
    async function onSave(input) {
        setLoading(true)
        let update = {
            name: input.name,
            ends: data.ends === input.ends ? input.ends : dateService.rotateDate(input.ends),
            starts: data.starts === input.starts ? input.starts : dateService.rotateDate(input.starts),
            project_manager: input.project_manager,
            budget: input.budget,
            currency: input.currency,
            description: input.description,
            man_days: input.man_days,
            technologies: {
                remove: data.technologies.map(item => item.id),
                add: input.technologies.map(item => item.id)
            },
        }
        let result = await projectService.updateProject(input.id, update)
        if (result.status !== 200) {
            setError(result.response.non_field_errors)
            setErrorModal(true)
            setLoading(false)
            return
        }
        setData(result.response)
        setOpen(false)
        setLoading(false)
    }
    return (
        <Grid style={{
            background: 'var(--light)',
            border: '1px solid var(--border)',
            margin: '1rem',
            padding: '1rem'

        }}>
            <BackButton content="Back to list" />

            {isLoading ? <Loading /> :
                <>
                    <ProjectHeader data={data} onDelete={() => setOpenDelete(true)} onEdit={() => setOpen(true)} />
                    <ProjectTechnologies techs={data.technologies} />
                    <ProjectContent data={data} />
                    <ProjectEditModal
                        open={open}
                        cancel={() => setOpen(false)}
                        onSave={(e) => onSave(e)}
                        data={data}
                        loading={loading} />
                    <ProjectDeleteModal
                        open={openDelete}
                        cancel={() => setOpenDelete(false)}
                        onDelete={() => onDelete(data.id)} />
                    <ResponseModal
                        onClick={() => setErrorModal(false)}
                        header='Error'
                        open={errorModal}
                        size='mini'
                        message={<span>{error}</span>}
                        onClose={() => setErrorModal(false)}
                    />
                </>}
        </Grid>
    )
}

export default ProjectDetail
