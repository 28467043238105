import React from 'react'
import { Grid, Icon } from 'semantic-ui-react'
import InfoColSO from '../../detail/InfoColSO'
import TechnologiesSO from '../../detail/TechnologiesSO'


const ServiceOfferAdd = ({ data, removeAds }) => {

    function convertTypeToText(number, type) {
        let result
        if (type === 1) result = number + " minute"
        if (type === 2) result = number + " hour"
        if (type === 3) result = number + " day"
        if (type === 4) result = number + " week"
        if (type === 5) result = number + " month"
        if (number > 1) return result + "s"
        return result
    }
    function convertContractTypeToText(number, type) {
        let result
        if (type === 1) result = number + " minute"
        if (type === 2) result = number + " hour"
        if (type === 3) result = number + " day"
        if (type === 4) result = number + " week"
        if (type === 5) result = number + " month"
        if (number > 1) return result + "s"
        return result
    }
    function projectManagerConvertToString(manager) {
        if (manager) return "With"
        return "Without"
    }
    return (
        <Grid style={styles.container}>
            <Icon name="close" style={styles.delete_icn} onClick={() => removeAds()} />
            <InfoColSO name="Price" value={data.price + " " + data.currency} />
            <InfoColSO name="Total people" value={data.total_persons} />
            <InfoColSO name="Minimum order" value={data.minimum_order} />
            <InfoColSO name="Dedication" value={data.dedication} />
            <InfoColSO name="Time before order" value={convertTypeToText(data.time_before_order, data.time_before_order_type)} />
            <InfoColSO name="Minimum contract length" value={convertContractTypeToText(data.minimum_contract_length, data.minimum_contract_length_type)} />
            <InfoColSO name="Project manager" value={projectManagerConvertToString(data.project_manager)} />
            <Grid.Column width={2} style={{ width: 'auto', display: 'flex', alignItems: "center" }}>
                <TechnologiesSO data={data.technology} />
            </Grid.Column>
        </Grid >
    )
}
const styles = {
    container: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: "var(--border)",
        width: "100%",
        backgroundColor: "#f3f6f9"
    },
    row: { padding: 0 },
    info: { display: 'flex', alignItems: 'center' },
    icn_one: { color: 'var(--black)', fontSize: '1.25rem', marginRight: '1rem' },
    icn_two: { color: 'var(--primary)', fontSize: '1.25rem' },
    icn_three: { color: 'var(--success)', fontSize: '1.25rem' },
    delete_icn: { color: 'var(--danger)', position: 'absolute', right: 0, zIndex: 20, cursor: 'pointer' }

}
export default ServiceOfferAdd
