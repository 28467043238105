import React, { Fragment, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import GraphBar from './GraphBar';
import GraphPie from './GraphPie';
import GraphStatistics1 from './GraphStatistics1';
import GraphStatistics2 from './GraphStatistics2';

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

// components
import Sidebar from './Sidebar';
import { routes } from '../../../store/routes/index';


function SidebarWidgetsView() {
    const history = useHistory()
    const profile = useSelector(state => state.profile)

    useEffect(() => {
        async function fetchData() {
            if (!profile.person || !profile.business_detail) {
                history.push(routes.PROFILE)
            }
        }
        fetchData()
    }, [history, profile])

    return (
        <Fragment>
            <Sidebar />
            <div className="main">
                <GraphStatistics1 />
                <GraphStatistics2 />
                <Grid columns={2}>
                    <Grid.Column style={{ padding: 0 }} mobile={16} tablet={8} computer={4}>
                        <GraphBar />
                    </Grid.Column>
                    <Grid.Column style={{ padding: 0 }} mobile={16} tablet={8} computer={4}>
                        <GraphPie />
                    </Grid.Column>
                </Grid>
            </div>
        </Fragment>
    );
}

export default SidebarWidgetsView;