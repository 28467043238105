import React from 'react'
import { Grid } from 'semantic-ui-react'
const SectionTwo = () => {
    return (
        <div>
            <img src="/images/home-section-two.jpg" alt="logo" width="100%" />
            <Grid style={styles.container} className="center-text">
                <div className="text-inc" style={styles.text_char}>{"<"}</div>
                <ul style={styles.list_text}>
                    <li className="text-inc">Full-time and contract opportunities</li>
                    <li className="text-inc">for a developer, tester, project manager,</li>
                    <li className="text-inc">designer or another IT professional.</li>
                </ul>
                <div className="text-inc" style={styles.text_char}>{"/>"}</div>
            </Grid>
        </div>
    )
}
const styles = {
    container: {
        position: "absolute",
        zIndex: 1,
        backgroundColor: "var(--white)",
        marginTop: "-15%",
        padding: "2% 2% 2% 8%",
        display: "block"
    },
    text_char: {
        color: "var(--primary)",
        padding: 0
    },
    list_text: {
        listStyleType: "none",
        padding: 0,
        fontSize: 16,
        lineHeight: 1.2,
        margin: 0
    }
}
export default SectionTwo
