import React, { useState } from 'react'
import { Grid, Button, Header, Form, Modal } from 'semantic-ui-react'
import ActionButton from '../../../../components/buttons/ActionButton'
import SuperModal from '../../../../components/modals/SuperModal'
const TechnologiesList = ({ data, handler, onEdit, onDelete }) => {
    const [search, setSearch] = useState('')
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [new_id, setNewId] = useState(0)
    const [name, setName] = useState('')
    const [workingData, setWorkingData] = useState(data ? data : [])


    const ActionButtonGroup = ({ id, input_name }) => {
        return (
            <Button.Group>
                <ActionButton action={() => action(id, input_name, 'edit')} icon="pencil" />
                <ActionButton action={() => action(id, input_name, 'delete')} icon="trash" />
            </Button.Group>)
    }
    const TechRow = () => {
        let result = workingData.filter(item => item.name.includes(search.toUpperCase()))
        if (result.length === 0) return <Header style={styles.no_technologies}>no technologies found</Header>
        return result.map((item, key) => (
            <Grid.Row key={key} style={styles.row} className='service-offer-row'>
                <Grid.Column width={2}>#{item.id}</Grid.Column>
                <Grid.Column width={12} style={styles.title}>{item.name}</Grid.Column>
                <Grid.Column width={2} style={styles.actions}><ActionButtonGroup id={item.id} input_name={item.name} /></Grid.Column>
            </Grid.Row>
        ))

    }
    function action(id, input_name, type) {
        setName(input_name)
        setNewId(id)
        if (type === 'delete') setOpenDelete(true)
        if (type === 'edit') setOpenEdit(true)
    }
    function deleteApprove() {
        let array = workingData.filter((item) => item.id !== new_id)
        handler(array)
        onDelete(new_id)
        setWorkingData(array)
        setOpenDelete(false)
    }
    function editApprove() {
        let array = workingData.map((item) => {
            if (item.id === new_id) return { id: item.id, name: name }
            return item
        })
        handler(array)
        onEdit({ id: new_id, name: name })
        setWorkingData(array)
        setOpenEdit(false)
    }

    return (
        <Grid style={{ width: "100%", }}>
            <SuperModal
                onClose={() => setOpenDelete(false)}
                header={'Delete'}
                content={`Do you really want to delete ${name}.`}
                onAccept={() => deleteApprove()}
                positive={true}
                open={openDelete} />
            <Modal
                open={openEdit}
                onClose={() => setOpenEdit(false)}
                size='mini'
                style={{ textAlign: "center" }}>
                <Modal.Header>Edit</Modal.Header>
                <Modal.Content>
                    Change name of technology
                    <Form.Input
                        style={{ marginTop: '1rem' }}
                        value={name}
                        onChange={(e) => setName(e.target.value.toUpperCase())}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpenEdit(false)} basic>Close</Button>
                    <Button onClick={() => editApprove()} icon="checkmark" content="Save" />
                </Modal.Actions>

            </Modal>
            <Grid.Row style={styles.heading}>
                <Form.Input
                    placeholder="Search technology"
                    value={search}
                    icon='search'
                    onChange={(e) => setSearch(e.target.value)}
                    style={styles.input}
                />
            </Grid.Row>
            <TechRow />

        </Grid>
    )
}
const styles = {
    row: {
        borderTopStyle: "solid",
        borderTopColor: "var(--border)",
        borderTopWidth: 1,
        padding: 0,
        alignItems: 'center',
        width: '100%'
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: 0
    },
    title: {
        display: 'flex',
        alignItems: "center"
    },
    heading: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    no_technologies: {
        color: 'var(--variant4)'
    },
    input: {
        width: "100%",
    }
}

export default TechnologiesList
