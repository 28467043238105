import React from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from "react-router-dom";

// routes
import { routes } from "../../../store/routes/index";

function Banner() {
    return (
        <div>
            <img src="/images/banner.jpg" alt="logo" width="100%" />
            <span className="home-dot one"></span>
            <span className="home-dot two"></span>
            <span className="home-dot three"></span>
            <div style={styles.container} className="center-text">
                <div className="header home-header">
                    WE HAVE OUTSTANDING IT PROFESSIONALS
                </div>
                <div>
                    <Link to={routes.REGISTRATION}><Button style={styles.button} className="center-button">REGISTER NOW</Button></Link>
                </div>
            </div>
        </div>
    );
}
const styles = {
    container: {
        width: "40%",
        position: "absolute",
        zIndex: 1,
        backgroundColor: "var(--white)",
        marginTop: "-35%",
        padding: "2% 7%",
        display: "block",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    button: {
        padding: "1.5rem 3.5rem",
        display: "flex", justifyContent: "center", alignItems: "center",
        marginTop: "2rem"
    },
}

export default Banner
