import React, { useState } from 'react'
import { Grid, Step, Progress } from 'semantic-ui-react'
import PersonForm from './forms/PersonForm'
import BankForm from './forms/BankForm'
import BusinessAdresses from './forms/BusinessAdresses'
import ConfirmData from './forms/ConfirmData'
import { userService } from '../../../store/services/users/ServiceUser'
import { businessService } from '../../../store/services/common/BusinessService'
import ResponseModal from '../../../components/modals/ResponseModal'
import { connect } from "react-redux";
import { addProfile } from "../../../store/actions/index";

const ClientProfileCreate = (props) => {
    const [step, setStep] = useState(1)
    const [billing_address, setBillingAddress] = useState('')
    const [shipping_address, setShippingAddress] = useState('')
    const [business_detail, setBusinessDetail] = useState({
        vat_number: '',
        nace_code: '',
        nace_name: '',
        business_register_folder: '',
        identification_number: '',
        dit_number: '',
        name: '',
    })
    const [person, setPerson] = useState({
        firstname: '',
        lastname: '',
        middlename: '',
        titles_before: '',
        titles_after: '',
        gender: 0,
        phone: ''
    })
    const [bank, setBank] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')


    function handlePerson(input) {
        setPerson(input)
    }
    function handleAddresses(input) {
        setBillingAddress(input.billing_address)
        setShippingAddress(input.shipping_address)
        setBusinessDetail(input.business)
    }
    function handleBank(input) {
        setBank(input)
    }
    function checkError(input, message, status) {
        if (input.status !== status) {
            setError(true)
            setErrorMessage(message)
            setLoading(false)
            return true
        }
        return false
    }
    async function confirmAll() {
        setLoading(true)
        let bill_add = await businessService.createAddress(billing_address)
        if (checkError(bill_add, 'Not valid billing address', 201)) return
        let ship_add = await businessService.createAddress(shipping_address)
        if (checkError(ship_add, 'Not valid shipping address', 201)) return
        let bank_add = null
        if (bank.bank_address.street.length < 0 || bank.bank_address.city.length < 0 || bank.bank_address.postcode.length < 0 || bank.bank_address.country.length < 0) {
            bank_add = await businessService.createAddress(bank.bank_address)
            if (checkError(bank_add, 'Not valid bank address', 201)) return
        }
        let new_bank_account = {
            name: bank.account.name,
            iban: bank.account.iban,
            alias: bank.account.alias,
            currency: bank.currency,
            swift_code: bank.account.swift_code,
            bank_detail: bank.account.bank_detail,
            bank_address: bank_add ? bank_add.response.id : null
        }
        let nace = await businessService.createNaceCode({ code: business_detail.nace_code, name: business_detail.nace_name })
        if (checkError(nace, 'Not valid nace code', 201)) return
        let bank_account = await businessService.createBankAccount(new_bank_account)
        if (checkError(bank_account, 'Not valid bank account', 201)) return
        let new_business_detail = {
            identification_number: business_detail.identification_number,
            dit_number: business_detail.dit_number,
            name: business_detail.name,
            bank_account: bank_account.response.id,
            billing_address: bill_add.response.id,
            shipping_address: ship_add.response.id,
            business_register_folder: business_detail.business_register_folder,
            nace_code: nace.response.id,
            vat_number: business_detail.vat_number
        }
        let update_business_detail = await businessService.createBusinessDetail(new_business_detail)
        if (checkError(update_business_detail, 'Not valid business detail', 201)) return
        let new_person = await userService.createPerson(person)
        if (checkError(new_person, 'Not valid personal information', 201)) return
        let update_profile = await userService.updateProfile(props.profile.id, {
            person: new_person.response.id,
            business_detail: update_business_detail.response.id,
            phone: person.phone
        })
        if (checkError(update_profile, 'Something went wrong with your profile.', 200)) return
        props.dispatch(addProfile(update_profile.response))
        props.rerender({ business: update_business_detail.response, person: update_profile.response.person, phone: update_profile.response.phone })
    }
    return (
        <Grid style={{
            backgroundColor: 'var(--light)',
            margin: '1rem',
            borderWidth: 1,
            borderColor: 'var(--border)',
            borderStyle: "solid",
        }}>
            <ResponseModal
                open={error}
                onClose={() => setError(false)}
                icon='warning'
                header='Error'
                onClick={() => setError(false)}
                message={errorMessage}
            />

            <Grid.Row>
                <Step.Group ordered style={{ width: "100%" }}>
                    <Step completed={step > 1} active={step === 1}>
                        <Step.Content>
                            <Step.Title>Person</Step.Title>
                        </Step.Content>
                    </Step>
                    <Step completed={step > 2} active={step === 2}>
                        <Step.Content>
                            <Step.Title>Business</Step.Title>
                        </Step.Content>
                    </Step>
                    <Step completed={step > 3} active={step === 3}>
                        <Step.Content>
                            <Step.Title>Bank</Step.Title>
                        </Step.Content>
                    </Step>
                    <Step completed={step > 4} active={step === 4}>
                        <Step.Content>
                            <Step.Title>Confirm all</Step.Title>
                        </Step.Content>
                    </Step>
                </Step.Group>
            </Grid.Row>
            {step === 1 && <PersonForm
                client
                handleData={(output) => handlePerson(output)}
                nextStep={() => setStep(2)}
                data={person} />}
            {step === 2 && <BusinessAdresses
                handleData={(output) => handleAddresses(output)}
                nextStep={() => setStep(3)}
                previousStep={() => setStep(1)}
                data={{ billing_address: billing_address, shipping_address: shipping_address, business_detail: business_detail }}
                client />
            }
            {step === 3 && <BankForm
                handleData={(output) => handleBank(output)}
                nextStep={() => setStep(4)}
                previousStep={() => setStep(2)}
                data={bank} />}
            {step === 4 && <ConfirmData
                previousStep={() => setStep(3)}
                confirm={() => confirmAll()}
                load={loading}
                data={{
                    person: person,
                    addresses:
                    {
                        billing_address: billing_address,
                        shipping_address: shipping_address
                    },
                    bank: bank,
                    business_detail: business_detail
                }} />}
            <Grid.Row><Progress value={step} total={4} progress='ratio' style={{ width: "100%" }} /></Grid.Row>
        </Grid>
    )
}


export default connect()(ClientProfileCreate)
