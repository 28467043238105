import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { routes } from '../../store/routes/index';
import { authService } from '../../store/services/ServiceAuth';
import Loading from '../general/Loading';

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [currentPath] = useState(window.location.pathname);

    useEffect( () => {
        async function checkUser() {
            const result = await authService.validateToken();
            setIsLoading(false);

            if( result !== false && restricted ){
                history.push(routes.DASHBOARD);
            }
        };

        checkUser();
    }, [history, currentPath, restricted]);

    if( isLoading ){
        return <Loading/>;
    } else {
        return (
            <Route {...rest} render={props => (
                <Component {...props} />
            )} />
        );
    }
};

export default PublicRoute;