import React from 'react'
import { Button, Grid } from 'semantic-ui-react'
import { useSelector } from 'react-redux'

const ReservationActions = ({ onAcceptOffers, loading, disabled, onAcceptReservation, onRejectReservation, stage, data }) => {
    const profile = useSelector(state => state.profile)
    if (profile.user.role === 3 && data.status === 1) {
        if ((data.selected_service_offers[0].status_by_provider === 3 || data.selected_service_offers[0].status_by_client === 3)
            && data.selected_service_offers.length === 1) {
            return (<Button content="Reject reservation" icon="close" onClick={onRejectReservation} disabled={disabled} loading={loading} basic />)
        }
        let number_of_rejected = 0
        data.selected_service_offers.filter(item => {
            if (item.status_by_client === 3) {
                number_of_rejected += 1
            }
            return item
        })
        if (number_of_rejected === data.selected_service_offers.length) {
            return (<Button content="Reject reservation" icon="close" onClick={onRejectReservation} disabled={disabled} loading={loading} basic />)
        }
    }
    return (
        <Grid.Row style={{ padding: 0, margin: 0 }}>
            {stage === 1 &&
                <Button content="Confirm selected service offers" icon="checkmark" onClick={onAcceptOffers} disabled={disabled} loading={loading} />
            }
            {stage === 2 && <>
                <Button content="Reject reservation" icon="close" onClick={onRejectReservation} disabled={disabled} loading={loading} basic />
                <Button content="Accept reservation" icon="checkmark" onClick={onAcceptReservation} disabled={disabled} loading={loading} />
            </>}
        </Grid.Row>
    )
}

export default ReservationActions
