import React from 'react'
import { Grid, Icon } from 'semantic-ui-react'

const ListOfSSO = ({ data, removeOffer, loading }) => {

    // function getDiscounted(){
    // }
    function dicountedPrice(price, discount, currency) {
        let new_price = parseFloat(price) * ((100 - parseFloat(discount)) / 100)
        return (
            <div>
                <span style={{ fontWeight: 'bold', paddingRight: '0.5rem' }}>{new_price.toFixed(2)}</span>
                <span style={{ textDecoration: 'line-through' }}>{price}</span>
                <span style={{ paddingLeft: '0.5rem' }}>{currency}</span>
            </div>
        )
    }
    const MapDatas = () => {
        return data.map((item, key) => {
            let bundle = null
            if (item.bundle) {
                for (let i in item.data.active_bundles) {
                    if (item.bundle === item.data.active_bundles[i].id) {
                        bundle = item.data.active_bundles[i]
                    }
                }
            }

            return (
                <Grid.Row columns={3} key={key}
                    style={{
                        background: 'var(--white)',
                        padding: 0,
                        borderBottomColor: "var(--border)",
                        borderBottomStyle: "solid",
                        borderBottomWidth: 1,
                        marginBottom: key + 1 !== data.length ? '0.5rem' : 0
                    }}>
                    <Grid.Column width={7} style={{ flexDirection: 'column' }}>
                        <span >{item.data.technology.name}</span>
                        {item.data.project_manager &&
                            <span style={{ color: 'var(--primary  )', display: 'flex' }}> Managers</span>}</Grid.Column>
                    <Grid.Column width={2}>{item.total}</Grid.Column>
                    {bundle
                        ?
                        <Grid.Column width={7}>{dicountedPrice(item.data.price_display, bundle.discount, item.data.currency)}</Grid.Column>
                        :
                        <Grid.Column width={7}>{item.data.price_display} {item.data.currency}</Grid.Column>
                    }
                    {!loading && <Icon name="close" style={{ position: "absolute", right: 0, cursor: 'pointer' }} onClick={() => removeOffer(item)} />}
                </Grid.Row>
            )
        })
    }


    return (
        <Grid style={{ background: 'var(--light)', padding: '1rem' }}>
            <MapDatas />
        </Grid>
    )
}

export default ListOfSSO
