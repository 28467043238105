import React, { Fragment, useEffect, useState } from 'react'
// components
import TableRequests from './TableRequests'
import { Header } from 'semantic-ui-react'
import Loading from '../../../../components/general/Loading'

//Services
import { registerService } from '../../../../store/services/users/RegistrationService'

function Requests() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const fetch = async () => {
        let result = await registerService.getRegistrationRequests()
        setData(result.response)
        setLoading(false)
    }
    useEffect(() => {
        fetch()
    }, [])

    return (
        <Fragment>
            {loading ?
                <Loading />
                : <>
                    <Header as='h1' style={{ color: 'var(--primary)', margin: "1rem" }}>
                        <Header.Content>Requests management</Header.Content>
                        <Header.Subheader>
                            Manage your registration requests.
                        </Header.Subheader>
                    </Header>
                    <TableRequests data={data} />
                </>
            }
        </Fragment>
    )
}

export default Requests
