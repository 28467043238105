import React from 'react';
import { Grid, Header, Segment, Icon } from 'semantic-ui-react';

function GraphTotalSection(props) {

    return (
        <>
            <Segment style={{ padding: "1rem", border: 0, boxShadow: 0 }}>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column width={13}>
                            <Header color={props.headerColor}>
                                {props.header}
                            </Header>
                            <Header>
                                {props.detail}
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Icon name={props.icon} size="big" />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </>
    );
}


export default GraphTotalSection
