import React, { Fragment, useEffect, useState } from 'react'

import { projectService } from '../../../store/services/projects/ProjectService'
import { useSelector } from "react-redux";

import ProjectList from './list/ProjectList'
import Loading from '../../../components/general/Loading'

const Projects = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const profile = useSelector(state => state.profile)

    useEffect(() => {
        async function fetchData() {
            let result = await projectService.getProjects()
            if (result) setData(result.response)
            setLoading(false)
        }
        fetchData()
    }, [])

    function addToData(input) {
        let array = data
        let result = array.concat(input)
        setData(result)

    }
    function deleteData(input) {
        let array = data.filter(item => item.id !== input)
        setData(array)
    }
    function updateData(input) {
        let array = data.map(item => {
            if (item.id === input.id) return input
            return item
        })
        setData(array)
    }

    return (
        <Fragment>
            {loading ? <Loading /> : <ProjectList
                data={data}
                id={profile.user.id}
                addProject={(e) => addToData(e)}
                deleteProject={(e) => deleteData(e)}
                updateProject={(e) => updateData(e)} />}
        </Fragment>
    )
}

export default Projects
