import { APIRequest } from '../helpers/requests';
import { API } from '../../config';


export const registerService = {
    registerUser,
    registerClient,
    getRegistrationRequests,
    registrationApprove,
    registrationReject,
    registerClientRef,
    registerUserRef
}
async function registerUser(input) {
    return await APIRequest({
        url: API.REGISTER,
        method: 'POST',
        data: input,
        private: false
    });
}
async function registerUserRef(input, link) {
    return await APIRequest({
        url: API.REGISTER + "?ref_link=" + link,
        method: 'POST',
        data: input,
        private: false
    });
}
async function registerClient(input) {
    return await APIRequest({
        url: API.REGISTER_CLIENT,
        method: 'POST',
        data: input,
        private: false
    });
}
async function registerClientRef(input, link) {
    return await APIRequest({
        url: API.REGISTER_CLIENT + "?ref_link=" + link,
        method: 'POST',
        data: input,
        private: false
    });
}
async function getRegistrationRequests() {
    return await APIRequest({
        url: API.REGISTER,
        method: 'GET',
        private: true
    });
}
async function registrationApprove(id) {
    return await APIRequest({
        url: API.REGISTER + id + "/approve/",
        method: 'POST',
        private: true,
        data: id
    });
}
async function registrationReject(id) {
    return await APIRequest({
        url: API.REGISTER + id + "/reject/",
        method: 'POST',
        private: true,
        data: id
    });
}
