import React, { useState, useEffect } from 'react'
import { Divider, Button, Grid, Form, Segment } from 'semantic-ui-react'
import { businessService } from '../../../store/services/common/BusinessService'
import AddressForm from '../../../components/inputs/AddressForm'
import NaceCodeInput from '../../../components/inputs/NaceCodeInput';

const UpdateBusinessModal = ({ data, container, modal, update }) => {
    const [loading, setLoading] = useState(true)
    const [loadingWhole, setLoadingWhole] = useState(false)
    const [business, setBusiness] = useState(
        data ? data : {
            identification_number: '',
            dit_number: '',
            vat_number: '',
            nace_code: '',
            business_register_folder: '',
            billing_address: {},
            shipping_address: {},
            name: ''
        })
    const [naceBack, setNaceBack] = useState({})
    const [nace, setNace] = useState({
        id: 0,
        nace_code: '',
        nace_name: ''
    })
    const handleBusiness = (e, { name, value }) => setBusiness((prevState) => ({ ...prevState, [name]: value }))
    const handleBillingAdress = (e) => setBusiness((prevState) => ({ ...prevState, billing_address: e }))
    const handleShippingAdress = (e) => setBusiness((prevState) => ({ ...prevState, shipping_address: e }))

    useEffect(() => {
        async function fetchData() {
            if (data.nace_code) {
                let result = await businessService.getNaceByID(data.nace_code)
                setNace((prevState) => ({
                    ...prevState,
                    id: result.response.id,
                    nace_code: result.response.code,
                    nace_name: result.response.name,
                }))
                setNaceBack(result.response)
            }
            setLoading(false)
        }
        fetchData()
    }, [data.nace_code])

    function checkChange() {
        if (data.name !== business.name) return true
        if (data.identification_number !== business.identification_number) return true
        if (data.dit_number !== business.dit_number) return true
        if (data.vat_number !== business.vat_number) return true
        if (data.business_register_folder !== business.business_register_folder) return true
        if (isNaceChanged()) return true
        if (changedAdresses()) return true
        return loading
    }
    function changedAdresses() {
        if (business.billing_address.street !== data.billing_address.street
            || business.billing_address.city !== data.billing_address.city
            || business.billing_address.state !== data.billing_address.state
            || business.billing_address.country !== data.billing_address.country
            || business.billing_address.postcode !== data.billing_address.postcode
            || business.shipping_address.street !== data.shipping_address.street
            || business.shipping_address.city !== data.shipping_address.city
            || business.shipping_address.state !== data.shipping_address.state
            || business.shipping_address.country !== data.shipping_address.country
            || business.shipping_address.postcode !== data.shipping_address.postcode) {
            return true
        }
        return false
    }
    function isNaceChanged() {
        if (!naceBack && ((nace.nace_code !== '') && (nace.nace_name !== ''))) return true
        if (naceBack.code !== nace.nace_code) return true
        if (naceBack.name !== nace.nace_name) return true
        return false
    }

    const updateBusiness = async () => {
        setLoadingWhole(true)
        modal({
            open: true,
            content: <Segment loading />,
            icon: '',
            header: 'Processing',
        })
        let nace_res
        let bill_add
        let ship_add
        if (data.nace_code) {
            if (isNaceChanged()) nace_res = await businessService.updateNaceCode(data.nace_code, { code: nace.nace_code, name: nace.nace_name })
        } else {
            if (isNaceChanged()) nace_res = await businessService.createNaceCode({ code: nace.nace_code, name: nace.nace_name })
        }
        //IF ADDRESS DOESNT EXIST
        if (changedAdresses()) {
            if (!data.billing_address) bill_add = await businessService.createAddress(business.billing_address)
            if (!data.shipping_address) ship_add = await businessService.createAddress(business.shipping_address)
            //IF ADDRESS UPDATE
            if (data.billing_address) bill_add = await businessService.updateAddress(data.billing_address.id, business.billing_address)
            if (data.shipping_address) ship_add = await businessService.updateAddress(data.shipping_address.id, business.shipping_address)
        }
        let result = await businessService.updateBusinessDetail(
            data.id,
            {
                name: business.name,
                identification_number: business.identification_number,
                dit_number: business.dit_number,
                vat_number: business.vat_number,
                business_register_folder: business.business_register_folder,
                nace_code: nace_res && nace_res.response.id,
                billing_address: bill_add && bill_add.response.id,
                shipping_address: ship_add && ship_add.response.id
            }
        )
        update(result.response)
        setLoadingWhole(false)
        modal({
            open: true,
            message: 'Your change was successful.',
            icon: 'checkmark',
            header: 'Success',
            content: null
        })
    }
    return (
        <Segment disabled={loading}>
            <Grid style={container}>
                <Grid.Row style={{ padding: 0 }}></Grid.Row>
                <Divider horizontal>Business</Divider>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <span>NAME</span>
                        <Form.Input
                            fluid
                            name="name"
                            value={business.name}
                            onChange={handleBusiness}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <span>ID</span>
                        <Form.Input
                            fluid
                            name="identification_number"
                            value={business.identification_number}
                            onChange={handleBusiness}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <span>DIT</span>
                        <Form.Input
                            fluid
                            name="dit_number"
                            value={business.dit_number}
                            onChange={handleBusiness}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <span>VAT</span>
                        <Form.Input
                            fluid
                            name="vat_number"
                            value={business.vat_number}
                            onChange={handleBusiness}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <span>Business register folder</span>
                        <Form.Input
                            fluid
                            name="business_register_folder"
                            value={business.business_register_folder}
                            onChange={handleBusiness}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <span>Nace code</span>
                        <NaceCodeInput handler={(e) => setNace({ nace_name: e.name, nace_code: e.code, id: e.id })} data={nace} />
                    </Grid.Column>
                </Grid.Row>
                <Divider horizontal>Billing Address</Divider>
                <Grid.Row><AddressForm data={business.billing_address} handleData={(e) => handleBillingAdress(e)} /></Grid.Row>
                <Divider horizontal>Shipping Address</Divider>
                <Grid.Row><AddressForm data={business.shipping_address} handleData={(e) => handleShippingAdress(e)} /></Grid.Row>
                <Grid.Column width={16} >
                    <Button content="Save changes" onClick={() => updateBusiness()} disabled={!checkChange() || loading} loading={loadingWhole} />
                </Grid.Column>
            </Grid>
        </Segment>
    )
}
export default UpdateBusinessModal
