import React from 'react'
//This component will be changed after connecting to api
const Technology = ({ data }) => {
    return (<div style={styles.one}>{data}</div>)
}
const styles = {
    one: {
        display: 'flex',
        padding: "0.1rem 1rem",
        fontWeight: 'bold',
        width: '7rem',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        margin: '0.1rem 0.1rem',
        backgroundColor: "var(--tech1)",
        color: 'var(--white)'
    },
}
export default Technology
