import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

// components
import { Button, Icon, Label } from "semantic-ui-react";
import ActionButton from "../../../components/buttons/ActionButton";
import DataTable from "../../../components/tables/DataTable";

// others
import { dateService } from "../../../store/services/custom/DateServices";
import { routes } from "../../../store/routes";
import { Link } from "react-router-dom";


const OrdersTable = ({ data }) => {
    const [dataToTable, setDataToTable] = useState([])
    const profile = useSelector(state => state.profile)
    let history = useHistory()

    useEffect(() => {
        (async function () {
            setDataToTable(
                data.map((item => (
                    {
                        // provider: item.providers[0].name,
                        // provider_id: item.providers[0].id,
                        uuid: item.reservation.uuid,
                        is_premium: item.reservation.is_premium,
                        created_when: item.ordered_when,
                        tableActions: item.id
                    }
                )))
            )

        })()
    }, [data]);

    function getTotalPrice(input) {
        let new_data = profile.user.role === 2 ? data[input].reservation.provider_selected_service_offers :
            profile.user.role === 3 ? data[input].reservation.selected_service_offers : []

        let result = new_data.reduce((accumulator, value) => {
            return accumulator + (parseFloat(value.price) * value.total_men)
        }, 0)

        return `${result} ${new_data[0].service_offer.currency}`
    }
    function getTotalPeople(input) {
        let new_data = profile.user.role === 2 ? data[input].reservation.provider_selected_service_offers :
            profile.user.role === 3 ? data[input].reservation.selected_service_offers : []

        let result = new_data.reduce((accumulator, value) => {
            return accumulator + parseFloat(value.total_men)
        }, 0)

        return result
    }
    function getAllTechnologies(input) {
        let array = []
        let new_data = profile.user.role === 2 ? data[input].reservation.provider_selected_service_offers :
            profile.user.role === 3 ? data[input].reservation.selected_service_offers : []

        new_data.filter(item => {
            array = array.concat(item.service_offer.name)
            return item
        })
        let result = [];
        array.forEach((c) => {
            if (!result.includes(c)) {
                result.push(c);
            }
        })
        return result.map((item, key) => (
            <Label key={key} style={styles.technology}><Icon name='cog' />{item}</Label>
        ))
    }
    function getNumberOfOffers(input) {
        let new_data = profile.user.role === 2 ? data[input].reservation.provider_selected_service_offers :
            profile.user.role === 3 ? data[input].reservation.selected_service_offers : []

        return new_data.length
    }


    const orderedHeaders = [
        'uuid',
        'provider',
        'total_price',
        'total_people',
        'number_of_offers',
        'created_when',
        'technologies',
        'tableActions'
    ]

    const TableAction = ({ id }) => {
        return (
            <Button.Group>
                <ActionButton icon="eye" action={() => action(id)} circular={true} />
            </Button.Group>
        );
    }

    const headers = {
        is_premium: {
            invisible: true
        },
        provider_id: {
            invisible: true
        },
        uuid: {
            text: 'UUID',
            sortable: true,
            filterable: true,
            transform: (value, index, row) => {
                if (data[index].reservation.is_premium) return (
                    <div style={styles.premium}>#{value}<Icon name="star" /></div>
                )
                return (<div style={styles.basic}>#{value}</div>)
            }
        },
        provider: {
            text: 'Provider',
            invisible: true,
            sortable: true,
            filterable: true,
            transform: (value, index, row) => {
                return (<Link style={styles.provider} to={routes.PROVIDER_PROFILE + row.provider_id}>{value}</Link>)
            }
        },
        created_when: {
            text: 'Created',
            sortable: true,
            filterable: true,
            transform: (value, index, row) => {
                return dateService.convertDateWithDots(value)
            },
        },
        total_price: {
            text: 'Total price',
            sortable: false,
            filterable: true,
            transform: (value, index) => {
                return (<div style={{ textAlign: 'left' }}>{getTotalPrice(index)}</div>)
            },
        },
        total_people: {
            text: 'Total people',
            sortable: false,
            filterable: true,
            transform: (value, index) => {
                return (<div style={{ textAlign: "left" }}>{getTotalPeople(index)}</div>)
            },
        },
        number_of_offers: {
            text: 'Offers',
            sortable: false,
            filterable: true,
            transform: (value, index) => {
                return (<div style={{ textAlign: "left" }}>{getNumberOfOffers(index)}</div>)
            },
        },
        technologies: {
            text: 'Technologies',
            sortable: false,
            filterable: true,
            transform: (value, index) => {
                return (<div style={{ textAlign: "left" }}>{getAllTechnologies(index)}</div>)
            },
        },
        tableActions: {
            text: 'Detail',
            sortable: false,
            filterable: true,
            transform: (value, index, row) => {
                return <TableAction id={value} />
            },
        }
    }
    function action(id) {
        history.push(routes.ORDERS + id)
    }


    return (
        <Fragment>
            <DataTable
                data={dataToTable}
                orderedHeaders={orderedHeaders}
                headers={headers}
            />
        </Fragment>
    )
}
const styles = {
    container: {
        background: 'var(--light)',
        borderStyle: 'solid',
        borderColor: 'var(--border)',
        borderWidth: 1,
        color: 'var(--black)',
        marginBottom: '1rem'
    },
    premium: {
        background: 'var(--primary)',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0.05rem',
        color: 'var(--white)',
        borderRadius: 'var(--radius-label)',
    },
    basic: {
        fontWeight: 'bold',
        background: 'var(--variant3)',
        padding: '0.05rem',
        color: 'var(--white)',
        borderRadius: 'var(--radius-label)',
    },
    content: {
        background: 'var(--white)',
        padding: '1rem'
    },
    between: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    technology: {
        margin: '0.25rem'
    },
    provider: {
        color: 'var(--primary)',
        fontWeight: 'bold',
    }
}
export default OrdersTable