import { APIRequest } from '../helpers/requests';
import { API } from '../../config';


export const ratingService = {
    getRatingOrderByID,
    createRatingOrderByID,
    updateRatingOrderByID
}
async function getRatingOrderByID(id) {
    return await APIRequest({
        url: API.RATINGS + id,
        method: 'GET',
        data: {},
        private: true
    });
}
async function createRatingOrderByID(id, data) {
    return await APIRequest({
        url: API.RATINGS + id,
        method: 'POST',
        data: data,
        private: true
    });
}
async function updateRatingOrderByID(id, data) {
    return await APIRequest({
        url: API.RATINGS + id,
        method: 'PATCH',
        data: data,
        private: true
    });
}

