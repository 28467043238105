import React, { useEffect, useState } from 'react'
import { Dropdown, Label } from 'semantic-ui-react'
import { businessService } from '../../store/services/common/BusinessService'

const NaceCodeInput = ({ handler, data }) => {

    const [options, setOptions] = useState([])
    const [value, setValue] = useState(data ? data.id : '')
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')

    useEffect(() => {
        async function fetchOptions() {
            let result = await businessService.getNaceCode()
            if (result.status === 200) {
                setOptions(toObjectOptions(result.response))
            }
            setLoading(false)
        }
        fetchOptions()
        if (value?.length > 0 && !/^([a-zA-Z0-9]{1,3}[\s][-]([\s][a-zA-Z0-9]{3,}){1,})[\s]?$/.test(value)) {
            setError("Wrong format! Example is: A1x - Something new. (this format is strict)")
        } else {
            setError(null)
        }
        if (value !== data.id) { setValue(data.id) }
    }, [value, data])

    const handleAddition = async (e, { value }) => {
        setLoading(true)
        if (value.length > 0 && !/^([a-zA-Z0-9]{1,3}[\s][-]([\s][a-zA-Z0-9]{3,}){1,})[\s]?$/.test(value)) {
            setError("Wrong format! Example is: A1x - Something new. (this format is strict)")
            setLoading(false)
            return
        } else {
            setError(null)
        }
        let array = value.split(' - ')
        if (array.length < 2) return setLoading(false)
        let result = await businessService.createNaceCode({ code: array[0], name: array[1] })
        if (result.status === 201) {
            setOptions((prevState) => ([
                { key: result.response.id, value: result.response.id, text: `${result.response.code} - ${result.response.name}` }
                , ...prevState]))
            setValue(result.response.id)
            handler(result.response)
        }
        setLoading(false)
    }

    function toObjectOptions(input) {
        let array = []
        let result = input.map((item, id) => (
            array[id] = { key: item.id, value: item.id, text: `${item.code} - ${item.name}` }))
        return result
    }

    const handleChange = (e, { value }) => {
        setValue(value)
        let array = null
        let nace
        if (typeof value !== 'string') {
            array = options.filter(item => item.value === value)
            nace = array[0].text.split(' - ')
        }
        if (typeof value === 'string') {
            nace = value.split(' - ')
        }
        if (nace.length < 2) return
        handler({ id: array ? array[0].value : null, code: nace[0], name: nace[1] })
    }
    return (
        <>
            {
                error &&
                <Label>
                    {error}
                </Label>
            }
            <Dropdown
                loading={loading}
                disabled={loading}
                options={options}
                placeholder='Select nace code'
                search
                selection
                fluid
                allowAdditions
                value={value}
                onAddItem={handleAddition}
                onChange={handleChange}
                style={{ borderRadius: 0 }}
            />
        </>
    )
}
export default NaceCodeInput