import React from 'react'
import { Header, Grid, Icon, Label } from 'semantic-ui-react'
import { dateService } from '../../../../store/services/custom/DateServices'

const BaseDetails = ({ data, username, joined }) => {
    function getInitialsOfName() {
        return `${data.firstname[0]}${data.lastname[0]}`
    }
    function getUserName(input) {
        let name = ''
        if (!input) return input.user.username
        if (input.titles_before) {
            name = name + input.titles_before
        }
        if (input.firstname) {
            name = name + ' ' + input.firstname
        }
        if (input.middlename) {
            name = name + ' ' + input.middlename
        }
        if (input.lastname) {
            name = name + ' ' + input.lastname
        }
        if (input.titles_after) {
            name = name + ' ' + input.titles_after
        }
        return name
    }
    return (
        <Grid style={styles.container}>
            <Grid.Row style={styles.row}><Label circular style={styles.icon}>{getInitialsOfName()}</Label></Grid.Row>
            <Grid.Row style={styles.row}><Header as='h1' style={styles.pink}>{getUserName(data)}</Header></Grid.Row>
            <Grid.Row style={styles.row}><Icon name='user' /><span>Username: <b>{username}</b></span></Grid.Row>
            <Grid.Row style={styles.row}><Icon name='clock' /><span>Date joined: <b>{dateService.convertDateWithDots(joined)}</b></span></Grid.Row>
        </Grid>
    )
}
const styles = {
    container: {
        padding: "1rem",
        borderColor: "var(--border)",
        borderStyle: "solid",
        borderWidth: 1,
        boxShadow: "1px 1px 2px #686868c7",
        backgroundColor: "var(--white)",
    },
    headding: {
        display: "flex",
        justifyContent: 'space-between',
    },
    pink: {
        color: 'var(--primary)'
    },
    icon: {
        marginRight: ".5rem",
        backgroundColor: "var(--light)",
        color: "var(--dark)",
        fontSize: '5rem'
    },
    row: {
        justifyContent: 'center'
    }
}
export default BaseDetails
